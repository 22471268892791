import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import Box from '@mui/material/Box';

import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';

import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';


import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost } from "../network";

function WorkflowRetractDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onCancel,
        workflowIndex,
        ...other
    } = props;


    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(workflowIndex);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '2ex'}}>
                    <Typography sx={{display: 'inline'}}>Retract workflow </Typography><Typography sx={{display: 'inline', fontWeight: 'bold'}}>{workflowName}</Typography><Typography  sx={{display: 'inline'}}>?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onOKClick} sx={{minWidth: '7em'}} color="warning">Retract</Button>
                <Button onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function PublishedWorkflows(props) {

    const [updateDef, setUpdateDef] = React.useState(0);
    const [updateDefId, setUpdateDefId] = React.useState(-1);

    const [workflowList, setWorkflowList] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [retractEnabled, setRetractEnabled] = React.useState(false);

    const [workflowRetractOpen, setWorkflowRetractOpen] = React.useState(false);
    const [workflowRetractName, setWorkflowRetractName] = React.useState(false);

    const theme = useTheme();

    React.useEffect(() => {
        loadInstances();
    }, []);

    React.useEffect(() => {
        if (updateDefId >= 0) {
            netGet('/api/workflow/def/' + updateDefId)
                .then(response => response.json())
                .then(def => {
                    if (def && def.id) {
                        console.log('Updating Workflow Definition: ' + def.id);
                        let slist = workflowList.map((r) => {
                            if (r.id === def.id) {
                                return def;
                            }
                            return r;
                        });
                        setWorkflowList(slist);
                    }
                }).catch(error => {
                    console.log(`Error fetching def for ${updateDefId}: ${error}`);
                });
        }
    }, [updateDef,updateDefId]);


    const workflowMessage = (message) => {
        let wevent = message.data;
        // console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowDefinition') {
            if ( wevent.action === 'DELETE' || wevent.action === 'RETRACT') {
                let dindex = -1;
                const def = workflowList.find( (item, index) => {
                    if ( item.id === wevent.id ) {
                        dindex = index;
                        return true;
                    }
                });
                if ( def && dindex >= 0 ) {
                    let wlist = [ ...workflowList];
                    wlist.splice(dindex, 1);
                    setWorkflowList(wlist);
                }
            } else if ( wevent.action === 'NEW' || wevent.action === 'PUBLISH') {
                loadInstances();
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else {
                const def = workflowList.find( (item) => item.id === wevent.id);
                if ( def ) {
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                }
            }
        }
    };

    MessageHooks["workflow"]["WorkflowPublished"] = workflowMessage;


    const loadInstances = () => {
        netGet('/api/workflow/def/list?categ=DEF&pmask=2' )
            .then(response => response.json())
            .then(defs => {
                if ( defs && Array.isArray(defs) ) {
                    console.log('Workflow Definitions: ' + defs.length);
                    setWorkflowList(defs);
                }
            });
    };

    const serverRetract = (def) => {
        netPost('/api/workflow/def/retract', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not retract workflow definition: status = ' + resp.status);
                }
            });
    };

    const handleContextMenu = (event,index) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleItemClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleItemClick = (index) => {
        setSelectedIndex(index);
        if ( index >= 0 ) {
            setRetractEnabled(true);
        }
    };

    const isLockedByMe = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0;
    };

    
    const handleRetractWorkflowAction = () => {
        handleContextMenuClose();
        const workflow = workflowList[selectedIndex];
        setWorkflowRetractName(workflow.label);
        setWorkflowRetractOpen(true);
    }

    const retractWorkflowSave = () => {
        setWorkflowRetractOpen(false);
        const workflow = workflowList[selectedIndex];
        serverRetract(workflow);
    };

    const retractWorkflowCancel = () => {
        setWorkflowRetractOpen(false);

    };
    
    const publishedIconColor = (index) => {
        const def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if ( (def.flag & 2) != 0) {
            return 'black';
        }
        return 'transparent';
    };

    return (

        <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
            <Typography align="center" variant="h7" sx={{ paddingBottom: '2ex', fontWeight: theme.typography.fontWeightBold }}>Workflows</Typography>
            <Box sx={{textAlign: 'center'}}>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box
                sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }}
                onContextMenu={(event) => handleContextMenu(event, -1)}
            >

                <List sx={{ minHeight: '2em' }}>
                    {
                        workflowList.map((def, index) => (
                            <ListItem
                                key={'mwwp-' + index} selected={selectedIndex === index}
                                sx={{ padding: 1, paddingTop: 0, paddingBottom: 0 }}
                                onContextMenu={(event) => handleContextMenu(event, index)}
                            >
                                <Box sx={{ float: 'left', color: publishedIconColor(index), fontSize: '12px' }}>
                                    <CheckIcon fontSize="inherit" />
                                </Box>
                                <ListItemButton onClick={(event) => handleItemClick(index)} >
                                    <ListItemText>{def.label}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                        )
                    }
                </List>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={handleRetractWorkflowAction} disabled={!retractEnabled}>Retract</MenuItem>
                </Menu>
            </Box>
            <Box sx={{ padding: '1ex', paddingRight: '2ex', whiteSpace: 'nowrap', overflowX: 'hidden', textAlign: 'right' }} color={theme.palette.primary.main}>
                <Tooltip title="Retract Workflow">
                    <Button variant="outlined" startIcon={<DeleteIcon fontSize="inherit" />} disabled={!retractEnabled} onClick={handleRetractWorkflowAction}>
                        Retract
                    </Button>
                </Tooltip>
                <WorkflowRetractDialog title="Retract Workflow" open={workflowRetractOpen} workflowName={workflowRetractName}
                    onSave={retractWorkflowSave} onCancel={retractWorkflowCancel} />
            </Box>
        </Box>


    );

}


export { PublishedWorkflows, };