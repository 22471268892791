import React, { useState, useEffect } from 'react';
import useDownloader from 'react-use-downloader';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { getAuthHeaders } from './network';



const FileDownloader = (props) => {
    const {
        files, // [{url, name},...]
        downloadLabel='Start',
        cancelLabel='Abort',
        omitStart=false,
        startAction=false,
        onDone,
        ...other
    } = props;

    const ACTION_DOWNLOAD = 'Start';
    const ACTION_CANCEL = 'Abort';


    const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader({
        headers: getAuthHeaders(),
    });
    const [actionLabel, setActionLabel] = useState(ACTION_DOWNLOAD);
    const [index, setIndex] = useState(-1);
    const [currentUrl, setCurrentUrl] = useState('');
    const [currentName, setCurrentName] = useState('');
    const [running, setRunning] = useState(false);
    // const [done, setDone] = useState(false);

    useEffect( () => {
        if ( running ) {
            if ( !isInProgress ) {
                // finished file, go to next
                const i = index + 1;
                setTimeout(downloadNext, 300, i);
            }
        }

    },[isInProgress,running]);

    useEffect( () => {
        if ( typeof omitStart === 'boolean' && omitStart && typeof startAction === 'boolean' && startAction) {
            handleButtonAction();
        }

    },[omitStart,startAction])

    useEffect( () => {
        if ( Array.isArray(files) && files.length > 0 ) {
            if ( isInProgress ) {
                cancel();
            }
        }
        setRunning(false);
        setIndex(-1);
        setCurrentUrl('');
        setCurrentName('');
        setActionLabel(ACTION_DOWNLOAD);

    },[files]);

    const downloadNext = (i) => {
        if ( Array.isArray(files) && i < files.length ) {
            if (files[i].url && files[i].name) {
                download(files[i].url, files[i].name, -1, { headers: getAuthHeaders(), });
                setCurrentUrl(files[i].url);
                setCurrentName(files[i].name);
                setIndex(i);
                setRunning(true);
            } else {
                setIndex(-1);
                setCurrentName('');
                setCurrentUrl('');
                setRunning(false);
            }
        } else {
            if ( i <= files.length ) {
                setCurrentName(`Downloaded ${i} out of ${files.length} files.`);
            } else {
                setCurrentName('');
            }
            setIndex(-1);
            setCurrentUrl('');
            setRunning(false);
            if ( typeof onDone === 'function') {
                const n = Array.isArray(files) ? files.length : 0;
                onDone(n);
            }
        }
    };

    const handleButtonAction = () => {
        if ( !running ) {
            setCurrentName('');
            downloadNext(0);
        } else {
            setRunning(false);
            setIndex(-1);
            setCurrentName('');
            setCurrentUrl('');
            cancel();
        }
    };

    return (
        <Box sx={{ width: '100%' }} >
            <LinearProgress variant="determinate" value={percentage} sx={{visibility: running ? 'visible' : 'hidden'}} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
                {!omitStart && <Button
                    variant="outlined"
                    sx={{ mt: 1, mr: 1 }}
                    onClick={handleButtonAction}
                >
                    {running ? cancelLabel : downloadLabel}
                </Button>
                }
                <Typography sx={{fontSize: '90%', overflowX: 'hidden'}}>{currentName}</Typography>
            </Box>
        </Box>
    );

};


/*
import axios from 'axios';

const FileDownloader = () => {
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadSuccess, setDownloadSuccess] = useState(false);
  
    const downloadFile = async () => {
      try {
        const url = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'; // Replace with your file URL
        const response = await axios.get(url, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setDownloadProgress(percentCompleted);
          },
        });
  
        // Create a blob URL and trigger download
        const blob = new Blob([response.data]);
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'your-file.pdf'; // Set the desired file name
        link.click();
  
        setDownloadSuccess(true);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };
    return (
        <div className="file-downloader-box">
          <h2 className="file-downloader-title">File Downloader</h2>
          <button className="download-button" onClick={downloadFile}>
            Download File
          </button>
          {downloadProgress > 0 && (
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${downloadProgress}%` }}>
                {downloadProgress}%
              </div>
            </div>
          )}
        {downloadSuccess && (
          <p className="success-message">File downloaded successfully! 🎉</p>
        )}
        </div>);
  };
  */

  export default FileDownloader;  
  