import React, { Component, useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import { netGet, netPost } from '../network';

const EditProfileDialog = (props) => {
    const {
        title,
        profiles,
        profileIndex,
        open,
        onCancel,
        onSave,
        create,
        doc,
        ...other
    } = props;

    const [nameValue, setNameValue] = useState('');
    const [descValue, setDescValue] = useState('');
    const [htmlValue, setHtmlValue] = useState(false);
    const [pdfValue, setPdfValue] = useState(true);
    const [controlledValue, setControlledValue] = useState(true);
    const [uncontrolledValue, setUncontrolledValue] = useState(false);
    const [updatesValue, setUpdatesValue] = useState(false);
    const [okEnabled, setOkEnabled] = useState(false);

    const [effTargetList, setEffTargetList] = useState([]);

    useEffect(() => {
        if ( open ) {
            console.log('Doc: ' + JSON.stringify(doc));
            if (doc?.id) {
                netGet('/api/doc/info/' + doc.id)
                    .then(response => response.json())
                    .then((data) => {
                        // console.log('Actions: ' + JSON.stringify(data));
                        if (Array.isArray(data.metadata?.targets)) {
                            const targets = [...data.metadata.targets];
                            targets.forEach( item => {
                                if ( !create && Array.isArray(profiles) && profiles.length > 0 && profileIndex < profiles.length) {
                                    const ci = profiles[profileIndex].targets?.find( i => i.value === item.value && i.format === item.format);
                                    if ( typeof ci?.active === 'boolean') {
                                        item['active'] = ci.active;
                                    }
                                } else {
                                    item['active'] = false
                                }
                            });
                            setEffTargetList(targets);
                            console.log('DP eff targets: ' + JSON.stringify(targets));
                        }
                    });
            }
            if ( !create && Array.isArray(profiles) && profiles.length > 0 && profileIndex < profiles.length) {
                const pf = profiles[profileIndex];
                setNameValue(pf.name);
                setDescValue(pf.description);
                setHtmlValue(pf.html);
                setPdfValue(pf.pdf);
                setControlledValue(pf.controlled);
                setUncontrolledValue(pf.uncontrolled);
                setUpdatesValue(pf.updates);
                setOkEnabled(pf.name && pf.name !== '');
            } else {
                setNameValue('');
                setDescValue('');
                setHtmlValue(false);
                setPdfValue(true);
                setControlledValue(true);
                setUncontrolledValue(false);
                setUpdatesValue(false);
                setOkEnabled(false);
            }
        }

    },[open,doc,create,profiles,profileIndex])

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const onOKClick = () => {
        if ( typeof onSave === 'function') {
            onSave({
                name: nameValue,
                description: descValue,
                html: htmlValue,
                pdf: pdfValue,
                controlled: controlledValue,
                uncontrolled: uncontrolledValue,
                updates: updatesValue,
                targets: effTargetList
            });
        }

    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
        setOkEnabled( typeof event.target.value === 'string' && event.target.value.trim().length > 0 );
    };

    const descChanged = (event) => {
        setDescValue(event.target.value);
    };

    const handlePdfChanged = (event) => {
        setPdfValue(event.target.checked);
        if ( !event.target.checked) {
            const efl = [ ...effTargetList];
            efl.forEach( item => {
                if ( item.format === 'PDF' ) {
                    item.active = false;
                }
            });
            setEffTargetList(efl);
        }
    };

    const handleHtmlChanged = (event)  =>{
        setHtmlValue(event.target.checked);
        if ( !event.target.checked) {
            const efl = [ ...effTargetList];
            efl.forEach( item => {
                if ( item.format === 'HTML' ) {
                    item.active = false;
                }
            });
            setEffTargetList(efl);
        }

    };

    const handleControlledChanged = (event)  =>{
        setControlledValue(event.target.checked);
    };

    const handleUncontrolledChanged = (event)  =>{
        setUncontrolledValue(event.target.checked);
    };

    const handleUpdatesChanged = (event)  =>{
        setUpdatesValue(event.target.checked);
    };

    const handleItemTargetChanged = (event,item, index) => {
        // console.log('event: ' + event.target.checked + ', index = ' + index);
        item['active'] = event.target.checked;
        const efl = [ ...effTargetList];
        efl[index].active = event.target.checked;
        setEffTargetList(efl);
        // console.log('Item: ' + JSON.stringify(item));
        // console.log('Indexed Item: ' + JSON.stringify(efl[index]));
    };

    const formatItem = (item) => {
        let label = item.value;
        if ( item.format === 'HTML' && pdfValue && item.tail ) {
            label = label + ` (${item.format}, Tail specific)`;
        } else if ( item.tail ) {
            label = label + ` (Tail specific)`;
        } else if ( item.format === 'HTML' && pdfValue) {
            label = label + ` (${item.format})`
        }
        return label;
    };

    const getItemDisabled = (item) => {
        let r = false;
        if ( item.format === 'HTML' && !htmlValue ) {
            r = true;
        } else if ( item.format === 'PDF' && !pdfValue ) {
            r = true;
        }
        return r;
    }

    return (
        <Dialog
            maxWidth={'820px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 820,
                  maxHeight: 700
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{paddingTop: '2ex'}}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true} 
                        onFocus={event => {
                            event.target.select();
                          }} />
                </Box>
                <Box sx={{paddingTop: '2ex'}}>
                    <TextField label="Description" value={descValue} onChange={descChanged} size="small" fullWidth  />
                </Box>
                <Box sx={{paddingTop: '2ex', display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{fontWeight: 'bold', pr: 4, width: '9em'}}>Formats:</Typography>
                    <FormControlLabel key="dp-fmt-html" label="HTML"
                        control={<Checkbox checked={htmlValue} 
                            onChange={handleHtmlChanged}
                            name="dp-fmt-html" />}
                    />
                    <FormControlLabel key="dp-fmt-pdf" label="PDF"
                        control={<Checkbox checked={pdfValue} 
                            onChange={handlePdfChanged}
                            name="dp-fmt-pdf" />}
                    />
                </Box>
                <Box sx={{paddingTop: '2ex', display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{fontWeight: 'bold', pr: 4, width: '9em'}}>Output Type:</Typography>
                    <FormControlLabel key="dp-fmt-cont" label="Controlled"
                        control={<Checkbox checked={controlledValue} 
                            onChange={handleControlledChanged}
                            name="dp-fmt-cont" />}
                    />
                    <FormControlLabel key="dp-fmt-uncont" label="Uncontrolled"
                        control={<Checkbox checked={uncontrolledValue} 
                            onChange={handleUncontrolledChanged}
                            name="dp-fmt-uncont" />}
                    />
                    <FormControlLabel key="dp-fmt-upt" label="Updates"
                        control={<Checkbox checked={updatesValue} 
                            onChange={handleUpdatesChanged}
                            name="dp-fmt-upt" />}
                    />
                </Box>

                {Array.isArray(effTargetList) && effTargetList.length > 0 &&
                    <Box sx={{ paddingTop: '2ex' }}>
                        <Typography sx={{ fontWeight: 'bold', pr: 4, width: '9em' }}>Outputs:</Typography>
                        <Box sx={{ pl: 2, height: '190px' }}>
                            {effTargetList.map((item, index) =>
                                <Box sx={{display: getItemDisabled(item) ? 'none' : 'block'}}>
                                    <FormControlLabel key={`dp-out-${index}`} label={formatItem(item)}
                                        control={<Checkbox checked={effTargetList[index].active} disabled={getItemDisabled(item)}
                                            onChange={(event) => handleItemTargetChanged(event, item, index)}
                                            name={`dp-out-${index}`} />}
                                    />
                                </Box>
                            )
                            }
                        </Box>
                    </Box>
                }
                
            </DialogContent>
            <DialogActions sx={{padding: 4}}>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} disabled={!okEnabled}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

};

const DownloadProfiles = (props) => {
    const {
        doc,
        onChange,
        saveEnabled,
        readOnly,
        ...other
    } = props;

    const CREATE_TITLE = 'Create Download Profile';
    const EDIT_TITLE = 'Edit Download Profile';

    const [dialogTitle, setDialogTitle] = useState(CREATE_TITLE);
    const [sizes, setSizes] = useState(['94%', 'auto']);
    const [profiles, setProfiles] = useState([]);
    const [configKey, setConfigKey] = React.useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [addProfileEnabled, setAddProfileEnabled] = useState(true);
    const [editProfileEnabled, setEditProfileEnabled] = useState(false);
    const [deleteProfileEnabled, setDeleteProfileEnabled] = useState(false);
    const [editProfileCreateMode, setEditProfileCreateMode] = useState(true);
    const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);
    const theme = useTheme();

    useEffect( () => {
        if ( doc ) {
            const key = `${doc['configBaseKey']}.download.profiles`;
            setConfigKey(key);
            setAddProfileEnabled(!readOnly);
            setSelectedIndex(-1);
            setEditProfileEnabled(false);
            setDeleteProfileEnabled(false);
            const url = `/api/config/vlist?key=${key}`;
            setProfiles([]);
            netGet(url)
                .then(response => response.json())
                .then(iprofiles => {
                    if (Array.isArray(iprofiles)) {
                        console.log('Got ' + iprofiles.length + ' download profiles.\n' + JSON.stringify(iprofiles));
                        /*
                        netGet(url2)
                            .then(response => response.json())
                            .then(res => {
                                console.log('Got raw serial ' + JSON.stringify(res));
                                const rserial = res && res.v ? res.v : 0;
                                let iserial = Number(rserial);
                                if ( iserial === NaN ) {
                                    iserial = 0;
                                }
                                console.log('Got serial ' + iserial);
                                if ( iserial >= serial ) {
                                    setRules(irules);
                                }
                            }).catch ( error => {
                                console.log('Error fetching semantic rules serial: ' + error);
                            });
                        */
                        setProfiles(iprofiles);
                    }
                }).catch(error => {
                    console.log('Error fetching download profiles: ' + error);
                });
        } else {
            setConfigKey('');
            setAddProfileEnabled(false);
            setSelectedIndex(-1);
            setEditProfileEnabled(false);
            setDeleteProfileEnabled(false);
            setProfiles([]);
        }

    },[doc,readOnly]);


    const handleRowClick = (index) => {
        setSelectedIndex(index);
        if ( index >= 0 && Array.isArray(profiles) && index < profiles.length) {
            setEditProfileEnabled(!readOnly);
            setDeleteProfileEnabled(!readOnly);
        }
    };

    const handleCreateProfileAction = () => {
        setEditProfileCreateMode(true);
        setEditProfileDialogOpen(true);
    };

    const handleEditProfileAction = () => {
        setEditProfileCreateMode(false);
        setEditProfileDialogOpen(true);
    };

    const onProfileChange = (pf) => {
        netPost(`/api/config/set?key=${configKey}`, pf)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save rules: status = ' + resp.status);
                } else {
                    // delete def.saveSteps;
                    // setSaveDocumentEnabled(false);
                    // handleItemClick(selectedIndex);
                    // unmarkModified();
                }
            });
        if (typeof onChange === 'function') {
            onChange(pf);
        }
    }

    const handleDeleteProfileAction = () => {
        if ( selectedIndex >= 0 && selectedIndex < profiles.lengh) {
            const pf = [...profiles];
            pf.splice(selectedIndex,1);
            setProfiles(pf);
            onProfileChange(pf);
        }

    };

    const onEditProfileCancel = () => {
        setEditProfileDialogOpen(false);
    }

    const onEditProfileSave = (item) => {
        setEditProfileDialogOpen(false);
        const pf = [...profiles];
        if ( editProfileCreateMode ) {
            pf.push(item);
        } else if ( selectedIndex >= 0 && selectedIndex < pf.length ) {
            pf[selectedIndex] = item;
        }
        setProfiles(pf);
        onProfileChange(pf);
    };

    return (
        <Box sx={{position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid',  gridTemplateRows: 'minmax(10px,1fr) min-content'}}>
            <SplitPane
                split='horizontal'
                sizes={sizes}
                onChange={setSizes}
            >
                <Pane minSize={40} maxSize='95%'>
                    <Box sx={{ height: '100%', position: 'relative', overflow: 'auto'  }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ fontWeight: 'bold', borderBottom: 2 }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {profiles.map((profile, index) => (
                                        <TableRow selected={index === selectedIndex}
                                            onClick={() => handleRowClick(index)}
                                            key={`r-${doc.id}-${index}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                {profile.name}
                                            </TableCell>
                                            <TableCell>{profile.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Pane>
                <Box sx={{ height: '100%', position: 'relative', overflow: 'auto', borderTopColor: theme.palette.divider,  /* borderTopStyle: 'solid', */ borderTopWidth: '2px', paddingTop: 2}} >
                    <Typography> </Typography>
                </Box>
            </SplitPane>
            <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                <Tooltip title="Create Profile">
                    <IconButton color="inherit" sx={{ marginLeft: '1em', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addProfileEnabled} onClick={handleCreateProfileAction} >
                        <AddIcon color="inherit" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit Profile">
                    <IconButton color="inherit" disabled={!editProfileEnabled} onClick={handleEditProfileAction}>
                        <AutoFixNormalIcon color="inherit" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Rule">
                    <IconButton color="inherit" disabled={!deleteProfileEnabled} onClick={handleDeleteProfileAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                        <DeleteIcon color="inherit" />
                    </IconButton>
                </Tooltip>
                
                <EditProfileDialog profiles={profiles} profileIndex={selectedIndex} onCancel={onEditProfileCancel} onSave={onEditProfileSave} open={editProfileDialogOpen}
                    create={editProfileCreateMode} title={dialogTitle} doc={doc} />
            
            </Box>
        </Box>
    );
};

export { DownloadProfiles, };