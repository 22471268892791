import { createSlice } from '@reduxjs/toolkit'

// keycloak and websocket state and token
export const fileUpload = createSlice({
    name: 'fileUpload',
    initialState: {
        started: false,
        file: undefined,
        progress: 0,
        loaded: false,
        status: -1,
        errorMessage: ''
    },
    reducers: {
        setUploadStarted: (state, action) => {
            state.started = action.payload;
        },
        setUploadFile: (state, action) => {
            state.file = action.payload;
        },
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
        setUploadLoaded: (state, action) => {
            state.loaded = action.payload;
        },
        setUploadStatus: (state, action) => {
            if ( state.status < 0 || state.status === 800) {
                // do not override status unless not set yet
                state.status = action.payload;
            }
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        initFileUpload: (state, action) => {
            state.started = false;
            state.file = action.payload;
            state.progress = 0;
            state.loaded = false;
            state.errorMessage = '';
        }
    },
});

export const { setUploadFile, setUploadStarted, setUploadLoaded, setUploadProgress, setErrorMessage, initFileUpload, setUploadStatus } = fileUpload.actions;