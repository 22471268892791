import React, { Component, useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import Box from '@mui/material/Box';

import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';

import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost } from "../network";

function WorkflowStartDialog(props) {
    const {
        title,
        open,
        workflowName,
        workflowDescription,
        onSave,
        onCancel,
        workflowIndex,
        ...other
    } = props;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [instance, setInstance] = useState('');
    const [docList, setDocList] = useState([]);
    const [partList, setPartList] = useState([]);
    const [document, setDocument] = useState(null);
    const [docid, setDocid] = useState('');
    const [partid, setPartid] = useState('');
    const [part, setPart] = useState(null);

    React.useEffect( () => {
        setName(workflowName);
        setDescription(workflowDescription);
        setInstance('');
        setDocid('');
        setPartList([]);
        setDocument(null);
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    // console.log('Got ' + docs.length + ' documents.');
                    setDocList(docs);
                }
            }).catch(error => {
                console.log('Error fetching document list: ' + error);
            });
    }, [workflowName, workflowDescription]);

    React.useEffect( () => {
        setInstance('');
        setDocid('');
        setPartList([]);
        setDocument(null);
    },[open]);

    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            onSave(name, description, instance, workflowIndex, docid, part);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        setName(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescription(event.target.value);
    };

    const instanceChanged = (event) => {
        setInstance(event.target.value);
    };

    const documentChanged = (event) => {
        setDocid(event.target.value);
        const doc = docList.find( item => item.id === event.target.value);
        if ( doc ) {
            setDocument(doc);
            if ( doc.category?.includes('PARTED') ) {
                setPartid('');
                netGet('/api/doc/parts/' + encodeURIComponent(doc.id))
                    .then(response => response.json())
                    .then(parts => {
                        if ( Array.isArray(parts) ) {
                            setPartList(parts);
                        }
                    }).catch(error => console.log('Error retrieving document parts: ' + error));
            } else {
                setPartid(null);
            }
        }
    };

    const partChanged = (event) => {
        setPartid(event.target.value);
        const p = partList.find( item => item.number === event.target.value);
        if ( p ) {
            setPart(p);
            console.log('selected part: ' + JSON.stringify(p));
        }
    };

    return (
        <Dialog
            maxWidth={'1000px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: 600,
                  maxHeight: 700
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{paddingTop: '2ex'}}>
                    <TextField label="Workflow Name" value={name} onChange={nameChanged} size="small" fullWidth  
                    InputProps={{
                        readOnly: true,
                    }} />
                </Box>

                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Description" value={description} onChange={descriptionChanged} size="small" fullWidth 
                        multiline rows={3} />
                </Box>

                <Box sx={{paddingTop: '3ex'}}>
                    <TextField label="Instance Name" value={instance} onChange={instanceChanged} size="small" fullWidth autoFocus={true} 
                        helperText="Runtime instance name. It will be added to the workflow name on runtime card."
                        />
                </Box>

                <Box sx={{paddingTop: '4ex'}}>
                    <FormControl size="small" sx={{ width: '100%' }} >
                        <InputLabel id="start-select-doc-id-label" size="small">Context Document</InputLabel>
                        <Select
                            labelId="start-select-doc-id-label"
                            id="start-select-doc-id"
                            value={docid}
                            label="Context Document"
                            onChange={documentChanged}
                            size="small"
                            helperText="To use as default on document operations unless a step overrides it."
                        >
                            <MenuItem value={'NONE'} key={'NONE'}>None</MenuItem>
                            {Array.isArray(docList) &&
                                docList.map((doc, dindex) => (
                                    <MenuItem value={doc.id} key={doc.id}>{doc.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                {document && document.category?.includes('PARTED') &&
                    <Box sx={{ paddingTop: '4ex' }}>
                        <FormControl size="small" sx={{ width: '100%' }} >
                            <InputLabel id="start-select-part-id-label" size="small">Context Part</InputLabel>
                            <Select
                                labelId="start-select-part-id-label"
                                id="start-select-part-id"
                                value={partid}
                                label="Context Part"
                                onChange={partChanged}
                                size="small"
                                helperText="To use as default on document part operations unless a step overrides it."
                            >
                                <MenuItem value={'NONE'} key={'NONE'}>None</MenuItem>
                                {Array.isArray(partList) &&
                                    partList.map((part, dindex) => (
                                        <MenuItem value={part.number} key={part.number}>{`${part.number} ${part.title}`}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                }
                
                <Box sx={{paddingTop: '5ex'}}>
                    <Typography sx={{display: 'inline', fontWeight: 'bold'}}>Start workflow?</Typography>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onOKClick} sx={{minWidth: '7em'}} color="success">Start</Button>
                <Button onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function AvailableWorkflows(props) {

    const [updateDef, setUpdateDef] = React.useState(0);
    const [updateDefId, setUpdateDefId] = React.useState(-1);

    const [workflowList, setWorkflowList] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [startEnabled, setStartEnabled] = React.useState(false);

    const [workflowStartOpen, setWorkflowStartOpen] = React.useState(false);
    const [workflowStartName, setWorkflowStartName] = React.useState('');
    const [workflowStartDesc, setWorkflowStartDesc] = React.useState('');

    const theme = useTheme();

    React.useEffect(() => {
        loadInstances();
    }, []);

    React.useEffect(() => {
        if (updateDefId > 0) {
            netGet('/api/workflow/def/' + updateDefId)
                .then(response => response.json())
                .then(def => {
                    if (def && def.id) {
                        console.log('Updating Workflow Definition: ' + def.id);
                        let slist = workflowList.map((r) => {
                            if (r.id === def.id) {
                                return def;
                            }
                            return r;
                        });
                        setWorkflowList(slist);
                    }
                }).catch( error => {
                    console.log('Error fetching update definition: ' + error);
                });
        }
    }, [updateDef,updateDefId]);


    const workflowMessage = (message) => {
        let wevent = message.data;
        // console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowDefinition') {
            if ( wevent.action === 'DELETE' || wevent.action === 'RETRACT') {
                let dindex = -1;
                const def = workflowList.find( (item, index) => {
                    if ( item.id === wevent.id ) {
                        dindex = index;
                        return true;
                    }
                });
                if ( def && dindex >= 0 ) {
                    let wlist = [ ...workflowList];
                    wlist.splice(dindex, 1);
                    setWorkflowList(wlist);
                }
            } else if ( wevent.action === 'NEW' || wevent.action === 'PUBLISH') {
                loadInstances();
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else {
                const def = workflowList.find( (item) => item.id === wevent.id);
                if ( def ) {
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                }
            }
        }
    };

    MessageHooks["workflow"]["WorkflowAvailable"] = workflowMessage;


    const loadInstances = () => {
        netGet('/api/workflow/def/list?categ=DEF&pmask=2' )
            .then(response => response.json())
            .then(defs => {
                if ( defs && Array.isArray(defs) ) {
                    console.log('Workflow Definitions: ' + defs.length);
                    setWorkflowList(defs);
                }
            }).catch( error => {
                console.log('Error loading available workflows: ' + error);
            });
    };

    const serverStart = (def, label, description, instance, docid, part) => {
        let info = {
            definition: def
        };
        if ( label ) {
            info['label'] = label;
        }
        if ( description ) {
            info['description'] = description;
        }
        if ( instance ) {
            info['instance'] = instance;
        }
        if ( docid && docid !== 'NONE' && docid !== '') {
            info['document'] = docid;
        }
        if ( part ) {
            info['part'] = part;
        }
        console.log('Creating workflow instance with params: ' + JSON.stringify(info));
        netPost('/api/workflow/inst/start', info)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not start workflow: status = ' + resp.status);
                }
            });
    };

    const handleContextMenu = (event,index) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleItemClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleItemClick = (index) => {
        setSelectedIndex(index);
        if ( index >= 0 ) {
            setStartEnabled(true);
        }
    };

    const isLockedByMe = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0;
    };

    
    const handleStartWorkflowAction = () => {
        handleContextMenuClose();
        const workflow = workflowList[selectedIndex];
        setWorkflowStartName(workflow.label);
        setWorkflowStartDesc(workflow.description);
        setWorkflowStartOpen(true);
    }

    const startWorkflowSave = (newLabel, newDescription, instanceName, windex, docid, part) => {
        setWorkflowStartOpen(false);
        const workflow = workflowList[selectedIndex];
        serverStart(workflow, newLabel, newDescription, instanceName, docid, part);
    };

    const startWorkflowCancel = () => {
        setWorkflowStartOpen(false);

    };
    
    const publishedIconColor = (index) => {
        const def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if ( (def.flag & 2) != 0) {
            return 'black';
        }
        return 'transparent';
    };

    return (

        <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
            <Typography align="center" variant="h7" sx={{ paddingBottom: '2ex', fontWeight: theme.typography.fontWeightBold }}>Workflows</Typography>
            <Box sx={{textAlign: 'center'}}>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box
                sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }}
                onContextMenu={(event) => handleContextMenu(event, -1)}
            >

                <List sx={{ minHeight: '2em' }}>
                    {
                        workflowList.map((def, index) => (
                            <ListItem
                                key={'mwwp-' + index} selected={selectedIndex === index}
                                sx={{ padding: 1, paddingTop: 0, paddingBottom: 0 }}
                                onContextMenu={(event) => handleContextMenu(event, index)}
                            >
                                <Box sx={{ float: 'left', color: publishedIconColor(index), fontSize: '12px' }}>
                                    <CheckIcon fontSize="inherit" />
                                </Box>
                                <ListItemButton onClick={(event) => handleItemClick(index)} selected={selectedIndex === index}>
                                    <ListItemText>{def.label}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                        )
                    }
                </List>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={handleStartWorkflowAction} disabled={!startEnabled}>Start</MenuItem>
                </Menu>
            </Box>
            <Box sx={{ padding: '1ex', paddingRight: '2ex', whiteSpace: 'nowrap', overflowX: 'hidden', textAlign: 'right' }} color={theme.palette.primary.main}>
                <Tooltip title="Start Workflow">
                    <Button variant="outlined" startIcon={<DirectionsRunIcon fontSize="inherit" />} disabled={!startEnabled} onClick={handleStartWorkflowAction}>
                        Start
                    </Button>
                </Tooltip>
                <WorkflowStartDialog title="Start Workflow" open={workflowStartOpen} workflowName={workflowStartName} workflowDescription={workflowStartDesc}
                    onSave={startWorkflowSave} onCancel={startWorkflowCancel} />
            </Box>
        </Box>


    );

}


export { AvailableWorkflows, };