import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SchemaIcon from '@mui/icons-material/Schema';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { UserInfo, netGet, netPost } from "./network";
import 'chartjs-adapter-luxon';

// import VerticalBarChart from "./Charts/BarCharts/VerticalBarChart";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line, Bar } from 'react-chartjs-2';
  // import { faker } from '@faker-js/faker';


  ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  /*
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
*/

var samplingIntervalInst;
var samplingApps = [];

const registerSamplingApp = (appName, appCallback, freq) => {
    let appItem = samplingApps.find( value => {
        return value.name === appName;
      });
    if ( appItem ) {
        appItem.callback = appCallback;
        appItem.frequency = freq;
    } else {
        appItem = {
            name: appName,
            callback: appCallback,
            frequency: freq,
        }
        samplingApps.push(appItem);
    }
};

const removeSamplingApp = (appName) => {
    let index = -1;
    for( let i=0; i<samplingApps.length; i++) {
        if ( samplingApps[i].name === appName ) {
            samplingApps.splice(i, 1);
            break;
        }
    }
}

const runSamplingApps = () => {
    const callbacks = [...samplingApps];
    callbacks.forEach(item => {
        if (typeof item.callback === 'function') {
            item.callback();
        }
    });
};

samplingIntervalInst = setInterval(runSamplingApps, 20000);

function Chart(props) {
    const {
        chartType,
        width,
        options,
        data,
        ...other
    } = props;

    const getChart = (ctype) => {
        let c;
        switch (ctype) {
            case 'line':
                c = <Line options={options} data={data} />
                break;
            case 'bar':
                c = <Bar options={options} data={data} />
                break;
        }
        return c;
    }

    return getChart(chartType);
}

function MyChart(props) {
    const {
        chartInfo,
        ...other
    } = props;

    const [chartData, setChartData] = React.useState();

    const pollData = () => {
        netGet('/api/dashboard/data/' + chartInfo.id)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Status: ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                console.log('chartData: ' + JSON.stringify(data));
                setChartData(data);
            }).catch( error => {
                console.log('Error: ' + error);
            });
    };

    React.useEffect(() => {
        // console.log('Updating workflow menu...');
        pollData();
        registerSamplingApp(chartInfo.id, pollData, 30000);

        return () => removeSamplingApp(chartInfo.id);
    }, []);

    

    return (
        <Card sx={{ minWidth: 275, borderRadius: '15px', maxWidth: 800, marginTop: 2}}>
            <CardContent>
                { chartData && <Chart chartType={chartInfo.chartType} options={chartInfo.options} data={chartData} /> }
            </CardContent>
        </Card>
    );
}

function DashboardsMenu(props) {
    const [menuIcon, setMenuIcon] = React.useState('app');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const theme = useTheme();

    const handleEdocsAction = (event) => {
        netGet('/api/dashboard/charts')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Status: ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                let page = (<Box sx={{padding: 2, backgroundColor: '#f0f7ff', height: '100%' }}>
                    {
                        data.map( (chart) => { 
                            console.log('chartId: ' + chart.id + ', options: ' + JSON.stringify(chart.options));
                            return <MyChart chartInfo={chart} />;
                        })
                    }
                </Box>);
                DashboardsModule.pageChange(page);
            }).catch( error => {
                console.log('Error: ' + error);
            });
        // DashboardsModule.pageChange(<MyChart/>);
        setSelectedIndex(0);
    };


    const handleMenuIconClick = (event) => {
        if ( menuIcon === 'back' ) {
            DashboardsModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            DashboardsModule.menuIconChange('back');
            setMenuIcon('back');
        }
    };

    DashboardsModule.onMenuIconClick = handleMenuIconClick;

    return (
        <Box
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, paddingTop: "1ex", position: "relative" }}
            role="presentation"
        >
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="mw-dashboards" disablePadding >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                        <DashboardIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Dashboards" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="mw-edocs" disablePadding selected={selectedIndex === 0}>
                    <ListItemButton onClick={handleEdocsAction}>
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <LocalShippingIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Digital Logistics" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>

        </Box>
    );
}


const DashboardsModule = {
    // label
    label: 'Dashboards',
    barLabel: 'Dashboards',
    barShortLabel: 'ManaDashboardsgement',
    // icon
    icon: <DashboardIcon fontSize="inherit" />,
    // menu
    drawerContent: <DashboardsMenu />,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},

    hooks: {}, // { workflow: workflowInfo },
}

export default DashboardsModule;