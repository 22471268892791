import { createSlice } from '@reduxjs/toolkit'

export const processLogs = createSlice({
    name: 'processLogs',
    initialState: {
        pid: '',
        status: '',
        entries: [],
    },
    reducers: {
        setLogPid: (state, action) => {
            state.pid = action.payload;
        },
        setLogStatus: (state, action) => {
            state.status = action.payload;
        },
        setLogEntries: (state,action) => {
            state.entries = action.payload;
        },
        appendLogEntries: (state,action) => {
            state.entries = state.entries.concat(action.payload);
        },
    },
});

export const { setLogPid, setLogStatus, setLogEntries, appendLogEntries } = processLogs.actions;