import React, { Component, useRef } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Icon, Typography } from "@mui/material";
import { UserInfo, netGet, netPost, netFetch, keycloak } from "./network";

const EditedRowContext = React.createContext({
    row: '',
    setRow: (newrow) => {},
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, minHeight: '666.5px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function EventRow(props) {
    const { row, onChange } = props;
    const editedRow = React.useContext(EditedRowContext);
    // console.log('My row: ' + row.id + ', Editing row: ' + editedRow.row);
    const [ucs, setUcs] = React.useState( row.ucs );
    const [currentUcs, setCurrentUcs] = React.useState( row.ucs );
    const [messenger, setMessenger] = React.useState( row.messenger );
    const [currentMessenger, setCurrentMessenger] = React.useState( row.messenger );
    const [email, setEmail] = React.useState(row.email);
    const [currentEmail, setCurrentEmail] = React.useState(row.email);
    const [edit, setEdit] = React.useState(row.id === editedRow.row); // default value only evaluates first time, need to use it in children
    const [dirty, setDirty] = React.useState(false);
    

    const handleEditClick = (event) => {
        setCurrentUcs(ucs);
        setCurrentMessenger(messenger);
        setCurrentEmail(email);
        setEdit(true);
        editedRow.setRow(row.id);
    };
    
    const handleCancelClick = (event) => {
        setEdit(false);
        setUcs(currentUcs);
        setMessenger(currentMessenger);
        setEmail(currentEmail);
    };

    const handleSaveClick = (event) => {
        setEdit(false);
        if ( dirty ) {
            let r = { ...row};
            r.ucs = ucs;
            r.messenger = messenger;
            r.email = email;
            handleOnChange(r);
            setDirty(false);
        }
    };

    const handleUcsChange = (event) => {
        setUcs(event.target.value);
        setDirty(true);
    };

    const handleMessengerChange = (event) => {
        setMessenger(event.target.value);
        setDirty(true);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setDirty(true);
    };

    const handleOnChange = (r) => {
        if ( typeof onChange === 'function' ) {
            onChange(r);
        }
    }

    return (
        <TableRow >
            <TableCell sx={{ width: '1.5em' }} />
            <TableCell sx={{ width: 'calc(100% - 36em)' }}>{row.name}</TableCell>
            <TableCell align="center" sx={{ width: '10.2em' }}>
                <Typography sx={{fontSize: '0.99em', display: edit && row.id === editedRow.row ? 'none' : 'inline'}} >{ucs}</Typography>
                <FormControl sx={{ m: 1, minWidth: 120,  display: edit && row.id === editedRow.row ? 'inline-block' : 'none'}} size="small">
                    <Select
                        sx={{fontSize: '0.95em'}}
                        value={ucs}
                        onChange={handleUcsChange}
                    >
                        <MenuItem value="ERROR"  sx={{fontSize: '0.95em'}} >ERROR</MenuItem>
                        <MenuItem value="INFO"   sx={{fontSize: '0.95em'}} >INFO</MenuItem>
                        <MenuItem value="SILENT" sx={{fontSize: '0.95em'}}>SILENT</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center" sx={{ width: '10.2em' }}>
                <Typography sx={{fontSize: '0.99em', display: edit && row.id === editedRow.row ? 'none' : 'inline'}} >{messenger}</Typography>
                <FormControl sx={{ m: 1, minWidth: 120, display: edit && row.id === editedRow.row ? 'inline-block' : 'none'}} size="small">
                    <Select
                        sx={{fontSize: '0.95em'}}
                        value={messenger}
                        onChange={handleMessengerChange}
                    >
                        <MenuItem value="ERROR"  sx={{fontSize: '0.95em'}}>ERROR</MenuItem>
                        <MenuItem value="INFO"   sx={{fontSize: '0.95em'}}>INFO</MenuItem>
                        <MenuItem value="SILENT" sx={{fontSize: '0.95em'}}>SILENT</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center" sx={{ width: '10.2em' }}>
                <Typography sx={{fontSize: '0.99em', minWidth: '4em', display: edit && row.id === editedRow.row ? 'none' : 'inline'}} >{email}</Typography>
                <FormControl sx={{ m: 1, minWidth: '4em', display: edit && row.id === editedRow.row ? 'inline-block' : 'none'}} size="small">
                    <Select
                        sx={{fontSize: '0.95em'}}
                        value={email}
                        onChange={handleEmailChange}
                    >
                        <MenuItem value="ERROR"  sx={{fontSize: '0.95em'}}>ERROR</MenuItem>
                        <MenuItem value="INFO"   sx={{fontSize: '0.95em'}}>INFO</MenuItem>
                        <MenuItem value="SILENT" sx={{fontSize: '0.95em'}}>SILENT</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center" sx={{ width: '14em' }}>
                <Box sx={{display: 'flex', flexWrap: 'nowrap'}}>
                    <Button onClick={handleEditClick} sx={{ display: edit && row.id === editedRow.row ? 'none' : 'inline' }} >Edit</Button>
                    <Button onClick={handleSaveClick} sx={{ display: edit && row.id === editedRow.row ? 'inline' : 'none' }} >Save</Button>
                    <IconButton
                        size="small"
                        color="error"
                        sx={{ position: 'relative', top: 2, visibility: edit && row.id === editedRow.row ? 'visible' : 'hidden' }}
                        onClick={handleCancelClick} >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
}

function Row(props) {
    const { row, onChange } = props;
    const [open, setOpen] = React.useState(true);


    const handleOnChange = (event) => {
        if (typeof onChange === 'function') {
            // console.log('Config event changed: ' + JSON.stringify(event));
            let r = { ...row};
            r.events = [ ...row.events];
            let ucsRequested = false;
            r.events.forEach( e => {
                if ( e.id === event.id) {
                    if ( (typeof e.ucs === 'undefined' || e.ucs === 'SILENT') && (event.ucs === 'ERROR' || event.ucs === 'INFO') ) {
                        ucsRequested = true;
                    }
                    e.ucs = event.ucs;
                    e.messenger = event.messenger;
                    e.email = event.email;
                }
            });
            if ( ucsRequested ) {
                requestOSNotifications();
            }
            onChange(r);
        }
    }
    
    const requestOSNotifications = () => {
        // Check if the browser supports notifications
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
            return;
        }
        if ( !(Notification.permission === 'granted') ) {
            Notification.requestPermission((result) => {
                console.log('OS notification permission = ' + result);
            });
        }
    };
    
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                    {row.name}
                </TableCell>
                <TableCell align="center">{row.ucs}</TableCell>
                <TableCell align="center">{row.messenger}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit >
                            <Table size="small" aria-label="events">
                                <TableBody>
                                    {row.events.map((eventRow) => (
                                        <EventRow row={eventRow} onChange={handleOnChange} />
                                    ))}
                                </TableBody>
                            </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
        ucs: PropTypes.string,
        messenger: PropTypes.string,
        email: PropTypes.string,
        events: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                ucs: PropTypes.string.isRequired,
                messenger: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

function createEventData(id, name, ucs, messenger, email) {
    return {
        id,
        name,
        ucs,
        messenger,
        email
    }
}

function createRowData(name, ucs, messenger, email, events) {
    return {
        name,
        ucs,
        messenger,
        email,
        events,
    };
}
/*
const rows = [
    createRowData('Databases','','',
        [
            createEventData('E1', 'Airports: New Data (when a new airport is added)', 'INFO', 'ERROR'),
            createEventData('E2', 'Airports: Data Modified (when Modify is saved)', 'INFO', 'ERROR'),
            createEventData('E3', 'Airports: Data Deleted', 'INFO', 'ERROR'),
        ]
    ),
    createRowData('Digital Logistics','','',
        [
            createEventData('E4', 'Reception: Document Arrival', 'INFO', 'ERROR'),
        ]
    ),
    createRowData('Workflow','','',
        [
            createEventData('E5', 'Open Edition: Start', 'INFO', 'ERROR'),
            createEventData('E6', 'Open Edition: Completion/Failure', 'INFO', 'ERROR'),
            createEventData('E7', 'Draft Output: Start', 'INFO', 'ERROR'),
            createEventData('E8', 'Draft Output: Completion/Failure', 'INFO', 'ERROR'),
            createEventData('E9', 'Edition Output: Start', 'INFO', 'ERROR'),
            createEventData('E10', 'Edition Output: Completion/Failure', 'INFO', 'ERROR'),
        ]
    ),
];
*/
const sample_rows = [
    createRowData('Workflow','','','',
        [
            createEventData('workflow_actions', 'Runtime', 'INFO', 'ERROR', 'INFO'),
        ]
    ),
];

function CollapsibleTable(props) {
    const {
        onChange,
        rows,
        ...other
    } = props;
    const [editedRow, setEditedRow] = React.useState('');

    const handleOnChange = (r) => {
        // console.log('Config row changed: ' + JSON.stringify(r));
        if ( typeof onChange === 'function' ) {
            onChange(r);
        }
    }
    
    return (
        <EditedRowContext.Provider value={{ row: editedRow, setRow: setEditedRow }}>
            <TableContainer component={Paper} sx={{ overflowY: 'auto', maxHeight: 600 }}>
                <Table aria-label="collapsible table" stickyHeader >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '2.5em' }} />
                            <TableCell sx={{ width: 'calc(100% - 37em)', fontWeight: 'bold', fontSize: '1.02em' }}>Events</TableCell>
                            <TableCell align="center" sx={{ width: '10em', fontWeight: 'bold', fontSize: '1.02em' }}>UCS</TableCell>
                            <TableCell align="center" sx={{ width: '9em', fontWeight: 'bold', fontSize: '1.02em' }}>Messenger</TableCell>
                            <TableCell align="center" sx={{ width: '11em', fontWeight: 'bold', fontSize: '1.02em' }}>Email</TableCell>
                            <TableCell align="right" sx={{ width: '11em' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row row={row} onChange={handleOnChange} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </EditedRowContext.Provider>
    );
}

function RegisterTelegramDialog(props) {
    const {
        title = 'Telegram Registration',
        open,
        onClose,
        info, // telegram info
        ...other
    } = props;

    const onCloseClick = () => {
        if ( typeof onClose === 'function' ) {
            onClose();
        }
    };

    const onLocalClose = () => {
        if ( typeof onCancel === 'function' ) {
            onClose();
        }
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onLocalClose}
            PaperProps={{
                sx: {
                  minWidth: 500,
                  maxHeight: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                
                <Box sx={{paddingTop: '0.1ex'}}>
                    <ol>
                        <li style={{paddingBottom: '5px'}}>Install Telegram application on your device and sign in.</li>
                        <li style={{paddingBottom: '5px'}}>Click or tap on the following link:<br />
                            <a href={info.registerUrl} target="_blank">Uniscope UCS Notifications Bot</a>
                        </li>
                        <li>Or scan the QR code and open the link with Telegram:<br />
                            <div align="center"><img style={{position: 'relative', left: '-15px', paddingTop: '1ex'}} alt="Uniscope UCS Notifications Bot" src={`data:image/png;base64,${info.registerQrcode}`}/></div> </li>
                    </ol>
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} sx={{minWidth: '7em'}} >Close</Button>
            </DialogActions>
        </Dialog>

    );

}

function PreferencesDialog(props) {
    const { onClose, open, ...other } = props;
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [registerOpen, setRegisterOpen] = React.useState(false);
    const [telegramInfo, setTelegramInfo] = React.useState({});
    const [registrationNeeded, setRegistrationNeeded] = React.useState(false);
    const [config,setConfig] = React.useState({});
    const [dirty, setDirty] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const timerIdRef = useRef(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const INTERVAL = 7000;
  
    React.useEffect(() => {
        if (open) {
            netGet('/api/notify/telegram/info?qr=1')
                .then(response => response.json())
                .then((data) => {
                    setTelegramInfo(data);
                    setRegistrationNeeded(!data.registered);
                    if ( data.registered ) {
                        stopPolling();
                    } else {
                        startPolling();
                    }
                });
            netGet('/api/notify/config')
                .then(response => response.json())
                .then((data) => {
                    setConfig(data);
                    loadRows(data);
                    setDirty(false);
                });
        } else {
            if ( config && dirty) {
                netPost('/api/notify/config', config)
                .then(response => {
                    if (response.ok) {
                        setDirty(false);
                        console.log('Updating configuration: ' + JSON.stringify(config));
                    }
                });
            }
        }
        return () => {
            stopPolling();
          };
    },[open]);

    React.useEffect( () => {
        if ( open && registrationNeeded ) {
            console.log('Starting polling registration status...');
            startPolling();
        } else {
            // console.log('No polling logs needed!');
            stopPolling();
            setRegisterOpen(false);
        }
    },[registrationNeeded]);

    const startPolling = () => {
        // pollingCallback(); // To immediately start fetching data
        // Polling every 3 seconds
        timerIdRef.current = setInterval(pollingCallback, INTERVAL);
    };

    const stopPolling = () => {
        if ( timerIdRef?.current ) {
            clearInterval(timerIdRef.current);
        }
    };

    const pollingCallback = () => {
        getRegistrationStatus();
    };

    const getRegistrationStatus = () => {
        netGet('/api/notify/telegram/info?qr=1')
                .then(response => response.json())
                .then((data) => {
                    setTelegramInfo(data);
                    setRegistrationNeeded(!data.registered);
                });
    };

    const loadRows = (cnf) => {
        if (cnf) {
            const rr = [
                createRowData('Workflow', '', '', '', [
                    createEventData('workflow_actions', 'Runtime',
                        getOsLevel(cnf['workflow_actions_ucs']),
                        getLevel(cnf['workflow_actions_messenger']),
                        getLevel(cnf['workflow_actions_email']))
                ]
                )
            ];
            setRows(rr);
        }
            
    };

    const getLevel = (v) => {
        if ( v === 'ERROR' || v === 'INFO' || v === 'SILENT' ) {
            return v;
        }
        return 'SILENT';
    }

    const getOsLevel = (v) => {
        if ( "Notification" in window && ! (Notification.permission === 'granted') ) {
            return 'SILENT';
        }
        return getLevel(v);
    }

    const handleMaxWidthChange = (event) => {
      setMaxWidth(
        // @ts-expect-error autofill of arbitrary value is not handled.
        event.target.value,
      );
    };
  
    const handleFullWidthChange = (event) => {
      setFullWidth(event.target.checked);
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleNotificationsChange = (event) => {
        config.notifications = event.target.checked;
        setDirty(true);
    }

    const handleRowChange = (r) => {
        if ( Array.isArray(r.events) ) {
            let c = { ...config};
            r.events.forEach( e => {
                c[`${e.id}_ucs`] = e.ucs;
                c[`${e.id}_messenger`] = e.messenger;
                c[`${e.id}_email`] = e.email;
            });
            setConfig(c);
            setDirty(true);
            console.log('Updated configuration: ' + JSON.stringify(c));
        }
    };

    const onSave = () => {
        if ( typeof onClose === 'function') {
            onClose();
        }
    };

    const onCancel = () => {
        setDirty(false);
        if ( typeof onClose === 'function') {
            onClose();
        }
    }
  
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            
            maxWidth={maxWidth}
            minHeight={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  maxHeight: 900
                }
              }}
        >
            <DialogTitle>Preferences</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="preferences tabs">
                        <Tab label="Notifications" {...a11yProps(0)} />
                        {/* <Tab label="Security" {...a11yProps(1)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    
                    <FormControlLabel control={<Switch checked={config?.notifications} onChange={handleNotificationsChange} />} label="Enable Notifications & Alerts" labelPlacement="start" sx={{paddingBottom: '1.4em',}}/>
                    
                    <Divider/>

                    <CollapsibleTable onChange={handleRowChange} rows={rows} />
                </TabPanel>
                {/*
                <TabPanel value={tabValue} index={1}>
                    <Typography>Secured</Typography>
                </TabPanel>
                */}
                <RegisterTelegramDialog open={registerOpen} info={telegramInfo} onClose={() => setRegisterOpen(false)} />
            </DialogContent>
            <DialogActions sx={{justifyContent: 'space-between', padding: 2}}>
                <Button onClick={() => setRegisterOpen(true)} disabled={!registrationNeeded}>Register Messenger...</Button>
                <Typography sx={{flexGrow: '1', paddingLeft: 1}}>{registrationNeeded ? 'Telegram Messenger not registered yet.' : 'Telegram Messenger successfully registered'}</Typography>
                <Button onClick={onSave}>Close</Button>
            </DialogActions>
        </Dialog>
    );
  }

  export { PreferencesDialog } ;