import React, { Component, useState, useCallback, useEffect, useRef } from "react";
import { LogViewer } from '@patternfly/react-log-viewer';
import dayjs from 'dayjs';
// import { FixedSizeList } from "react-window";
// import InfiniteLoader from "react-window-infinite-loader";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import store from '../../app/store';
/*
import { setLogPid, setLogStatus, setLogEntries, appendLogEntries } from '../../features/process/processSlice';
import { useSelector, useDispatch } from 'react-redux';
*/
import { netGet } from "../network";

var logEntries = [];
var infoValue = true;
var warnValue = true;
var errorValue = true;

const setInfoValue = (v) => {
    infoValue = v;
};

const setWarnValue = (v) => {
    warnValue = v;
};

const setErrorValue = (v) => {
    errorValue = v;
};

/*
import { useEffect, useState, useRef } from 'react';
import { usePageVisibility } from './usePageVisibility';

export function MyComponent() {
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);

  useEffect(() => {
    const pollingCallback = () => {
      // Your polling logic here
      console.log('Polling...');
      // Simulating an API failure in the polling callback
      const shouldFail = Math.random() < 0.2; // Simulate 20% chance of API failure

      if (shouldFail) {
        setIsPollingEnabled(false);
        console.log('Polling failed. Stopped polling.');
      }
    };

    const startPolling = () => {
      // pollingCallback(); // To immediately start fetching data
      // Polling every 30 seconds
      timerIdRef.current = setInterval(pollingCallback, 30000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };
    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPageVisible, isPollingEnabled]);

  return (
    // JSX for your component
  );
}
*/

function ProcessLogViewer(props) {
    const {
        pid,
        open,
        title = 'Log Viewer',
        onClose,
        ...other
    } = props;
    const [logData, setLogData] = useState([]);
    // const [logEntries, setLogEntries] = useState([]);
    const [scrollIndex, setScrollIndex] = useState(-1);
    /*
    const [infoValue, setInfoValue] = useState(true);
    const [warnValue, setWarnValue] = useState(true);
    const [errorValue, setErrorValue] = useState(true);
    */
    const [isPollingEnabled, setIsPollingEnabled] = useState(true);
    const [logCount, setLogCount] = useState(0);
    const [totalCount, setTotalCount] = useState(-1);
    const timerIdRef = useRef(null);
    /*
    const logEntries = useSelector(state => state.processLogs.entries);
    const logPid = useSelector(state => state.processLogs.pid);
    const logStatus = useSelector(state => state.processLogs.status);

    const dispatch = useDispatch();
    */

    const LIMIT = 300;
    const INTERVAL = 4000;

    const RED = "\x1b[31m";
    const GRAY = "\x1b[38;5;8m";
    const DARKGRAY = "\x1b[38;5;233m";
    const BLUE = "\x1b[38;5;26m";
    const ORANGE = "\x1b[38;5;214m";
    const INFO =  "INFO ";
    const WARN =  "WARN ";
    const ERROR = "ERROR";

    useEffect( () => {
        if ( open ) {
            getLogStatus(true);
        }
        if ( open && isPollingEnabled ) {
            // console.log('Starting polling logs...');
            startPolling();
        } else {
            // console.log('No polling logs needed!');
            stopPolling();
        }
        return () => {
            stopPolling();
          };

    }, [open,pid]);
    /*
    useEffect( () => {
        
    },[pid]);
    */

    useEffect( () => {
        if ( open && isPollingEnabled ) {
            // console.log('Starting polling logs...');
            startPolling();
        } else {
            // console.log('No polling logs needed!');
            stopPolling();
        }
    },[isPollingEnabled]);

    

    const startPolling = () => {
        // pollingCallback(); // To immediately start fetching data
        // Polling every 3 seconds
        timerIdRef.current = setInterval(pollingCallback, INTERVAL);
    };

    const stopPolling = () => {
        if ( timerIdRef?.current ) {
            clearInterval(timerIdRef.current);
        }
    };

    const pollingCallback = () => {
        getLogStatus(false);
    };

    const getLogStatus = (clear) => {
        if (pid) {
            netGet('/api/process/logcount/' + pid)
                .then(response => response.json())
                .then(countStatus => {
                    console.log('LOG count status = ' + JSON.stringify(countStatus) + ", clear = " + clear);
                    if ( clear ) {
                        logEntries = [];
                    }
                    let offset = logEntries.length;
                    const needsPolling = !(countStatus.status === 'COMPLETED' || countStatus.status === 'FAILED') || offset < countStatus.count;
                    // dispatch(setLogStatus(countStatus.status));
                    setTotalCount(countStatus.count);
                    setIsPollingEnabled(needsPolling);
                    console.log('NEEDS POLLING = ' + needsPolling + " offset=" + offset);
                    if (needsPolling) {
                        pollLogs(offset, LIMIT);
                    }
                }).catch(error => {
                    // ignore
                });
        }
    };

    const pollLogs = (offset,limit) => {
        if (pid) {
            netGet(`/api/process/logs/${pid}?offset=${offset}&limit=${limit}`)
                .then(response => response.json())
                .then(data => {
                    // console.log('LOGS: ' + pid + ': ' + JSON.stringify(data));
                    // console.log("\u001b[32mHa, I'm green, obviously.");

                    if (Array.isArray(data)) {
                        data.forEach( item => logEntries.push(item));
                        console.log("Entries length = " + logEntries.length);
                        processLogs();
                    }
                })
                .catch(error => {
                    // ignore
                });
        }
    }

    const processLogs = () => {
        if (Array.isArray(logEntries)) {
            let logs = []; // offset <= 0 ? [] : [ ...logData];
            let count = 0;
            console.log('Log entries size = ' + logEntries.length);
            logEntries.forEach(r => {
                //const l = `${DARKGRAY}${r.level} ${r.logDate}${GRAY}${r.message}`;
                // logs.push(l);
                // entries.push(r);
                let date = dayjs(r.logDate).format('[[]YY-MM-DD HH:mm:ss]');
                let line;
                switch (r.level) {
                    case 'INFO':
                        if (infoValue) {
                            line = `${BLUE}${INFO} ${DARKGRAY}${date} ${GRAY}${r.message}`;
                            logs.push(line);
                            count += r.message.split(/\r\n|\r|\n/).length;
                        }
                        break;
                    case 'WARN':
                        if (warnValue) {
                            line = `${ORANGE}${WARN} ${DARKGRAY}${date} ${GRAY}${r.message}`;
                            logs.push(line);
                            count += r.message.split(/\r\n|\r|\n/).length;
                        }
                        break;
                    case 'ERROR':
                        if (errorValue) {
                            line = `${RED}${ERROR} ${DARKGRAY}${date} ${GRAY}${r.message}`;
                            logs.push(line);
                            count += r.message.split(/\r\n|\r|\n/).length;
                        }
                        break;
                }
            });
            setLogData(logs);
            setTimeout(() => {
                setScrollIndex(count - 1);
                // console.log('LOAD LOGS = ' + count);

            }, 200);
        }
    };

    const filterLogs = (infoFlag, warnFlag, errorFlag) => {
        if ( Array.isArray(logEntries) ) {
            let logs = [];
            let count = 0;
            logEntries.forEach( r => {
                let date = dayjs(r.logDate).format('[[]YY-MM-DD HH:mm:ss]');
                let line='';
                if ( r.level === 'INFO' && infoFlag ) {
                    line = `${BLUE}${INFO} ${DARKGRAY}${date} ${GRAY}${r.message}`;
                    logs.push(line);
                    count += r.message.split(/\r\n|\r|\n/).length;
                } 
                if ( r.level === 'WARN' && warnFlag) {
                    line = `${ORANGE}${WARN} ${DARKGRAY}${date} ${GRAY}${r.message}`;
                    logs.push(line);
                    count += r.message.split(/\r\n|\r|\n/).length;
                }
                if ( r.level === 'ERROR' && errorFlag) {
                    line = `${RED}${ERROR} ${DARKGRAY}${date} ${GRAY}${r.message}`;
                    logs.push(line);
                    count += r.message.split(/\r\n|\r|\n/).length;
                }
                
            });
            setLogData(logs);
            //setScrollIndex(count-1);
            setScrollIndex(1);
            //console.log('LOGS = ' + count);
        }
    };


    const onCancelClick = () => {
        if ( typeof onClose === 'function' ) {
            onClose();
        }
    };

    const infoChanged = (event) => {
        setInfoValue(event.target.checked);
        filterLogs(event.target.checked, warnValue, errorValue);
    }

    const warnChanged = (event) => {
        setWarnValue(event.target.checked);
        filterLogs(infoValue, event.target.checked, errorValue);
    }

    const errorChanged = (event) => {
        setErrorValue(event.target.checked);
        filterLogs(infoValue, warnValue, event.target.checked);
    }

    return (
        <Dialog
            maxWidth={'980px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 1080,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{paddingBottom: '1ex'}}>
                    <FormControlLabel control={<Checkbox checked={infoValue}
                                onChange={infoChanged} />} label="INFO" />
                    <FormControlLabel control={<Checkbox checked={warnValue}
                                onChange={warnChanged} />} label="WARN" />
                    <FormControlLabel control={<Checkbox checked={errorValue}
                                onChange={errorChanged} />} label="ERROR" />
                </Box>
                <LogViewer
                    hasLineNumbers={false}
                    height={300}
                    width={1050}
                    data={logData}
                    scrollToRow={scrollIndex}
                    style={{fontFamily: 'mono', fontSize: '10pt'}}
                />

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Close</Button>
            </DialogActions>
        </Dialog>
    );

}
/*
function LogViewer1(props) {
    const {
        pid,
        ...other
    } = props;
    const [itemCount, setItemCount] = useState(0);
    const [items, setItems] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(true);

    const isItemLoaded = index => !hasNextPage || index < items.length;

    const loadMoreItems = (startIndex, stopIndex) => {
        for (let index = startIndex; index <= stopIndex; index++) {
          itemStatusMap[index] = LOADING;
        }
        return new Promise(resolve =>
          setTimeout(() => {
            for (let index = startIndex; index <= stopIndex; index++) {
              itemStatusMap[index] = LOADED;
            }
            resolve();
          }, 2500)
        );
      };

    // Render an item or a loading indicator.
    const Item = ({ index, style }) => {
        let content;
        if (!isItemLoaded(index)) {
            content = "Loading...";
        } else {
            content = items[index].level + ' ' + items[index].logDate + ' ' + items[index].message;
        }

        return <div style={style}>{content}</div>;
    };

}
*/

export { ProcessLogViewer };