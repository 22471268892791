import React, { Component, useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { OtherHouses } from "@mui/icons-material";
import { UserInfo, netGet, netPost } from "../network";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';

import { Modules } from "App";

const PERMISSION_LIST = [
    { value: 'HIDE', label: 'Hide'},
    { value: 'DENY', label: 'Deny Access'},
    { value: 'WRITE', label: 'Allow Full Access'},
    { value: 'READ', label: 'Allow Read-Only Access'}
];

function AddACEDialog(props) {
    const {
        title = 'Add Access Control Entry',
        open,
        onSave,
        onCancel,
        ace,
        path,
        edit=false,
        ...other
    } = props;

    const [roleList, setRoleList] = useState([]);
    const [roleValue, setRoleValue] = useState('');
    const [permissionValue, setPermissionValue] = useState('');

    useEffect(() => {
        if ( open ) {
            netGet('/api/auth/roles')
                .then(response => response.json())
                .then(roles => {
                    if (Array.isArray(roles)) {
                        // console.log('Got ' + docs.length + ' documents.');
                        const rr = ['ALL', ...roles];
                        setRoleList(rr);
                        if ( !ace?.subject && rr.length > 0) {
                            setRoleValue(rr[0]);
                        }
                    }
                }).catch(error => {
                    console.log('Error fetching role list: ' + error);
                });
            
        }
        if ( ace ) {
            if ( ace.subject ) {
                setRoleValue(ace.subject);
            }
            if ( ace.permission ) {
                setPermissionValue(ace.permission);
            } else {
                setPermissionValue(PERMISSION_LIST[0].value);
            }
        } else {
            setPermissionValue(PERMISSION_LIST[0].value);
        }
    }, [open, ace]);


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave({
                path: path,
                subject: roleValue,
                permission: permissionValue,
                inherited: false,
                from: '',
            });
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const onRoleValueChanged = (event) => {
        setRoleValue(event.target.value);
    };

    const onPermissionValueChanged = (event) => {
        setPermissionValue(event.target.value);
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 500,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <FormControl size="small" sx={{ width: '100%' }}>
                        <InputLabel id="ace-add-role-label" size="small">Role*</InputLabel>
                        <Select
                            labelId="ace-add-role-label"
                            id="ace-add-role-sel"
                            value={roleValue}
                            label="Role*"
                            onChange={onRoleValueChanged}
                            size="small"
                            required
                        >
                            {Array.isArray(roleList) &&
                                roleList.map((role, rindex) => (
                                    <MenuItem value={role} key={`ace-add-role-${role}`}>{role}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <FormControl size="small" sx={{ width: '100%' }}>
                        <InputLabel id="ace-add-perm-label" size="small">Permission/Restriction*</InputLabel>
                        <Select
                            labelId="ace-add-perm-label"
                            id="ace-add-perm-sel"
                            value={permissionValue}
                            label="Permission/Restriction*"
                            onChange={onPermissionValueChanged}
                            size="small"
                            required
                        >
                            {Array.isArray(PERMISSION_LIST) &&
                                PERMISSION_LIST.map((perm, rindex) => (
                                    <MenuItem value={perm.value} key={`ace-add-perm-${perm.value}`}>{perm.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{padding: 3}}>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} >OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function AclConfiguration(props) {

    const {
        open,
        ...other
    } = props;

    const [menuList, setMenuList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedItemId, setSelectedItemId] = useState('');
    const [modified, setModified] = useState([]);
    const [aclMap, setAclMap] = useState({});
    const [labelMap, setLabelMap] = useState({});
    const [deleteEnabled, setDeleteEnabled] = useState(false);
    const [moveUpEnabled, setMoveUpEnabled] = useState(false);
    const [moveDownEnabled, setMoveDownEnabled] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [addAceOpen, setAddAceOpen] = useState(false);

    const theme = useTheme();

    useEffect( () => {
        
        if ( open ) {
            // console.log('OPEN MENU ACL Config...');
            let mp = {};
            const mlist = [];
            const lmap = {};
            lmap['/C'] = 'Uniscope Cloud Portal';
            Modules.forEach( mod => {
                // console.log('Module: ' + JSON.stringify(mod));
                if ( Array.isArray(mod.aclPaths) ) {
                    mod.aclPaths.forEach( entry => {
                        let acl = { ...entry};
                        mp[acl.path] = acl;
                        lmap[acl.path] = acl.label;
                        const n =acl.path.lastIndexOf('/');
                        let hasParent = false;
                        if ( n >= 0 ) {
                            const ppath = acl.path.substring(0, n);
                            if ( mp[ppath]) {
                                if ( !mp[ppath].children ) {
                                    mp[ppath]['children'] = [];
                                }
                                mp[ppath]['children'].push(acl);
                                hasParent = true;
                            }
                        }
                        if ( !hasParent ) {
                            mlist.push(acl);
                        }
                    });

                }
            });
            
            if (mp['/C/workflow/instance']) {
                netGet('/api/workflow/inst/find/instances')
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Status: ' + response.status);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if ( Array.isArray(data) ) {
                            if ( !mp['/C/workflow/instance'].children ) {
                                mp['/C/workflow/instance']['children'] = [];
                            }
                            data.forEach( inst => {
                                let label = inst.label;
                                if ( inst.properties.instanceName ) {
                                    label = label + ': ' + inst.properties.instanceName;
                                } else {
                                    label = label + ': ' + inst.description;
                                }
                                const path = '/C/workflow/instance/' + inst.name;
                                mp['/C/workflow/instance']['children'].push({path: path, label: label});
                                lmap[path] = label;
                            });
                            setMenuList(mlist);
                            setLabelMap(lmap);
                        } else {
                            setMenuList(mlist);
                            setLabelMap(lmap);
                        }
                    }).catch(error => {
                        console.log('Error: ' + error);
                        setMenuList(mlist);
                        setLabelMap(lmap);
                    });
            } else {
                setMenuList(mlist);
                setLabelMap(lmap);
            }

        } else {
            console.log('CLOSE MENU ACL Config...');
        }
        

    }, [open]);

    /*
    const lockIconLabel = (index) => {
        const doc = documentList[index];
        if ( doc.configLock && doc.configLock !== '' ) {
            return 'Locked by ' + doc.configLock;
        }
        return '';
    };

    const lockIconColor = (index) => {
        const doc = documentList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (doc.configLock && doc.configLock !== '') {
            if (doc.configLock === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };
    */

    /*
    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    };

    const isLockedByMe = (index) => {
        let doc = documentList[index];
        return typeof doc.configLock === 'string' && doc.configLock.length > 0 && doc.configLock === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let doc = documentList[index];
        return typeof doc.configLock === 'string' && doc.configLock.length > 0;
    };
    */

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    /*
    const markModified = () => {
        const m = [ ...modified];
        if ( !m.includes(selectedIndex) ) {
            m.push(selectedIndex);
        }
        setModified(m);
        
        setSaveDocumentEnabled(true);
        // setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter( item => item !== selectedIndex);
        setModified(m);
        setCheckinEnabled(isLockedByMe(selectedIndex));
        setSaveDocumentEnabled(false);
        // setUndoSaveEnabled(false);
    };

    const doUnmarkModified = (index) => {
        const m = modified.filter( item => item !== index);
        setModified(m);
        // setCheckinEnabled(isLockedByMe(index));
    };
    */

    const handleRowClick = (index, entry) => {
        setSelectedIndex(index);
        if ( index >= 0 && !entry.inherited ) {
            setDeleteEnabled(true)
            if ( index < aclMap[selectedItemId].length - 1 ) {
                setMoveDownEnabled(true);
            } else {
                setMoveDownEnabled(false);
            }
            if ( index > 0 ) {
                if ( Array.isArray(aclMap[selectedItemId]) && !aclMap[selectedItemId][index-1].inherited ) {
                    setMoveUpEnabled(true);
                } else {
                    setMoveUpEnabled(false);
                }
            } else {
                setMoveUpEnabled(false);
            }
        } else {
            setDeleteEnabled(false);
            setMoveDownEnabled(false);
            setMoveUpEnabled(false);
        }
    };

    const handleContextMenu = (event, index, entry) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if ( index >= 0 ) {
            handleRowClick(index,entry);
        }
    };

    const handleContextMenuClose = (event) => {
        setContextMenu(null);
    };

    const handleItemClick = (event, itemId) => {
        setSelectedItemId(itemId);
        setSelectedIndex(-1);
        setDeleteEnabled(false);
        setMoveDownEnabled(false);
        setMoveUpEnabled(false);
        // if ( ! aclMap[itemId] ) { // always update
            netGet('/api/auth/acl/get?path=' + encodeURIComponent(itemId))
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    
                    if ( Array.isArray(data) ) {
                        const aclm = {...aclMap};
                        aclm[itemId] = data;
                        setAclMap(aclm);
                        // console.log(`ACL[${itemId}] = ` + JSON.stringify(data));
                    }
                }).catch(error => {
                    console.log('Error: ' + error);
                });
        // }
        
    };

    const getPermissionLabel = (ace) => {
        if ( ace.permission === 'WRITE' ||  ace.permission === 'MANAGE') {
            return 'Allow Full Access';
        } else if ( ace.permission === 'HIDE') {
            return 'Hide';
        } else if ( ace.permission === 'DENY') {
            return 'Deny Access';
        } else if ( ace.permission === 'READ') {
            return 'Allow Read-Only Acesss';
        }
    };

    const getFromLabel = (ace) => {
        if ( ace.from ) {
            if ( labelMap[ace.from]) {
                return labelMap[ace.from];
            }
        }
        return '';
    }

    const renderMenuItem = (item) => {
        return (
            <TreeItem itemId={item.path} label={item.label} selected={selectedItemId === item.path}>
                {Array.isArray(item.children) && item.children.map( subitem => renderMenuItem(subitem) )}
            </TreeItem>
        )
    };

    const handleMenuAdd = () => {
        handleContextMenuClose();
        setAddAceOpen(true);
    };

    const handleMenuDelete = () => {
        handleContextMenuClose();
        const entry = aclMap[selectedItemId];
        if ( Array.isArray(entry) && selectedIndex >= 0 && selectedIndex < entry.length) {
            // console.log('TO DELETE: ' + selectedIndex);

            entry.splice(selectedIndex, 1);
            const req = {
                path: selectedItemId,
                acl: entry.filter( e => !e.inherited ),
            };
            req.acl.reverse();
            // console.log('AFTER DELETE: ' + JSON.stringify(req));

            netPost('/api/auth/acl/put', req)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    
                    if ( Array.isArray(data) ) {
                        const aclm = {...aclMap};
                        aclm[req.path] = data;
                        setAclMap(aclm);
                        // console.log(`ACL[${req.path}] = ` + JSON.stringify(data));
                        handleRowClick(-1);
                    }
                }).catch(error => {
                    console.log('Error: ' + error);
                });

        }

    };

    const handleMenuUp = () => {
        handleContextMenuClose();
        if (selectedIndex > 0) {
            const eacl = aclMap[selectedItemId];
            let sd = [...eacl];
            const p = sd[selectedIndex - 1];
            const n = selectedIndex - 1;
            sd[selectedIndex - 1] = sd[selectedIndex];
            sd[selectedIndex] = p;
            
            const req = {
                path: selectedItemId,
                acl: sd.filter( e => !e.inherited ),
            };
            // console.log('AFTER MOVE UP: ' + JSON.stringify(req));

            netPost('/api/auth/acl/put', req)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    
                    if ( Array.isArray(data) ) {
                        const aclm = {...aclMap};
                        aclm[req.path] = data;
                        setAclMap(aclm);
                        // console.log(`ACL[${req.path}] = ` + JSON.stringify(data));
                        handleRowClick(n,data);
                    }
                }).catch(error => {
                    console.log('Error: ' + error);
                });
        }

    };

    const handleMenuDown = () => {
        handleContextMenuClose();
        const eacl = aclMap[selectedItemId];
        let sd = [...eacl];
        if (selectedIndex >= 0 && selectedIndex < sd.length - 1) {
            const p = sd[selectedIndex + 1];
            const n = selectedIndex + 1;
            sd[selectedIndex + 1] = sd[selectedIndex];
            sd[selectedIndex] = p;

            const req = {
                path: selectedItemId,
                acl: sd.filter( e => !e.inherited ),
            };
            // console.log('AFTER MOVE UP: ' + JSON.stringify(req));

            netPost('/api/auth/acl/put', req)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    
                    if ( Array.isArray(data) ) {
                        const aclm = {...aclMap};
                        aclm[req.path] = data;
                        setAclMap(aclm);
                        // console.log(`ACL[${req.path}] = ` + JSON.stringify(data));
                        handleRowClick(n,data);
                    }
                }).catch(error => {
                    console.log('Error: ' + error);
                });
        }
    };

    const onAddSave = (ace) => {
        setAddAceOpen(false);
        if ( ace ) {
            const req = {
                path: ace.path,
                acl: [],
            };
            const entries = aclMap[ace.path];
            if ( Array.isArray(entries) ) {
                req.acl = entries.filter(e => !e.inherited );
            }
            req.acl.push(ace);
            req.acl.reverse(); // UI displays in reverse order
            netPost('/api/auth/acl/put', req)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Status: ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    
                    if ( Array.isArray(data) ) {
                        const aclm = {...aclMap};
                        aclm[ace.path] = data;
                        setAclMap(aclm);
                        handleRowClick(-1);
                        // console.log(`ACL[${itemId}] = ` + JSON.stringify(data));
                    }
                }).catch(error => {
                    console.log('Error: ' + error);
                });

        }
    };

    
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '30% 3px 1fr', gridTemplateRows: '100%' }}>
            <Box sx={{position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid',  gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content'}}>
                <Typography align="center" sx={{paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Menu Path</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                />
                <Box 
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto', paddingTop: 2}} 
                    
                >
                    <SimpleTreeView onItemClick={handleItemClick} expansionTrigger="iconContainer">
                        <TreeItem itemId="/C" label="Uniscope Cloud Portal">
                            {Array.isArray(menuList) && menuList.map(item => renderMenuItem(item))}
                        </TreeItem>
                    </SimpleTreeView>
                    
                </Box>
                
            </Box>

            <Divider orientation="vertical"/>

            <Box sx={{overflowY: 'auto', position: 'relative', height: '100%'}}>
                
                <Box sx={{ height: 'calc(100% - 60px)', paddingTop: 1 }} onContextMenu={(event) => handleContextMenu(event,-1)}>
                    <TableContainer component={Paper} sx={{paddingTop: 2}}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold'}}>Role</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Inherited From</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Permission/Restriction</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {aclMap && Array.isArray(aclMap[selectedItemId]) &&
                                    aclMap[selectedItemId].map((entry, index) => (
                                        <TableRow selected={selectedIndex === index}
                                            onClick={() => handleRowClick(index, entry)}
                                            key={`ACL-00-${index}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 },  }}
                                            onContextMenu={(event) => handleContextMenu(event, index, entry)}
                                        >
                                            <TableCell sx={{color: entry.inherited ? 'gray' : 'black'}}>{entry.subject}</TableCell>
                                            <TableCell sx={{color: entry.inherited ? 'gray' : 'black'}}>{getFromLabel(entry)}</TableCell>
                                            <TableCell sx={{color: entry.inherited ? 'gray' : 'black'}}>{getPermissionLabel(entry)}</TableCell>
                                        </TableRow>
                                    ))

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleMenuAdd} >Add ACE</MenuItem>
                        <MenuItem onClick={handleMenuDelete} disabled={!deleteEnabled}>Delete ACE</MenuItem>
                        <MenuItem onClick={handleMenuUp} disabled={!moveUpEnabled}>Move Up</MenuItem>
                        <MenuItem onClick={handleMenuDown} disabled={!moveDownEnabled}>Move Down</MenuItem>
                    </Menu>
                </Box>
                <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Add ACE">
                        <span>
                        <IconButton color="inherit" onClick={handleMenuAdd} >
                            <AddIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Delete ACE">
                        <span>
                        <IconButton color="inherit"  sx={{ marginLeft: '1em' }} disabled={!deleteEnabled} onClick={handleMenuDelete} >
                            <DeleteIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Move Up">
                        <span>
                        <IconButton color="inherit"  sx={{ marginLeft: '1em' }} disabled={!moveUpEnabled} onClick={handleMenuUp} >
                            <MoveUpIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Move Down">
                        <span>
                        <IconButton color="inherit" disabled={!moveDownEnabled} onClick={handleMenuDown} >
                            <MoveDownIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <AddACEDialog open={addAceOpen} onCancel={() => {setAddAceOpen(false)}} path={selectedItemId} onSave={onAddSave} />
            </Box>
        </Box>

    );
}

export { AclConfiguration };