import React, { Component, useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';

import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";

function SupplementApprover (props) {
    const {
        fields, // All fields definitions
        values, // all values
        index, 
        item,       // current field item
        itemIndex,  // current field item index
        onChange,
    } = props;

    const [fleets, setFleets] = useState([]); // { label, name }
    const [approvers, setApprovers] = useState([]); // { name, title, fleets[] }

    useEffect(() => {
        if ( Array.isArray(fields) && Array.isArray(values) ) {
            const ai = fields.findIndex( f => f.name?.toLowerCase().includes('audience'));
            const auds = [];
            if ( ai >= 0 ) {
                const fa = fields[ai];
                const fv = values[ai];
                fa.properties.selectionData.forEach( item => {
                    if ( item.value && fv.fields) {
                        const iv = fv.fields[item.value];
                        if ( iv ) {
                            const ffi = {
                                label: item.label,
                                name: item.value,
                            };
                            auds.push(ffi);
                        }
                    }
                });
                // console.log('AUD selData = ' + JSON.stringify(fa.properties.selectionData));
                // console.log('AUD value = ' + JSON.stringify(fv));
            }
            // console.log('Audiences: ' + JSON.stringify(auds));

            const fi = fields.findIndex( f => f.name?.toLowerCase().includes('fleet'));
            if ( fi >= 0 ) {
                const fd = fields[fi];
                const fv = values[fi];
                // console.log('FLEETS: ' + JSON.stringify(fd));
                // console.log('F VALUE: ' + JSON.stringify(fv));
                /*
                if ( index < values.length ) {
                    console.log('ITEM VALUE = ' + JSON.stringify(values[index].comp));
                }
                */
                if ( Array.isArray(fd.properties?.selectionData)) {
                    const ff = [];
                    const ff2 = [];
                    const allf = fd.properties.selectionData.find( item => item.value && item.value.includes('ALL') && fv.fields && fv.fields[item.value]);
                    fd.properties.selectionData.forEach( item => {
                        if ( item.value && !item.value.includes('ALL') &&  fv.fields) {
                            const iv = fv.fields[item.value];
                            if ( iv || allf) {
                                const ffi = {
                                    label: item.label,
                                    name: item.value,
                                    value: false,
                                };
                                ff.push(ffi);
                                const ffi2 = {
                                    label: 'CC-' + item.label,
                                    name: 'CC_' + item.value,
                                    value: false,
                                }
                                ff2.push(ffi2);
                            }
                        }
                    });
                    // fleets stores the template
                    /*
                    if ( ff.length == 1) {
                        ff[0].value = true;
                    }
                    */
                    const ffa = [];
                    if ( auds.length > 0 ) {
                        auds.forEach( a => {
                            if ( a.name.includes('CABIN') ) {
                                ff2.forEach( f => ffa.push(f));
                            } else {
                                ff.forEach( f => ffa.push(f) );
                            }
                        });
                    } else {
                        ff.forEach( f => ffa.push(f) );
                        setFleets(ffa);
                    }
                    if ( ffa.length == 1) {
                        ffa[0].value = true;
                    }
                    setFleets(ffa);
                    // console.log('FLEETS: ' + JSON.stringify(ffa));
                    let apprs = [];
                    if ( index < values.length && values[index].comp) {
                        apprs = values[index].comp[item.value];
                        if ( !Array.isArray(apprs) ) {
                            apprs = [...approvers];
                        }
                    }
                    let ap = [];
                    if ( apprs.length == 0) {
                        // initialize
                        ap = [{
                            name: '',
                            title: '',
                            fleets: [...ffa],
                        }];
                        setApprovers(ap);
                        // onFleetValueChanged(ap, 0, ap[0].fleets, 0, true);
                    } else {
                        ap = [...apprs];
                        // console.log('OLD APPROVERS: ' + JSON.stringify(approvers));
                        ap.forEach( item => {
                            const ffi = [];
                            ffa.forEach( f => {
                                const ifv = item.fleets.find( i => i.name === f.name);
                                // console.log('FLEET: ' + JSON.stringify(ifv));
                                ffi.push({
                                    label: f.label,
                                    name: f.name,
                                    value: ifv ? ifv.value: false,
                                });
                            })
                            item.fleets = ffi;
                        });
                        setApprovers(ap);
                        
                    }
                    filterApprovers(ap, ffa);
                    
                    // console.log('APPROVERS: ' + JSON.stringify(ap));
                }
            }
        }

    },[fields, values]);

    const save = (apprs) => {
        if ( typeof onChange === 'function' ) {
            onChange(apprs);
        }
    };

    const onNameChanged = (apprs, aindex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        a.name = value;
        setApprovers(ap);
        save(ap);
    };

    const onTitleChanged = (apprs, aindex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        a.title = value;
        setApprovers(ap);
        save(ap);
    };

    const onFleetValueChanged = (apprs, aindex, fleet, findex, value) => {
        const ap = [...apprs];
        const a = ap[aindex]
        const f = a.fleets[findex];
        // console.log('SEL FLEET: ' + JSON.stringify(f));
        if ( a.fleets.length > 1 ) {
            f.value = value;
            const gff = [...fleets];
            const gf = gff.find(f => f.name === fleet.name);
            if (gf) {
                gf.value = value;
            }
            setFleets(gff);
            // console.log('NEW FLEETS: ' + JSON.stringify(gff));
            // console.log('NEW APPRS: ' + JSON.stringify(ap));
            const rap = filterApprovers(ap, gff);
            save(rap);
        }
    };

    const filterApprovers = (apprs, fleets) => {
        // filter the approvers, the first approver sees all fleets,
        // each subsequent approver sees the leftover from previous
        // if there are not leftovers, remove the approver
        const aprs = [];
        let lff = [...fleets]; // initialize with all fleets
        apprs.forEach ( a => {
            const aff = [];
            lff.forEach(f => {
                aff.push({
                    name: f.name,
                    label: f.label,
                    value: false,
                })
            });
            // copy current value
            if (aff.length > 0) {
                aff.forEach(f => {
                    const af = a.fleets.find(cf => cf.name === f.name);
                    if (af) {
                        f.value = af.value;
                    } else {
                        f.value = false;
                    }
                });

                a.fleets = aff;
                if ( a.fleets.length == 1) {
                    a.fleets[0].value = true;
                    const af = fleets.find( f => f.name === a.fleets[0].name);
                    if ( af ) {
                        af.value = true;
                    }
                }
                aprs.push(a);
            }
            const nlff = aff.filter( f => f.value === false);
            lff = nlff;
        });
        lff = [...fleets];
        lff.forEach(f => f.value = false);
        apprs.forEach( a => {
            a.fleets.forEach(f => {
                if ( f.value ) {
                    const lf = lff.find( l => l.name === f.name);
                    if ( lf ) {
                        lf.value = f.value;
                    }
                }
            });
        });
        setFleets(lff);
        setApprovers(aprs);
        return aprs;
    };

    const canAddApprover = () => {
        const fi = fleets.findIndex( f => f.value === false);
        return fi >= 0;
    };

    const addApprover = () => {
        const ff = fleets.filter( f => f.value === false);
        const naff = [];
        ff.forEach( f => {
            naff.push({
                name: f.name,
                label: f.label,
                value: false,
            });
        })
        const na = {
            name: '',
            title: '',
            fleets: naff,
        };
        if ( na.fleets.length == 1) {
            na.fleets[0].value = true;
            const aff = [...fleets];
            const af = aff.find(f => f.name === na.fleets[0].name);
            if ( af ) {
                af.value = true;
            }
            setFleets(aff);
        }
        const apr = [...approvers];
        apr.push(na);
        setApprovers(apr);
    };

    // testing
    return (
        <Box sx={{paddingBottom: 2}}>
            <Typography>{item?.label}</Typography>
            { Array.isArray(approvers) && approvers.map( (apr, aindex) => 
            <Box sx={{ paddingLeft: 2, paddingTop: 1, display: 'flex', alignItems: 'center' }}>
                <TextField label="Name" size="small" value={apr.name} onChange={event => onNameChanged(approvers, aindex, event.target.value)} />
                <Typography sx={{ paddingLeft: 1, paddingRight: 1 } } >/</Typography>
                <TextField label="Title" size="small" value={apr.title} onChange={event => onTitleChanged(approvers, aindex, event.target.value)} />
                <Box sx={{ paddingLeft: 2, display: 'flex', flexWrap: 'wrap' }} >
                    {(aindex > 0 || apr.fleets.length > 1) && apr.fleets.map((fleet, findex) =>
                        <FormControlLabel control={<Checkbox checked={fleet.value}
                            onChange={(event) => onFleetValueChanged(approvers, aindex, fleet, findex, event.target.checked) } />} label={fleet.label} />
                    )}
                </Box>
            </Box> )
            }
            { canAddApprover() && 
            <Box sx={{paddingLeft: 1}} >
                <IconButton onClick={addApprover}>
                    <AddBoxIcon />
                </IconButton>
            </Box>
            }
        </Box>
    );
}

/**
 * Update form fields based on the selected supplement given by supp parameter.
 * It returns the new values to be set on the form.
 * 
 * @param {*} fields 
 * @param {*} values 
 * @param {*} field 
 * @param {*} index 
 * @param {*} supp 
 */
const updateSupplementForm = (fields, values, field, index, supp, docid) => {
    return new Promise((resolve,reject) => {
        
        netGet(`/api/doc/supp/info/${docid}/${supp?.id}`)
            .then(response => response.json())
            .then(info => {
                console.log('EX SUP INFO: ' + JSON.stringify(info));
                const fv = [...values];
                let fleetIndex = -1;
                let approversIndex = -1;
                let audienceIndex = -1;
                fields.forEach((f, i) => {
                    if (f.name) {
                        const fname = f.name.toLowerCase();
                        //console.log('SCAN field: ' + fname);
                        if (fname.includes('title')) {
                            fv[i] = supp.title;
                        } else if ( fname.includes('audience') ) {
                            const aud = info.properties?.audience;
                            if ( aud ) {
                                audienceIndex = i;
                                f.properties.selectionData.forEach( item => {
                                    if ( item.value.includes('CABIN') ) {
                                        const cv = aud['CABIN'];
                                        fv[i].fields[item.value] = cv;
                                    } else {
                                        const cv = aud['FLIGHT'];
                                        fv[i].fields[item.value] = cv;
                                    }
                                });
                            }
                        } else if ( fname.includes('fleet') ) {
                            const fleets = info.properties?.fleets;
                            fleetIndex = i;
                            if ( fleets ) {
                                f.properties.selectionData.forEach( item => {
                                    if ( item.value.includes('777') ) {
                                        const cv = fleets['777'];
                                        fv[i].fields[item.value] = cv;
                                    } else if ( item.value.includes('787') ) {
                                        const cv = fleets['787'];
                                        fv[i].fields[item.value] = cv;
                                    } else if ( item.value.includes('300') ) {
                                        const cv = fleets['Q300'];
                                        fv[i].fields[item.value] = cv;
                                    } else if ( item.value.includes('ATR') ) {
                                        const cv = fleets['ATR'];
                                        fv[i].fields[item.value] = cv;
                                    } else if ( item.value.includes('320') ) {
                                        const cv = fleets['A320'];
                                        fv[i].fields[item.value] = cv;
                                    }
                                });
                            } 
                        } else if (fname.includes('applicable')) {
                            const ap = info.properties?.applicableTo;
                            if (ap) {
                                // console.log('APPLICABLE field: ' + JSON.stringify(info.properties?.selectionData));
                                f.properties.selectionData.forEach(item => {
                                    if (item.other) {
                                        const ot = ap['OTHER'];
                                        if (typeof ot === 'string' && ot.length > 0) {
                                            fv[i].fields[item.value] = true;
                                            fv[i].other[item.value] = ot;
                                        } else {
                                            fv[i].fields[item.value] = false;
                                            fv[i].other[item.value] = '';
                                        }
                                    } else if (item.value.includes('CSM')) {
                                        fv[i].fields[item.value] = ap['CSM'];
                                    } else if (item.value.includes('EFB') && item.value.includes('IPAD')) {
                                        fv[i].fields[item.value] = ap['IPAD_EFB'];
                                    } else if (item.value.includes('FCOM')) {
                                        fv[i].fields[item.value] = ap['FCOM'];
                                    } else if (item.value.includes('FCTM')) {
                                        fv[i].fields[item.value] = ap['FCTM'];
                                    } else if (item.value.includes('FLYSMART')) {
                                        fv[i].fields[item.value] = ap['FLYSMART'];
                                    } else if (item.value.includes('QRH')) {
                                        fv[i].fields[item.value] = ap['QRH'];
                                    } else if (item.value.includes('MEL')) {
                                        fv[i].fields[item.value] = ap['MEL'];
                                    } else if (item.value.includes('ROUTE')) {
                                        fv[i].fields[item.value] = ap['ROUTE_GUIDE'];
                                    } else if (item.value.includes('SOP')) {
                                        fv[i].fields[item.value] = ap['SOP'];
                                    } else if (item.value.includes('EFB') && item.value.includes('PORTABLE')) {
                                        fv[i].fields[item.value] = ap['PORTABLE_EFB'];
                                    }
                                });
                            }

                        } else if ( fname.includes('restrict') || fname.includes('distribu')) {
                            const rv = info.properties?.restricted;
                            if ( typeof rv === 'boolean' ) {
                                fv[i] = rv;
                            }
                        } else if ( fname.includes('supersed') || fname.includes('cancel')) {
                            const cv = info.properties?.cancelling;
                            if ( typeof cv === 'string' && cv.length > 0 ) {
                                fv[i] = cv;
                            }
                        } else if (fname.includes('number')) {
                            const num = info.properties?.number;
                            console.log('NUMBER field value: ' + JSON.stringify(fv[i]));
                            
                            if (typeof num === 'string' && num.length > 0 ) {
                                f.properties.selectionData.forEach(item => {
                                    if (item.other) {
                                        fv[i].value = item.value;
                                        fv[i].other[item.value] = num;
                                    }
                                });
                            }
                        } else if ( fname.includes('approve') ) {
                            approversIndex = i;
                        } else if ( fname.includes('date') ) {
                            const ed = info.properties?.effectiveDate;
                            if ( typeof ed === 'string' && ed.length > 0 ) {
                                try {
                                    const edd = dayjs(ed);
                                    fv[i] = edd;
                                } catch(error) {
                                    // ignore
                                }
                                
                            }
                        }

                    } 
                });
                if ( fleetIndex >= 0 && approversIndex >= 0 && audienceIndex >= 0) {
                    const signs = info.properties?.signatures;
                    const ff = fields[fleetIndex];
                    const lv = fv[fleetIndex];
                    const df = fields[audienceIndex];
                    const dv = fv[audienceIndex];
                    const af = fields[approversIndex];
                    const av = fv[approversIndex];
                    if (signs) {
                        const ff1 = [];
                        const ff2 = [];
                        const allf = ff.properties.selectionData.find(item => item.value && item.value.includes('ALL') && lv.fields && lv.fields[item.value]);
                        ff.properties.selectionData.forEach(item => {
                            if (item.value && !item.value.includes('ALL') && lv.fields) {
                                const iv = lv.fields[item.value];
                                if (iv || allf) {
                                    const ffi = {
                                        label: item.label,
                                        name: item.value,
                                        value: false,
                                    };
                                    ff1.push(ffi);
                                    const ffi2 = {
                                        label: 'CC-' + item.label,
                                        name: 'CC_' + item.value,
                                        value: false,
                                    }
                                    ff2.push(ffi2);
                                }
                            }
                        });
                        const auds = [];
                        df.properties.selectionData.forEach(item => {
                            if (item.value && dv.fields) {
                                const iv = dv.fields[item.value];
                                if (iv) {
                                    const ffi = {
                                        label: item.label,
                                        name: item.value,
                                    };
                                    auds.push(ffi);
                                }
                            }
                        });
                        
                        const ffa = [];
                        if (auds.length > 0) {
                            auds.forEach(a => {
                                if (a.name.includes('CABIN')) {
                                    ff2.forEach(f => ffa.push(f));
                                } else {
                                    ff1.forEach(f => ffa.push(f));
                                }
                            });
                        } else {
                            ff1.forEach(f => ffa.push(f));
                        }
                        const comp = {};
                        af.properties.selectionData.forEach( item => {
                            if ( item.value.includes('SME') ) {
                                const sign = signs['SME'];
                                buildSign(sign, ffa, comp, av, item);
                            } else if ( item.value.includes('CHECK') ) {
                                const sign = signs['checked'];
                                buildSign(sign, ffa, comp, av, item);
                            } else if ( item.value.includes('OPERATIO') || item.value.includes('REVIEW')) {
                                const sign = signs['operationalReview'];
                                buildSign(sign, ffa, comp, av, item);
                            } else if ( item.value.includes('APPROVE')) {
                                const sign = signs['approved'];
                                buildSign(sign, ffa, comp, av, item);
                            }

                        });
                        av.comp = comp;
                    }
                }
                resolve(fv);
            }).catch(error => {
                console.log('Error fetching supplement info: ' + error);
                reject(error);
            });
        
    });
};

const buildSign = (sign, ffa, comp, av, item) => {
    if ( sign ) {
        const ccl = [];
        sign.forEach( s => {
            const cc = {
                name: s.name,
                title: s.title,
                fleets: [],
            }
            if ( Array.isArray(s.fleets)) {
                s.fleets.forEach( f => {
                    const fa = ffa.find( fi => fi.label === f);
                    if ( fa ) {
                        cc.fleets.push({
                            label: fa.label,
                            name: fa.name,
                            value: true,
                        });
                    }
                });
            }
            ccl.push(cc);
        });
        comp[item.value] = ccl;
        av.fields[item.value] = true;
    }
};

export {SupplementApprover, updateSupplementForm};