import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RuleIcon from '@mui/icons-material/Rule';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import OutputIcon from '@mui/icons-material/Output';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UndoIcon from '@mui/icons-material/Undo';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ClearIcon from '@mui/icons-material/Clear';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { InsertBeforeIcon, InsertAfterIcon } from "../icons";
import { WorkflowCheckinDialog, WorkflowRevokeCheckoutDialog, WorkflowDeleteDialog, StepDeleteDialog, CreateStepWorkflowDialog, CreateTemplateWorkflowDialog } from "./management-workflow-dialogs";
import { EditIndicator } from "./management-workflow-common";


function WorkflowDefinitionStepEdit(props) {
    const {
        def,
        onStepSelected,
        onStepAction,
        refresh,
        update,
        stepDefList,
        ...other
     } = props;
    // const [ workflowUpdate, setWorkflowUpdate] = React.useState(0); // increment to update
    const [editIndex, setEditIndex] = React.useState(-1);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [insertBeforeEnabled, setInsertBeforeEnabled] = React.useState(false);
    const [insertAfterEnabled, setInsertAfterEnabled] = React.useState(false);
    const [deleteStepEnabled, setDeleteStepEnabled] = React.useState(false);
    const [stepLabelMap, setStepLabelMap] = React.useState({});
    const theme = useTheme();

    /*
    React.useEffect(() => {
        if (instance) {
            console.log('Fetching workflow definition ' + instance.definitionId + '...');

            netGet('/api/workflow/def/' + instance.definitionId)
                .then(response => response.json())
                .then(data => {
                    // console.log(Array.isArray(data.steps) + ' : ' + data.steps.length);
                    setWorkflowDef(data);
                    /
                });
        }
        
    }, [workflowUpdate, instance, update]); 
      */
    
    React.useEffect(() => {
        setEditIndex(-1);
        if ( typeof onStepSelected === 'function') {
            onStepSelected(-1);
        }
    }, [def] );

    React.useEffect(() => {
        if ( Array.isArray(stepDefList) ) {
            let lm = {};
            stepDefList.forEach(item => {
                lm[item.name] = item.label;
            });
            setStepLabelMap(lm);
        }
    }, [stepDefList] );
    

    const handleEditClick = (index) => {
        if ( isLockedByMe() ) {
            setEditIndex(index);
            if ( typeof onStepSelected === 'function') {
                onStepSelected(index);
            }
        }
    };

    const saveToServer = (def) => {
        netPost('/api/workflow/def/update', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save workflow definition: status = ' + resp.status);
                } else {
                    if ( refresh ) {
                        refresh(def);
                    }
                }
            });
    };

    const isLockedByMe = () => {
        return def && typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const handleContextMenu = (event,index) => {
        //event.preventDefault();
        // const slen = def.steps.length;
        if (isLockedByMe()) {
            /* let the parent handle the context menu
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
            if ( index < 0 ) {
                setInsertBeforeEnabled(false);
                setInsertAfterEnabled(false);
                setDeleteStepEnabled(false);
           } else {
                setInsertBeforeEnabled(index > 0);
                setInsertAfterEnabled(index < slen);
                setDeleteStepEnabled(index > 0);
            }
            */
            if (index >= 0) {
                handleEditClick(index);
            }
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleInsertBeforeAction = () => {
        handleContextMenuClose();
        if ( typeof onStepAction === 'function' ) {
            onStepAction(editIndex, 'insertBefore');
        }
    };

    const handleInsertAfterAction = () => {
        handleContextMenuClose();
        if ( typeof onStepAction === 'function' ) {
            onStepAction(editIndex, 'insertAfter');
        }
    };

    const deleteStepHandler = () => {
        handleContextMenuClose();
        if ( typeof onStepAction === 'function' ) {
            onStepAction(editIndex, 'delete');
        }
    };

    return (
        def && Array.isArray(def.steps) && def.steps.length > 0 ? (
            
            <Box sx={{ width: '100%', height: '100%', position: 'relative', overflowY: 'auto', padding: '1ex', paddingTop: '2ex', minHeight: '3em' }}>
                <EditIndicator readOnly={!isLockedByMe()}/>
                <Typography sx={{fontSize: '18px', fontWeight: theme.typography.fontWeightBold, paddingTop: '8px' }}>{def.label}</Typography> 
                <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex' }}>{def.category.endsWith('SUB') ? 'SUBWORKFLOW' : 'WORKFLOW'}</Typography>
                <Typography sx={{paddingTop: '1ex', paddingBottom: '2ex', whiteSpace: 'pre-wrap'}}>{def.description}</Typography>
                <List >
                    {
                        def.steps.map( (step,index) => (
                            <ListItem key={'mwtw' + index} alignItems="flex-start"  onContextMenu={(event) => handleContextMenu(event, index)}>
                                <ListItemButton onClick={() => handleEditClick(index)} alignItems="flex-start" 
                                        selected={editIndex === index && isLockedByMe()}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ width: 24, height: 24 }}>
                                            <Typography>{index + 1}</Typography>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText disableTypography={true} 
                                        primary={
                                            <Typography sx={{ fontWeight: 'bold' }} >{step.label}</Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex', paddingBottom: '1ex'}}>{stepLabelMap[step.name]}</Typography>
                                                <Typography sx={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>{step.description}</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={handleInsertBeforeAction} disabled={!insertBeforeEnabled}>Insert Before</MenuItem>
                    <MenuItem onClick={handleInsertAfterAction} disabled={!insertAfterEnabled}>Insert After</MenuItem>
                    <MenuItem onClick={deleteStepHandler} disabled={!deleteStepEnabled}>Delete</MenuItem>
                </Menu>
            </Box>
        ) : null
      );

}


function EditTemplate(props) {

    const [workflowList, setWorkflowList] = React.useState([]);
    const [updateWorkflowList, setUpdateWorkflowList] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const [checkinEnabled, setCheckinEnabled] = React.useState(false);
    const [addWorkflowEnabled, setAddWorkflowEnabled] = React.useState(true);
    const [deleteWorkflowEnabled, setDeleteWorkflowEnabled] = React.useState(false);
    const [saveWorkflowEnabled, setSaveWorkflowEnabled] = React.useState(false);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [stepContextMenu, setStepContextMenu] = React.useState(null);
    const [updateDef, setUpdateDef] = React.useState(0);
    const [updateDefId, setUpdateDefId] = React.useState(-1);
    const [stepTabValue, setStepTabValue] = React.useState(0);
    const [fullStepList, setFullStepList] = React.useState([]);
    const [stepList, setStepList] = React.useState([]);
    const [editStepList, setEditStepList] = React.useState([]);
    const [stepSelectedIndex, setStepSelectedIndex] = React.useState(-1);
    const [templateSelectedIndex, setTemplateSelectedIndex] = React.useState(-1);
    const [stepEditSelectedIndex, setStepEditSelectedIndex] = React.useState(-1);
    const [insertBeforeEnabled, setInsertBeforeEnabled] = React.useState(false);
    const [insertAfterEnabled, setInsertAfterEnabled] = React.useState(false);
    const [deleteStepEnabled, setDeleteStepEnabled] = React.useState(false);
    const [editStepEnabled, setEditStepEnabled] = React.useState(false);
    const [attributesEditEnabled, setAttributesEditEnabled] = React.useState(false);

    const [stepDialogOpen, setStepDialogOpen] = React.useState(false);
    const [stepDialogTemplate, setStepDialogTemplate] = React.useState('');
    const [stepDialogName, setStepDialogName] = React.useState('');
    const [stepDialogDescription, setStepDialogDescription] = React.useState('');
    
    const [stepDialogTitle, setStepDialogTitle] = React.useState('');

    const [stepEditDialogOpen, setStepEditDialogOpen] = React.useState(false);
    const [stepEditDialogTemplate, setStepEditDialogTemplate] = React.useState('');
    const [stepEditDialogName, setStepEditDialogName] = React.useState('');
    const [stepEditDialogDescription, setStepEditDialogDescription] = React.useState('');
    const [stepEditDialogTarget, setStepEditDialogTarget] = React.useState('');
    const [stepEditDialogTitle, setStepEditDialogTitle] = React.useState('');


    const [workflowDialogOpen, setWorkflowDialogOpen] = React.useState(false);
    const [workflowDialogTemplate, setWorkflowDialogTemplate] = React.useState([]);
    const [workflowDialogName, setWorkflowDialogName] = React.useState('');
    const [workflowDialogDescription, setWorkflowDialogDescription] = React.useState('');
    const [workflowDialogTitle, setWorkflowDialogTitle] = React.useState('Create Workflow');

    const [attributesEditDialogOpen, setAttributesEditDialogOpen] = React.useState(false);
    const [attributesEditDialogName, setAttributesEditDialogName] = React.useState('');
    const [attributesEditDialogDescription, setAttributesEditDialogDescription] = React.useState('');
    const [attributesEditDialogTitle, setAttributesEditDialogTitle] = React.useState('Edit Workflow Properties');


    const [checkinDialogOpen, setCheckinDialogOpen] = React.useState(false);
    const [checkinDialogTitle, setCheckinDialogTitle] = React.useState('Checkin Workflow');
    const [checkinDialogWorkflowName, setCheckinDialogWorkflowName] = React.useState('');

    const [stepDeleteOpen, setStepDeleteOpen] = React.useState(false);
    const [stepDeleteName, setStepDeleteName] = React.useState('');
    const [stepDeleteWorkflowName, setStepDeleteWorkflowName] = React.useState('');

    const [revokeDialogOpen, setRevokeDialogOpen] = React.useState(false);
    const [revokeDialogTitle, setRevokeDialogTitle] = React.useState('Revoke Checkout');

    const [workflowDeleteOpen, setWorkflowDeleteOpen] = React.useState(false);
    const [workflowDeleteName, setWorkflowDeleteName] = React.useState(false);

    const [modified, setModified] = React.useState([]); // indexes of modified workflows
    const [undoSaveEnabled, setUndoSaveEnabled] = React.useState(false);

    const theme = useTheme();

    // <StepDeleteDialog title="Delete Step" open={stepDeleteOpen} stepName={deleteStepName} workflowName={deleteStepWorkflowName} 
    // onSave={deleteStepSave} onCancel={deleteStepCancel}/>

    // <StepDialog open={stepDialogOpen} onSave={stepDialogSaveHandler} onCancel={stepDialogCancelHandler} 
    // template={stepTemplateName} name={stepDialogName} description={stepDialogDescription} 
    // title={stepDialogTitle} />

    React.useEffect(() => {
        loadInstances();
    }, [updateWorkflowList]);

    React.useEffect(() => {
        if (updateDefId >= 0) {
            netGet('/api/workflow/def/' + updateDefId)
                .then(response => response.json())
                .then(def => {
                    if (def && def.id) {
                        // console.log('Updating Workflow Definition: ' + def.id);
                        let slist = workflowList.map((r) => {
                            if (r.id === def.id) {
                                return def;
                            }
                            return r;
                        });
                        setWorkflowList(slist);
                        loadWorkflowDialogTemplates(slist);
                        const cdef = slist.find( (item) => item.id === def.id );
                        if ( cdef ) {
                            const i = slist.indexOf(cdef);
                            // console.log('Found def to update ' + cdef + ' at index ' + i);
                            updateSelectedItem(cdef, i);
                        }
                    }
                }).catch(error => {
                    console.log('Error fetching definition: ' + error);
                });
        }
    }, [updateDef,updateDefId]);

    React.useEffect(() => {
        netGet('/api/workflow/step/def/list?mask=0')
            .then(response => response.json())
            .then(steps => {
                if ( Array.isArray(steps) ) {
                    setFullStepList(steps);
                    setStepList(steps);
                    // console.log('STEPS: ' + JSON.stringify(steps));
                }
            }).catch( error => {
                console.log('Error fetching model list: ' + error);
            });

    }, []);

    const workflowMessage = (message) => {
        let wevent = message.data;
        console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowDefinition') {
            if ( wevent.action === 'DELETE' ) {
                let dindex = -1;
                const def = workflowList.find( (item, index) => {
                    if ( item.id === wevent.id ) {
                        dindex = index;
                        return true;
                    }
                });
                if ( def && dindex >= 0 ) {
                    let wlist = [ ...workflowList];
                    wlist.splice(dindex, 1);
                    setWorkflowList(wlist);
                    loadWorkflowDialogTemplates(wlist);
                }
            } else if ( wevent.action === 'NEW' ) {
                loadInstances(wevent.id);
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else if ( wevent.action === 'CHECKOUT' || wevent.action === 'REVOKE_CHECKOUT') {
                
                let cdef = workflowList.find( (item) => item.id === wevent.previousId );
                if ( cdef ) {
                    cdef.id = wevent.id;
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                    
                }
            } else {
                const def = workflowList.find( (item) => item.id === wevent.id);
                if (def) {
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                    
                }
            }
        }
    };

    MessageHooks["workflow"]["WorkflowTemplateEdit"] = workflowMessage;

    const serverCheckout = (index) => {
        const def = workflowList[index];
        netPost('/api/workflow/def/checkout',def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkout workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverCheckin = (index, msg) => {
        const def = workflowList[index];
        const message = msg ? msg : '';
        const payload = { definition: def, message: message};
        netPost('/api/workflow/def/checkin', payload)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkin workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverRevokeCheckout = (index) => {
        const def = workflowList[index];
        netPost('/api/workflow/def/revoke_checkout', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not revoke checkout workflow definition: status = ' + resp.status);
                }
            });
    };

    const loadInstances = (selectId) => {
        netGet('/api/workflow/def/list?categ=MODELS')
            .then(response => response.json())
            .then(data => {
                if ( data && Array.isArray(data) ) {
                    console.log('Workflow Definitions: ' + data.length);
                    const wl = data.sort( (a,b) => a.label.localeCompare(b.label));
                    setWorkflowList(wl);
                    loadWorkflowDialogTemplates(wl);
                    if ( selectId ) {
                        let sindex = -1;
                        let sdef  = wl.find( (item,i) => {
                            if ( item.id === selectId ) {
                                sindex = i;
                                return true;
                            }
                            return false;
                        });
                        if ( sindex >= 0 ) {
                            setSelectedIndex(sindex);
                        }
                    }
                }
            });
    };

    const loadWorkflowDialogTemplates = (templateList) => {
        let tnames = templateList.map( (def) => def.label);
        tnames.splice(0,0,'None');
        setWorkflowDialogTemplate(tnames);
    };

    const handleContextMenu = (event,index) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleItemClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleStepContextMenu = (event,index) => {
        event.preventDefault();
        setStepContextMenu(
          stepContextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
        if ( index >= 0 ) {
            handleStepItemClick(index);
        }
    };

    const handleStepContextMenuClose = () => {
        setStepContextMenu(null);
    };
    

    const isLockedByOther = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0 && def.user !== UserInfo.info.name;
    };

    const isLockedByMe = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0;
    };

    const handleItemClick = (index) => {
        setSelectedIndex(index);
        if ( isLocked(index) ) {
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(isLockedByMe(index) && !saveRequired(index));
            setSaveWorkflowEnabled(saveRequired(index));
            setUndoSaveEnabled(saveRequired(index));
            setAttributesEditEnabled(isLockedByMe(index));
        } else {
            setCheckoutEnabled(true);
            setDeleteWorkflowEnabled(true);
            setCheckinEnabled(false);
            setSaveWorkflowEnabled(false);
            setUndoSaveEnabled(false);
            setAttributesEditEnabled(false);
        }
    };

    const updateSelectedItem = (def, index) => {
        const locked = typeof def.user === 'string' && def.user.length > 0;
        const lockedByMe = typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
        setSelectedIndex(index);
        if ( locked ) {
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(lockedByMe /* && !saveRequired(index) */);
            setSaveWorkflowEnabled(saveRequired(index));
            setUndoSaveEnabled(saveRequired(index));
            setAttributesEditEnabled(lockedByMe);
        } else {
            setCheckoutEnabled(true);
            setDeleteWorkflowEnabled(true);
            setCheckinEnabled(false);
            setSaveWorkflowEnabled(false);
            setUndoSaveEnabled(false);
            setAttributesEditEnabled(false);
        }
    };

    const handleStepItemClick = (index) => {
        setStepSelectedIndex(index);
        if ( index < 0 ) {
            setInsertBeforeEnabled(false);
            setInsertAfterEnabled(false);
            setDeleteStepEnabled(false);
            setEditStepEnabled(false);
        } else {
            setInsertBeforeEnabled(true);
            setInsertAfterEnabled(true);
            setDeleteStepEnabled(true);
            setEditStepEnabled(true);
        }
    };

    const lockIconColor = (index) => {
        let def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (def.user && def.user !== '') {
            if (def.user === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };

    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    }

    const lockIconLabel = (index) => {
        const def = workflowList[index];
        if ( def.user && def.user !== '' ) {
            return 'Locked by ' + def.user;
        }
        return '';
    };

    const handleAttributesEdit = () => {
        const def = workflowList[selectedIndex];
        setAttributesEditDialogName(def.label);
        setAttributesEditDialogDescription(def.description);
        setAttributesEditDialogOpen(true);
        handleContextMenuClose();
    };

    const handleCheckoutAction = () => {
        if (selectedIndex >= 0) {
            serverCheckout(selectedIndex);
            handleItemClick(selectedIndex);
        }
    };

    const handleMenuCheckout = () => {
        handleContextMenuClose();
        handleCheckoutAction();
    };

    const handleMenuRevokeCheckout = () => {
        handleContextMenuClose();
        handleRevokeCheckoutAction();
    };

    const handleRevokeCheckoutAction = () => {
        setRevokeDialogOpen(true);

    };

    const handleRevokeCheckoutSave = (index) => {
        setRevokeDialogOpen(false);
        if (selectedIndex >= 0) {
            serverRevokeCheckout(selectedIndex);
            handleItemClick(selectedIndex);
        }
    };

    const handleRevokeCheckoutCancel = () => {
        handleContextMenuClose();
        setRevokeDialogOpen(false);
    };

    const handleCheckinAction = () => {
       setCheckinDialogOpen(true);
    };

    const handleCheckinSave = (message) => {
        setCheckinDialogOpen(false);
        if (selectedIndex >= 0) {
            serverCheckin(selectedIndex, message);
            handleItemClick(selectedIndex);
        }
    };

    const handleCheckinCancel = () => {
        setCheckinDialogOpen(false);
    };

    const handleMenuCheckin = () => {
        handleContextMenuClose();
        handleCheckinAction();
    };

    const handleMenuDeleteWorkflow = () => {
        handleContextMenuClose();
        handleDeleteWorkflowAction();
    };

    const handleStepTabChange = (event, newValue) => {
        setStepTabValue(newValue);
        computeStepTabList(newValue, stepSelectedIndex);
    };

    const computeStepTabList = (tab, stepIndex) => {
        setTemplateSelectedIndex(-1);
        switch ( tab ) {
            case 1:
                // insert before
                if ( selectedIndex >= 0 && stepIndex >= 0 ) {
                    const steps = workflowList[selectedIndex].steps;
                    computeInsertBefore(steps, stepIndex);
                }
                break;
            case 2:
                // insert after
                if ( selectedIndex >= 0 && stepIndex >= 0 ) {
                    const steps = workflowList[selectedIndex].steps;
                    computeInsertAfter(steps, stepIndex);
                }
                break;
            default:
                setStepList(fullStepList);
                break;
        }
    };

    const computeEditStepList = (stepIndex) => {
        let editList = [];
        let editIndex = -1;
        let defStep;
        if ( selectedIndex >= 0 && stepIndex >= 0 ) {
            const steps = workflowList[selectedIndex].steps;
            const cstep = steps[stepIndex];
            if (cstep) {
                setStepEditDialogName(cstep.label);
                setStepEditDialogDescription(cstep.description);
                setStepEditDialogTarget(cstep.properties && cstep.properties.target ? cstep.properties.target : '');
                console.log('step def name = ' + cstep.name);
                defStep = getStepDefinition(cstep.name);
                if (defStep) {
                    const si = fullStepList.indexOf(defStep);
                    // editList.push(si); // the current step is on the list,  of course
                    // find what steps are valid after previous step
                    if (si > 0) {
                        const index = si - 1;
                        const nextStep = index + 1 >= steps.length - 1 ? undefined : getStepDefinition(steps[index + 2].name);
                        fullStepList.forEach((item) => {
                            if (checkAfter(defStep, item)) {
                                if (nextStep) {
                                    if (checkBefore(item, nextStep)) {
                                        editList.push(item);
                                    }
                                } else {
                                    editList.push(item);
                                }
                            }
                        });
                    }
                    if (editList.length <= 0) {
                        editList.push(defStep); // at least the current step
                    }
                } else {
                    console.log('No definition for step: ' + cstep.name);
                }
                editIndex = editList.indexOf(defStep);

            }
        }
        setEditStepList(editList);
        setStepEditSelectedIndex(editIndex);
        setStepEditDialogTemplate(defStep ? defStep.name : undefined);
    };

    // get the step definition from the master table as the one 
    // in the workflows don't contain all the rules 
    const getStepDefinition = (name) => {
        return fullStepList.find( (item) => item.name === name);
    };

    // check if step can preceed next 
    // steps should be master step definitions with schema rules
    const checkAfter = (anchor, next) => {
        return ( anchor.properties.allowAfter.includes('ANY') || anchor.properties.allowAfter.includes(next.name) ) &&
            ( next.properties.allowBefore.includes('ANY') || next.properties.allowBefore.includes(anchor.name) );
    };

    const checkBefore = (previous, anchor) => {
        return ( anchor.properties.allowBefore.includes('ANY') || anchor.properties.allowBefore.includes(previous.name) ) &&
            ( previous.properties.allowAfter.includes('ANY') || previous.properties.allowAfter.includes(anchor.name) );
    };

    const computeInsertBefore = (steps, index) => {
        if ( index <= 0 ) {
            setStepList([]); // cannot insert before first
        } else {
            const step = getStepDefinition(steps[index].name);
            const previousStep = getStepDefinition(steps[index-1].name);
            // console.log('checking step ' + step.name + ' : ' +  step.label + ' : ' + index + ' : ' + step.properties);
            let slist = fullStepList.filter( (item) => {
                if ( checkAfter(item, step) && checkBefore(previousStep, item) ) {
                    return true;
                }
                return false;
            });
            setStepList(slist);
        }

    };

    const computeInsertAfter = (steps, index) => {
        const step = getStepDefinition(steps[index].name);
        const nextStep = index >= steps.length - 1 ? undefined : getStepDefinition(steps[index + 1].name);
        let slist = fullStepList.filter( (item) => {
            if ( checkAfter(step, item) ) {
                if ( nextStep ) {
                    if ( checkBefore(item, nextStep) ) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            return false;
        });
        setStepList(slist);
    };

    const handleStepSelected = (index) => {
        setStepSelectedIndex(index);
        const steps = selectedIndex >= 0 && workflowList[selectedIndex] ? workflowList[selectedIndex].steps : [];
        const slen = steps.length;
        console.log('index=' + index + ',len=' + slen);
        if ( index < 0 || slen === 0) {
            setInsertBeforeEnabled(false);
            setInsertAfterEnabled(false);
            setDeleteStepEnabled(false);
            setEditStepEnabled(false);
            setStepTabValue(0);
            setStepList(fullStepList);
            setEditStepList([]);
        } else {
            setInsertBeforeEnabled(index > 0);
            setInsertAfterEnabled(index < slen && steps[index] && steps[index].name !== 'END');
            setDeleteStepEnabled(index > 0);
            setEditStepEnabled(index > 0);
        }
        computeStepTabList(stepTabValue, index);
        computeEditStepList(index);
    };

    const handleInsertBeforeAction = () => {
        handleStepTabChange(null, 1);
        handleStepContextMenuClose();
    };

    const handleInsertAfterAction = () => {
        handleStepTabChange(null, 2);
        handleStepContextMenuClose();
    };

    const templateItemClicked = (index) => {
        setTemplateSelectedIndex(index);
        if ( stepTabValue > 0 ) {
            const step = stepList[index];
            setStepDialogTemplate(step.name);
            setStepDialogName(step.label);
            setStepDialogDescription(step.description);
            switch(stepTabValue) {
                case 1:
                    setStepDialogTitle('Insert Before');
                    break;
                case 2:
                    setStepDialogTitle('Insert After');
                    break;
            }
            setStepDialogOpen(true);
        }
    };

    const stepDialogSaveHandler = (name,description,stepDefName,targetName) => {
        setStepDialogOpen(false);
        console.log('Name = ' + name + ', Description = ' + description);
        const workflow = workflowList[selectedIndex];
        let steps = workflow.steps;
        if ( typeof workflow.saveSteps === 'undefined' ) {
            workflow.saveSteps = [ ...steps]; // this should save server steps safe
        }
        const stepTemplate = stepList[templateSelectedIndex];
        let position = stepSelectedIndex;
        if ( stepTabValue === 2 ) {
            position = position + 1;
        }
        let nstep = {
            name: stepDefName,
            label: name,
            description: description,
            flag: 4,
            id: position,
        }
        if ( stepDefName === 'CALL' || stepDefName === 'FORM') {
            nstep['properties'] = {
                target: targetName,
            };
        }
        if ( position >= steps.length ) {
            steps.push(nstep);
        } else {
            steps.splice(position,0,nstep);
            for(let i=position+1; i<steps.length; i++) {
                steps[i].id = i;
            }
        }
        // serverSave(workflow);
        markModified();
    };

    const findStepTemplate = (stepDefName) => {
        return fullStepList.find(item => item.name === stepDefName );
    }

    const stepEditDialogSaveHandler = (name,description, stepDefName, targetName) => {
        setStepEditDialogOpen(false);
        console.log('Name = ' + name + ', Description = ' + description + ', template = ' + stepDefName);
        const workflow = workflowList[selectedIndex];
        let steps = workflow.steps;
        if ( typeof workflow.saveSteps === 'undefined' ) {
            workflow.saveSteps = [ ...steps]; // this should keep server steps safe
        }
        // const stepTemplate = findStepTemplate(stepDefName);
        let nstep = { ...steps[stepSelectedIndex]};
        nstep.label = name;
        nstep.description = description;
        nstep.name = stepDefName;
        steps[stepSelectedIndex] = nstep;
        if ( stepDefName === 'CALL' || stepDefName === 'FORM') {
            nstep['properties'] = {
                target: targetName,
            };
        }
        markModified();
    };

    const markModified = () => {
        const m = [ ...modified];
        if ( !m.includes(selectedIndex) ) {
            m.push(selectedIndex);
        }
        setModified(m);
        
        setSaveWorkflowEnabled(true);
        setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter( item => item !== selectedIndex);
        setModified(m);
        setCheckinEnabled(isLockedByMe(selectedIndex));
        setSaveWorkflowEnabled(false);
        setUndoSaveEnabled(false);
    };

    const saveWorkflowHandler = () => {
        const workflow = workflowList[selectedIndex];
        handleContextMenuClose();
        serverSave(workflow);
    };

    const undoSaveHandler = () => {
        handleStepContextMenuClose();
        const workflow = workflowList[selectedIndex];
        if ( typeof workflow.saveSteps !== 'undefined') {
            workflow.steps = [ ...workflow.saveSteps];
            delete workflow.saveSteps;
        }
        unmarkModified();
    };

    const serverSave = (def) => {
        // const payload = { definition: def, message: 'Checkin'};
        const sdef = { ...def};
        delete sdef.saveSteps;
        netPost('/api/workflow/def/update', sdef)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not update workflow definition: status = ' + resp.status);
                } else {
                    unmarkModified();
                }
            });
    };

    const serverDelete = (def) => {
        netFetch('/api/workflow/def/' + def.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not delete workflow definition: status = ' + resp.status);
                }
            });
    };

    const stepDialogCancelHandler = () => {
        setStepDialogOpen(false);
    };

    const stepEditDialogCancelHandler = () => {
        setStepEditDialogOpen(false);
    };

    const deleteStepHandler = () => {
        const workflow = workflowList[selectedIndex];
        const step = workflow.steps[stepSelectedIndex];
        setStepDeleteName(step.label);
        setStepDeleteWorkflowName(workflow.label);
        setStepDeleteOpen(true);
        handleStepContextMenuClose();
    };

    const deleteStepFromServer = () => {
        const workflow = workflowList[selectedIndex];
        let steps = workflow.steps;
        if ( typeof workflow.saveSteps === 'undefined' ) {
            workflow.saveSteps = [ ...steps]; // this should save server steps safe
        }
        let position = stepSelectedIndex;
        if (position > 0) {
            console.log('Deleting step ' + steps[position].label);
            steps.splice(position, 1);
            for(let i=position; i<steps.length; i++) {
                steps[i].id = i;
            }
            // serverSave(workflow);
            markModified();
        }
    };

    const deleteStepSave = () => {
        setStepDeleteOpen(false);
        deleteStepFromServer();
    };

    const deleteStepCancel = () => {
        setStepDeleteOpen(false);
    };

    const handleDeleteWorkflowAction = () => {
        const workflow = workflowList[selectedIndex];
        setWorkflowDeleteName(workflow.label);
        setWorkflowDeleteOpen(true);
    }

    const deleteWorkflowSave = () => {
        setWorkflowDeleteOpen(false);
        const workflow = workflowList[selectedIndex];
        serverDelete(workflow);
    };

    const deleteWorkflowCancel = () => {
        setWorkflowDeleteOpen(false);

    };

    const handleCreateWorkflowAction = () => {
        setStepContextMenu(null);
        setWorkflowDialogOpen(true);
    };

    const handleEditStepAction = () => {
        setStepContextMenu(null);
        setStepEditDialogTitle('Edit Step');
        setStepEditDialogOpen(true);
        // stepSelectedIndex;
    };

    const workflowDialogCreateHandler = (name, description, template, templateType) => {
        handleContextMenuClose();
        setWorkflowDialogOpen(false);
        if ( typeof name === 'string' && name.length > 0 ) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime()/100);
            const suffix = timestamp.toString(16);
            const id = `${name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            console.log('name: ' + name + ',id: ' + id);
            let newWorkflow = {
                name: id,
                label: name,
                description: description,
                flag: 0,
                category: templateType ? templateType : 'MODEL',
                steps: [],
            }
            //console.log('creating from template: ' + template);
            if ( typeof template === 'string' && template.length > 0 && template !== 'None') {
                let def = workflowList.find( (item) => item.label === template);
                if ( def ) {
                    def.steps.forEach( (item) => {
                        const step = { ...item};
                        newWorkflow.steps.push(step);
                    });
                } else {
                    newWorkflow.steps.push( 
                        { 
                            name: 'START',
                            id: 0,
                            label: 'START',
                        }
                    );
                }

            } else {
                newWorkflow.steps.push( 
                    { 
                        name: 'START',
                        id: 0,
                        label: 'START',
                    }
                );
            }
            addServerWorkflow(newWorkflow);
        }
    };

    const addServerWorkflow = (workflow) => {
        netPost('/api/workflow/def', workflow)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not create workflow definition: status = ' + resp.status);
                }
            });
    };

    const workflowDialogCancelHandler = () => {
        setWorkflowDialogOpen(false);
    };

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    const attributesEditDialogSaveHandler = (name, description) => {
        setAttributesEditDialogOpen(false);
        setStepContextMenu(null);
        let def = workflowList[selectedIndex];
        def.label = name;
        def.description = description;
        updateServerWorkflow(def);
    };

    const updateServerWorkflow = (workflow) => {
        netPost('/api/workflow/def/update', workflow)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not update workflow definition: status = ' + resp.status);
                }
            });
    };

    const attributesEditDialogCancelHandler = (name, description) => {
        setAttributesEditDialogOpen(false);
        setStepContextMenu(null);
    };

    
    return (
        <Box sx={{position: 'relative', width: '100%', height: '100%', display: 'grid', gridTemplateColumns: '30% 3px 1fr 3px 25%', gridTemplateRows: '100%'}}>
            <Box sx={{position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid',  gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content'}}>
                <Typography align="center" sx={{paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Workflows</Typography>
                <TextField
                    id="mwt-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                />
                <Box 
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }} 
                    onContextMenu={(event) => handleContextMenu(event, -1)}
                >

                    <List sx={{ minHeight: '2em' }}>
                        {
                            workflowList.map((def, index) => (
                                <ListItem
                                    key={'mww-' + index} 
                                    sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                    onContextMenu={(event) => handleContextMenu(event, index)}
                                >
                                    <Box sx={{ float: 'left', color: lockIconColor(index), fontSize: '12px', paddingTop: '0.5ex', alignItems: 'baseline'}}>
                                        <Tooltip title={lockIconLabel(index)}>
                                            <span>
                                                <LockIcon fontSize="inherit" />
                                            </span>
                                        </Tooltip>
                                        
                                    </Box>
                                    <Box sx={{ float: 'left', color: modifiedColor(index), fontSize: '12px', paddingTop: '0.5ex', alignItems: 'baseline'}}>
                                        <Tooltip title={modifiedLabel(index)}>
                                            <Typography sx={{paddingLeft: '4px'}}>*</Typography>
                                        </Tooltip>
                                    </Box>
                                    <ListItemButton onClick={(event) => handleItemClick(index)} sx={{paddingLeft: '8px', paddingRight: '8px'}} 
                                        selected={selectedIndex === index}>
                                        <ListItemText sx={{fontStyle: def.category.endsWith('_SUB') ? 'italic' : 'normal'}}>{def.label}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                        }
                    </List>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleMenuCheckout} disabled={!checkoutEnabled}>Checkout</MenuItem>
                        <MenuItem onClick={handleMenuRevokeCheckout} disabled={!checkinEnabled}>Revoke Checkout</MenuItem>
                        <MenuItem onClick={saveWorkflowHandler} disabled={!saveWorkflowEnabled}>Save</MenuItem>
                        <MenuItem onClick={handleMenuCheckin} disabled={!checkinEnabled}>Checkin</MenuItem>
                        <MenuItem onClick={handleCreateWorkflowAction} disabled={!addWorkflowEnabled}>Add</MenuItem>
                        <MenuItem onClick={handleAttributesEdit} disabled={!attributesEditEnabled}>Properties</MenuItem>
                        <MenuItem onClick={handleMenuDeleteWorkflow} disabled={!deleteWorkflowEnabled}>Delete</MenuItem>
                    </Menu>
                    <WorkflowCheckinDialog open={checkinDialogOpen} onSave={handleCheckinSave} onCancel={handleCheckinCancel} 
                        title={checkinDialogTitle} workflowName={checkinDialogWorkflowName} />
                    <WorkflowRevokeCheckoutDialog open={revokeDialogOpen} onSave={handleRevokeCheckoutSave} title={revokeDialogTitle}
                        onCancel={handleRevokeCheckoutCancel} workflowName={checkinDialogWorkflowName} />
                </Box>
                <Box sx={{padding: '1ex', paddingLeft: 0, paddingRight: 0, whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Checkout Workflow">
                        <IconButton color="inherit" disabled={!checkoutEnabled} onClick={handleCheckoutAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <OutputIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Revoke Checkout Workflow">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleRevokeCheckoutAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <ReplayIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Workflow">
                        <IconButton color="inherit" disabled={!saveWorkflowEnabled} sx={{paddingLeft: '6px', paddingRight: '6px'}} onClick={saveWorkflowHandler}>
                            <SaveAltIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Checkin Workflow">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleCheckinAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <ExitToAppIcon color="inherit" sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Create a Workflow">
                        <IconButton color="inherit" sx={{ marginLeft: '10px', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addWorkflowEnabled} onClick={handleCreateWorkflowAction}>
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Workflow Properties">
                        <IconButton color="inherit" sx={{ paddingLeft: '2px', paddingRight: '6px' }} disabled={!attributesEditEnabled} onClick={handleAttributesEdit}>
                            <EditAttributesIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Workflow">
                        <IconButton color="inherit" disabled={!deleteWorkflowEnabled} onClick={handleDeleteWorkflowAction} sx={{paddingLeft: '2px', paddingRight: '6px'}} >
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    
                    <CreateTemplateWorkflowDialog open={workflowDialogOpen} onSave={workflowDialogCreateHandler} onCancel={workflowDialogCancelHandler} 
                        template={workflowDialogTemplate} name={workflowDialogName} description={workflowDialogDescription} 
                        title={workflowDialogTitle} />
                    <CreateTemplateWorkflowDialog open={attributesEditDialogOpen} onSave={attributesEditDialogSaveHandler} onCancel={attributesEditDialogCancelHandler}
                        editOnly={true} title={attributesEditDialogTitle} name={attributesEditDialogName} description={attributesEditDialogDescription} />
                    <WorkflowDeleteDialog title="Delete Workflow" open={workflowDeleteOpen} workflowName={workflowDeleteName} 
                    onSave={deleteWorkflowSave} onCancel={deleteWorkflowCancel}/>
                </Box>
            </Box>
            <Divider orientation="vertical"/>
            <Box sx={{width: '100%', height: '100%', position: 'relative', paddingBottom: '1ex',  display: 'grid',  gridTemplateRows: 'minmax(10px,1fr) min-content'}}
                onContextMenu={(event) => handleStepContextMenu(event, -1)} >
                { selectedIndex !== -1 ?
                    <WorkflowDefinitionStepEdit def={workflowList[selectedIndex]} onStepSelected={handleStepSelected} stepDefList={fullStepList} /> :
                    <Box/>
                }
                <Box sx={{padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Insert Before">
                        <IconButton color="inherit" disabled={!insertBeforeEnabled} onClick={handleInsertBeforeAction}>
                            <InsertBeforeIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Insert After">
                        <IconButton color="inherit" disabled={!insertAfterEnabled} onClick={handleInsertAfterAction}>
                            <InsertAfterIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Step">
                        <IconButton color="inherit" disabled={!editStepEnabled} onClick={handleEditStepAction}>
                            <ModeEditIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Step">
                        <IconButton color="inherit" disabled={!deleteStepEnabled} sx={{ marginLeft: '0.5em' }} onClick={deleteStepHandler}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Undo Modifications">
                        <IconButton color="inherit" disabled={!undoSaveEnabled} onClick={undoSaveHandler}>
                            <UndoIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <StepDeleteDialog title="Delete Step" open={stepDeleteOpen} stepName={stepDeleteName} workflowName={stepDeleteWorkflowName} 
                    onSave={deleteStepSave} onCancel={deleteStepCancel}/>
                <Menu
                    open={stepContextMenu !== null}
                    onClose={handleStepContextMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        stepContextMenu !== null
                            ? { top: stepContextMenu.mouseY, left: stepContextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={handleInsertBeforeAction} disabled={!insertBeforeEnabled}>Insert Before</MenuItem>
                    <MenuItem onClick={handleInsertAfterAction} disabled={!insertAfterEnabled}>Insert After</MenuItem>
                    <MenuItem onClick={handleEditStepAction} disabled={!editStepEnabled}>Edit</MenuItem>
                    <MenuItem onClick={deleteStepHandler} disabled={!deleteStepEnabled}>Delete</MenuItem>
                    <MenuItem onClick={undoSaveHandler} disabled={!undoSaveEnabled}>Undo</MenuItem>
                </Menu>
            </Box>
            <Divider orientation="vertical"/>
            <Box sx={{position: 'relative', padding: '1ex', paddingTop: '2ex', width: '100%', height: '100%'}}>
                <Typography align="center" sx={{display: 'block', paddingTop: '8px', paddingBottom: '1ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Steps</Typography>
                <Tabs value={stepTabValue} onChange={handleStepTabChange} aria-label="icon label steps tabs" sx={{padding: '4px'}} centered>
                    <Tab sx={{padding: '4px 6px', minWidth: '70px'}} icon={<Tooltip title="Library" ><CategoryIcon /></Tooltip>}/>
                    <Tab sx={{padding: '4px 6px', minWidth: '70px'}} icon={<Tooltip title="Insert Before" ><span><InsertBeforeIcon/></span></Tooltip> } disabled={!insertBeforeEnabled} />
                    <Tab sx={{padding: '4px 6px', minWidth: '70px'}} icon={<Tooltip title="Insert After"><span><InsertAfterIcon/></span></Tooltip> } disabled={!insertAfterEnabled} />
                </Tabs>
                <Box>
                    <List sx={{ minHeight: '2em' }}>
                        {
                            stepList.map((step, index) => (
                                <ListItem 
                                    key={'mwws-' + index} 
                                    sx={{ padding: '4px', paddingTop: 0, paddingBottom: 0 }}
                                    
                                >

                                    <ListItemButton onClick={() => templateItemClicked(index)} selected={templateSelectedIndex === index}>
                                        <ListItemText>{step.label}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                        }
                    </List>
                    <CreateStepWorkflowDialog open={stepDialogOpen} onSave={stepDialogSaveHandler} onCancel={stepDialogCancelHandler} 
                        template={stepDialogTemplate} name={stepDialogName} description={stepDialogDescription} stepList={stepList} 
                        step={Array.isArray(stepList) && stepList[templateSelectedIndex]} editOnly={false}
                        subroutine={''} form={''}
                        title={stepDialogTitle} />
                    <CreateStepWorkflowDialog open={stepEditDialogOpen} onSave={stepEditDialogSaveHandler} onCancel={stepEditDialogCancelHandler} 
                        template={stepEditDialogTemplate} name={stepEditDialogName} description={stepEditDialogDescription} stepList={editStepList} 
                        step={Array.isArray(editStepList) && editStepList[stepEditSelectedIndex]} 
                        subroutine={stepEditDialogTarget} form={stepEditDialogTarget} editOnly={true}
                        title={stepEditDialogTitle} />
                </Box>
            </Box>
        </Box>

    );
}



export { WorkflowDefinitionStepEdit, EditTemplate, };