import React, { Component } from "react";
import { styled, useTheme } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import InputAdornment from '@mui/material/InputAdornment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

import { UserInfo, netGet, netPost } from "../network";
import { openReadOnlyPdfDocument}  from "../webviewer";

import { DateTime } from "luxon";
const DATE_FORMAT = 'dd MMM yyyy, HH:mm ZZZZ';

const formatDate = (indate) => {
    const sd = DateTime.fromISO(indate);
    return sd.toFormat(DATE_FORMAT);
};

function WorkflowItem(props) {
    const {
        item,
        ...other
    } = props;

    const theme = useTheme();

    return (
        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            {item && <React.Fragment>
                

                <List>
                    {
                        item.properties.steps.map((step, index) => (
                            <ListItem key={'WICS' + step.id} alignItems="flex-start"
                            >
                                <ListItemButton alignItems="flex-start" >
                                    <ListItemAvatar>
                                        <Avatar sx={{ width: 24, height: 24 }}>
                                            <Typography>{index + 1}</Typography>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText disableTypography={true}
                                        primary={
                                            <Typography sx={{ fontWeight: 'bold' }} >{step.label}</Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography sx={{ fontSize: '14px' }}>{step.description}</Typography>
                                                <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                    <Typography sx={{ fontWeight: 'bold', width: '8em' }}>Start Date: </Typography>
                                                    <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                        {step.startDate && formatDate(step.startDate)}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                    <Typography sx={{ fontWeight: 'bold', width: '8em' }}>Complete Date: </Typography>
                                                    <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                        {step.endDate && formatDate(step.endDate)}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                    <Typography sx={{ fontWeight: 'bold', width: '8em' }}>Stakeholders: </Typography>
                                                    <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                        {step && Array.isArray(step.users) && step.users.length > 0 ?
                                                            step.users.reduce((total, user) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0 ' + user : user, '') : ''
                                                        }
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
            </React.Fragment>
            }
        </Box>
    );
}

function WorkflowItemDrafts(props) {
    const {
        item,
        ...other
    } = props;
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const theme = useTheme();

    const toggleSelected = (event,index) => {
        if ( selectedIndex === index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
            const draft = item.properties.pdfDrafts[index];
            openReadOnlyPdfDocument(draft.path, draft.label);
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            {item && <React.Fragment>
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" sx={{fontWeight: 'bold'}}>Name</TableCell>
                                <TableCell component="th" align="center" sx={{fontWeight: 'bold'}} >Date</TableCell>
                                <TableCell component="th" align="right" sx={{fontWeight: 'bold'}}>Version</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item.properties && item.properties.pdfDrafts && item.properties.pdfDrafts.map((row,index) => (
                                <TableRow
                                    key={'WCPD' + index} 
                                    sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                                    selected={index === selectedIndex}
                                    onClick={(event) => toggleSelected(event,index)}
                                >
                                    <TableCell>
                                        {row.label}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.date && formatDate(row.date)}
                                    </TableCell>
                                    <TableCell align="right">{row.version}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </React.Fragment>
            }
        </Box>
    );
};

function CompletedWorkflows(props) {

    const [workflowList, setWorkflowList] = React.useState([]);
    const [searchList, setSearchList] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const theme = useTheme();

    React.useEffect(() => {
        loadInstances();
    }, []);

    const loadInstances = () => {
        netGet('/api/dlib/list' )
            .then(response => response.json())
            .then(wlist => {
                
                if ( wlist && Array.isArray(wlist) ) {
                    // convert date
                    const wdlist = wlist.map((item) => {
                        let nitem = { ...item };
                        if (item.startDate) {
                            nitem['startDate'] = formatDate(item.startDate);
                            // console.log(item.startDate + ':' + sd.zoneName + ':' + sd.toFormat('fff'));
                        }
                        if (item.endDate) {
                             nitem['endDate'] = formatDate(item.endDate);
                        }
                        return nitem;
                    });
                    // console.log('Completed Workflows: ' + wlist.length + ' : ' + JSON.stringify(wlist));
                    setWorkflowList(wdlist);
                    //filter(searchValue);
                    setSearchValue('');
                    setSearchList([ ...wdlist]);
                }
            });
    };

    const filter = (term) => {
        if ( typeof term === 'string') {
            const t = term.trim().toLowerCase();
            if ( t === '' ) {
                const sl = [ ...workflowList];
                setSearchList(sl);
            } else {
                const sl = workflowList.filter( item => {
                    if ( item.label && item.label.toLowerCase().includes(t) ) {
                        return true;
                    }
                    if ( item.description && item.description.toLowerCase().includes(t) ) {
                        return true;
                    }
                    return false;
                });
                setSearchList(sl);
            }
        }
    };

    const handleContextMenu = (event, index) => {

    };

    const handleItemClick = (index) => {
        setSelectedIndex(index);
        setSelectedItem(searchList[index]);
    };

    const handleSearchValueChange = (event) => {
        const term = event.target.value;
        // console.log('Search: ' + term);
        setSearchValue(term);
        filter(term);
    };

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', 
                   gridTemplateAreas: '"title header" "search header" "result main" "result footer"',
                   gridTemplateColumns: '35% 1fr',
                   gridTemplateRows: 'min-content min-content 1fr 25%' }}>
            <Typography align="center" variant="h7" sx={{ paddingBottom: '2ex', fontWeight: theme.typography.fontWeightBold, gridArea: 'title' }}>Completed Workflows</Typography>
            <Box sx={{textAlign: 'center', gridArea: 'search', paddingBottom: '1em'}}>
                <TextField
                    id="mwc-search"
                    type="search"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    value={searchValue}
                    onChange={handleSearchValueChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box sx={{ gridArea: 'result', overflowY: 'auto', }}>
                <List sx={{ minHeight: '2em'}}>
                    {
                        searchList.map((w, index) => (
                            <ListItem
                                key={'mwwc-' + index}
                                sx={{ padding: 1, paddingTop: 0, paddingBottom: 0 }}
                                onContextMenu={(event) => handleContextMenu(event, index)}
                            >
                                <ListItemButton onClick={(event) => handleItemClick(index)} selected={selectedIndex === index}>
                                    <ListItemText>
                                        <Typography sx={{fontSize: '80%', color: 'gray'}}>{w.label}</Typography>
                                        <Typography sx={{fontSize: '70%', color: 'gray'}}>{w.endDate}</Typography>
                                        <Typography sx={{fontWeight: 'bold'}}>{w.description}</Typography>
                                        
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                        )
                    }
                </List>
            </Box>
            <Box sx={{gridArea: 'header', padding: '1ex', paddingLeft: '1em'}}>
                { selectedItem && 
                   <React.Fragment>
                      <Typography >{selectedItem.label}</Typography>
                      <Typography sx={{ paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>{selectedItem.description}</Typography>
                   </React.Fragment> 
                }
            </Box>
            <Box sx={{gridArea: 'main', padding: '1ex', paddingTop: 0, width: '100%', overflowY: 'auto'}}>
                <WorkflowItem item={selectedItem}/>
            </Box>
            <Box sx={{gridArea: 'footer', padding: '1ex'}}>
                <WorkflowItemDrafts item={selectedItem}/>
            </Box>

        </Box>
    );

}

export { CompletedWorkflows, };