import React, { Component } from "react";

import Box from '@mui/material/Box';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import Tooltip from '@mui/material/Tooltip';


function EditIndicator(props) {
    const {
        readOnly,
        ...other
    } = props;

    return (
        <Box sx={{position: 'relative', float: 'right', right: '20px', top: '6px', fontSize: '24px'}}>
            {
                readOnly ?
                    <Tooltip title="Read/Only">
                        <Box sx={{ position: 'absolute', left: 6, top: 5, fontSize: '16px', color: 'gray' }}>
                            <ModeEditIcon fontSize="inherit" />
                        </Box>
                    </Tooltip>
                    :
                    <Tooltip title="Read/Write">
                        <Box sx={{ position: 'absolute', left: 6, top: 5, fontSize: '16px', color: 'green' }}>
                            <ModeEditIcon fontSize="inherit" />
                        </Box>
                    </Tooltip>

                    
            }
        </Box>

    );

}


export { EditIndicator,  };