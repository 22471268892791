import React, { Component, useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch, useStore } from 'react-redux';
// import store from '../app/store';
import { setCurrentMenu } from '../features/menu/menuSlice';
import useVisibleOnScreen from "components/visible";
import postal from "postal";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListIcon from '@mui/icons-material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DatasetIcon from '@mui/icons-material/Dataset';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RuleIcon from '@mui/icons-material/Rule';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import OutputIcon from '@mui/icons-material/Output';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ClearIcon from '@mui/icons-material/Clear';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


import { MessageHooks } from "../App";
import { InsertBeforeIcon, InsertAfterIcon } from "./icons";

import { EditTemplate } from "./workflow/management-workflow-template";
import { EditWorkflow } from "./workflow/management-workflow-attributes";
import { PublishedWorkflows } from "./workflow/management-workflow-published";
import { WorkflowForms } from "./workflow/management-workflow-forms";
import { WorkflowFormLists } from "./workflow/management-workflow-forms";
import { DocumentConfiguration } from "./document/management-config";
import { AclConfiguration } from "./acl/management-acl";
import { UserInfo, netGet, netPost } from "./network";


const ACL_PATH = [
    '/C/management/workflow',
    '/C/management/workflow/templates',
    '/C/management/workflow/profiles',
    '/C/management/workflow/forms',
    '/C/management/workflow/published',
    '/C/management/document',
    '/C/management/document/config',
    '/C/management/security', 
    '/C/management/security/acl'
];

const WORKFLOW_INDEX = 0;
const TEMPLATES_INDEX = 1;
const PROFILES_INDEX = 2;
const FORMS_INDEX = 3;
const PUBLISHED_INDEX = 4;
const DOCUMENT_INDEX = 5;
const DOC_CONFIG_INDEX = 6;
const SECURITY_INDEX = 7;
const ACL_INDEX = 8;


function ManagementMenu(props) {
    const [menuIcon, setMenuIcon] = React.useState('app');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [pendingUpdates, setPendingUpdates] = useState(false);
    const theme = useTheme();
    const [isVisible, containerRef] = useVisibleOnScreen();
    const [aclOpen, setAclOpen] = useState(false);
    const [menuACL, setMenuACL] = useState([]);

    // const currentMenu = useSelector((state) => state.menu.currentMenu);
    // const store = useStore();

    React.useEffect(() => {
        // console.log('MNGNT VISIBLE: ' + isVisible);
        if ( isVisible ) {
            // store.dispatch(setCurrentMenu('management'));
            postal.publish({
                topic: "app.menu.change",
                data: {
                    menu: 'management',
                }
            });
            console.log('Posting currentMenu = management');
            if ( pendingUpdates ) {
                setPendingUpdates(false);
                // loadUpdates();
            }
            readACLs();
        }
    }, [isVisible]);

    const readACLs = () => {
        const aclm = [];
        const aparams = [];
        ACL_PATH.forEach( aclPath => {
            aparams.push({
                path: aclPath,
                action: 'BROWSE',
                subjects: UserInfo.info.roles,
            });
            aparams.push({
                path: aclPath,
                action: 'READ',
                subjects: UserInfo.info.roles,
            });
            /*
            aparams.push({
                path: aclPath,
                action: 'WRITE',
                subjects: UserInfo.info.roles,
            });
            */
        });
        netPost('/api/auth/acl/eval/multi', {
            params: aparams
        }).then(response => {
            if (!response.ok) {
                throw new Error('Status: ' + response.status);
            }
            return response.json();
        })
            .then(data => {
                // console.log('ACL Result [MANAGEMENT] = ' + JSON.stringify(data));
                if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i += 2) {
                        aclm.push({
                            hide: !data[i].result,
                            disabled: !data[i + 1].result,
                            // write: data[i + 2].result,
                        });
                    }

                    setMenuACL(aclm);
                    // console.log('MNGNT MENU ACL: ' + JSON.stringify(aclm));
                }

            }).catch(error => {
                console.log('Error: ' + error);
            });
    }

    /*
    useEffect( () => {
        console.log('MNGNT currentMenu changed = ' + currentMenu);
    },[currentMenu]);
    */

    const handleTemplateAction = (event) => {
        ManagementModule.pageChange(<EditTemplate/>)
        setSelectedIndex(0);
        setAclOpen(false);
    };

    const handleWorkflowAction = (event) => {
        ManagementModule.pageChange(<EditWorkflow/>);
        setSelectedIndex(1);
        setAclOpen(false);
    };

    const handleFormsAction = (event) => {
        ManagementModule.pageChange(<WorkflowForms/>);
        setSelectedIndex(4);
        setAclOpen(false);
    }

    const handleListsAction = (event) => {
        ManagementModule.pageChange(<WorkflowFormLists/>);
        setSelectedIndex(5);
        setAclOpen(false);
    }

    const handlePublishedAction = (event) => {
        ManagementModule.pageChange(<PublishedWorkflows/>);
        setSelectedIndex(2);
        setAclOpen(false);
    };

    const handleMenuIconClick = (event) => {
        if ( menuIcon === 'back' ) {
            ManagementModule.menuIconChange('app');
            setMenuIcon('app');
        } else {
            ManagementModule.menuIconChange('back');
            setMenuIcon('back');
        }
    };

    const handleDocsConfigAction = (event) => {
        ManagementModule.pageChange(<DocumentConfiguration/>);
        setSelectedIndex(3);
        setAclOpen(false);
    };

    const handleAccessAction = (event) => {
        if ( selectedIndex !== 6) {
            setAclOpen(true);
            ManagementModule.pageChange(<AclConfiguration open={true} />);
        }
        setSelectedIndex(6);
    };

    ManagementModule.onMenuIconClick = handleMenuIconClick;

    return (
        <Box ref={containerRef}
            sx={{ width: '100%', fontSize: '1.3em', padding: 0, paddingTop: "1ex", position: "relative" }}
            role="presentation"
        >
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="mw-workflow" disablePadding sx={{display: Array.isArray(menuACL) && WORKFLOW_INDEX < menuACL.length && menuACL[WORKFLOW_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <SchemaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Workflow" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="mw-templates" disablePadding 
                    sx={{display: Array.isArray(menuACL) && TEMPLATES_INDEX < menuACL.length && menuACL[TEMPLATES_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleTemplateAction} selected={selectedIndex === 0} 
                        disabled={Array.isArray(menuACL) && TEMPLATES_INDEX < menuACL.length && menuACL[TEMPLATES_INDEX].disabled}>
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <FormatListNumberedIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Templates" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="mw-archetypes" disablePadding 
                    sx={{display: Array.isArray(menuACL) && PROFILES_INDEX < menuACL.length && menuACL[PROFILES_INDEX].hide ? "none" : "block"}}>
                    <ListItemButton onClick={handleWorkflowAction} selected={selectedIndex === 1}
                        disabled={Array.isArray(menuACL) && PROFILES_INDEX < menuACL.length && menuACL[PROFILES_INDEX].disabled} >
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px' }}>
                            <RuleIcon  fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText primary="Assembly & Profiling" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
                <ListItem key="mw-forms" disablePadding 
                    sx={{display: Array.isArray(menuACL) && FORMS_INDEX < menuACL.length && menuACL[FORMS_INDEX].hide ? "none" : "block"}}>
                    <ListItemButton onClick={handleFormsAction} selected={selectedIndex === 4}
                        disabled={Array.isArray(menuACL) && FORMS_INDEX < menuACL.length && menuACL[FORMS_INDEX].disabled} >
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px' }}>
                            <DatasetIcon  fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText primary="Forms" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                {/*
                <ListItem key="mw-lists" disablePadding >
                    <ListItemButton onClick={handleListsAction} selected={selectedIndex === 5}>
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px' }}>
                            <ListIcon  fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText primary="Selection Lists" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                    */}
                <ListItem key="mw-published" disablePadding
                    sx={{display: Array.isArray(menuACL) && PUBLISHED_INDEX < menuACL.length && menuACL[PUBLISHED_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handlePublishedAction} selected={selectedIndex === 2}
                        disabled={Array.isArray(menuACL) && PUBLISHED_INDEX < menuACL.length && menuACL[PUBLISHED_INDEX].disabled} >
                        <ListItemIcon sx={{ paddingRight: 0, paddingLeft: '40px', minWidth: '70px'}}>
                            <PublishedWithChangesIcon  fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Published" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="mw-docs" disablePadding 
                    sx={{display: Array.isArray(menuACL) && DOCUMENT_INDEX < menuACL.length && menuACL[DOCUMENT_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Documents" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="mw-docs-config" disablePadding 
                    sx={{display: Array.isArray(menuACL) && DOC_CONFIG_INDEX < menuACL.length && menuACL[DOC_CONFIG_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleDocsConfigAction} selected={selectedIndex === 3}
                        disabled={Array.isArray(menuACL) && DOC_CONFIG_INDEX < menuACL.length && menuACL[DOC_CONFIG_INDEX].disabled} >
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <SettingsIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Configuration" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List  sx={{paddingBottom: 0}} >
                <ListItem key="mw-security" disablePadding 
                    sx={{display: Array.isArray(menuACL) && SECURITY_INDEX < menuACL.length && menuACL[SECURITY_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton>
                        <ListItemIcon sx={{ fontSize: 18, paddingRight: 0, minWidth: '40px' }}>
                            <SecurityIcon />
                        </ListItemIcon>
                        <ListItemText primary="Security" primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: theme.typography.fontWeightBold,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
            <List sx={{paddingTop: 0}}>
                <ListItem key="mw-security-access" disablePadding 
                    sx={{display: Array.isArray(menuACL) && ACL_INDEX < menuACL.length && menuACL[ACL_INDEX].hide ? "none" : "block"}} >
                    <ListItemButton onClick={handleAccessAction} selected={selectedIndex === 6}
                        disabled={Array.isArray(menuACL) && ACL_INDEX < menuACL.length && menuACL[ACL_INDEX].disabled} >
                        <ListItemIcon sx={{ paddingRight: 0,  paddingLeft: '40px', minWidth: '70px'}}>
                            <AdminPanelSettingsIcon fontSize="inherit"/>
                        </ListItemIcon>
                        <ListItemText primary="Access Controls" primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                        }} />
                    </ListItemButton>
                </ListItem>
                
            </List>
        </Box>
    );
}


const ManagementModule = {
    // label
    name: 'management',
    label: 'Management',
    barLabel: 'Management',
    barShortLabel: 'Management',
    // icon
    icon: <ManageAccountsIcon fontSize="inherit" />,
    // menu
    drawerContent: <ManagementMenu />,
    // initial body page
    pageContent: null,
    // send new body page on menu actions
    pageChange: (page) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},

    hooks: {}, // { workflow: workflowInfo },
    route: {
        path: "management",
        element: <ManagementMenu />,
    },
    aclPaths: [ 
        {path: '/C/management', label: 'Management'}, 
        {path: '/C/management/workflow', label: 'Workflow'},
        {path: '/C/management/workflow/templates', label: 'Templates'},
        {path: '/C/management/workflow/profiles', label: 'Assembly & Profiling'},
        {path: '/C/management/workflow/forms', label: 'Forms'},
        {path: '/C/management/workflow/published', label: 'Published'},
        {path: '/C/management/document', label: 'Documents'},
        {path: '/C/management/document/config', label: 'Configuration'},
        {path: '/C/management/security', label: 'Security'}, 
        {path: '/C/management/security/acl', label: 'Access Controls'}
    ],
}

export default ManagementModule;