import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { 
  setUser, 
  setAuthenticatedAsync,
  setSessionId,
} from '../features/network/networkSlice';
import { netGet, UserInfo} from "../components/network";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function FormLoginDialog(props) {
  const {
      open,
      ...other
  } = props;

  const [nameValue, setNameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('\xa0');

  const dispatch = useDispatch();

  /*
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const CreateMenuProps = {
      PaperProps: {
          style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
          },
      },
  };
  */
  

  const onOKClick = () => {
    login();
  }

  const login = () => {
    // Create an object to represent the form data
    const formData = new URLSearchParams();
    formData.append("j_username", nameValue);
    formData.append("j_password", passwordValue);

    // Make an HTTP POST request using fetch against j_security_check endpoint
    fetch("j_security_check", {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // Authentication was successful
          dispatch(setAuthenticatedAsync(true));
          setErrorMessage('\xa0');
          console.log("Authentication successful");
          netGet('/api/auth/user')
            .then(response => response.json())
            .then(data => {
              // Direct call may not contain a sessionId
              // as they come from the socket server
              // try to preserve it if we already have one
              let sid;
              if (UserInfo.info) {
                sid = UserInfo.info.sessionId;
              }
              // console.log('Got user = ' + JSON.stringify(data));
              UserInfo.info = data;
              dispatch(setUser(data));
              if (!UserInfo.info.sessionId) {
                UserInfo.info.sessionId = sid;
                if (sid) {
                  dispatch(setSessionId(sid));
                }
              }
              console.log('Fetched user data: ' + UserInfo.info.email + ' : ' + UserInfo.info.sessionId);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        } else {
          // Authentication failed
          console.error("Invalid credentials");
          setErrorMessage('Invalid credentials');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*
  const onCancelClick = () => {
      onClose();
  };

  const onClose = () => {
      if ( typeof onCancel === 'function' ) {
          onCancel();
      }
  };
 */
  const nameChanged = (event) => {
      setNameValue(event.target.value);
  };

  const passwordChanged = (event) => {
      setPasswordValue(event.target.value);
  };

  return (
      <Dialog
          maxWidth={'900px'}
          open={open}
          PaperProps={{
              sx: {
                minWidth: 500,
                maxHeight: 600
              }
            }}
      >
          <DialogTitle sx={{fontWeight: 'bold'}}>Log In to Uniscope Cloud Portal</DialogTitle>
          <DialogContent> 
              <Box sx={{paddingTop: '2ex'}}>
                  <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth />
              </Box>
              <Box sx={{paddingTop: '3ex'}}>
                  <TextField label="Password" value={passwordValue} onChange={passwordChanged} size="small" type="password"
                      fullWidth />
              </Box>
              <Box sx={{paddingTop: '1ex'}}>
                <Typography sx={{color: 'red', fontSize: '9pt'}}>{errorMessage}</Typography>
              </Box>
          </DialogContent>
          <DialogActions sx={{padding: 3}}>
              <Button type="submit" variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}} >Login</Button>
          </DialogActions>
      </Dialog>

  );

}

export default function Login() {
    const [open, setOpen] = React.useState(true);
    const isAuthenticated = useSelector((state) => state.auth.authenticated);
    const isFormEnabled = useSelector((state) => state.auth.formEnabled);

    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };
  
    return (
        <Box sx={{display: 'flex', textAlign: 'center', alignItems: 'center', height: '100%', justifyContent: 'center', backgroundImage: 'linear-gradient(to bottom right, white, rgb(102, 204, 255, 0.05) , rgb(204, 204, 255, 0.1))' }}>
            <Box sx={{textAlign: 'center' }}>
                <Typography sx={{ fontSize: '20px' }}><img src="/images/application.svg" width="40" style={{ paddingLeft: "0px", paddingRight: '2ex', position: 'relative', top: '3px'}}/>Loading UCS application. Please wait....</Typography>
                <CircularProgress color="inherit" sx={{ marginTop: '70px' }} />
            </Box>
            <FormLoginDialog open={isFormEnabled && !isAuthenticated}/>
        </Box>
    );
};

/*
<div>
        <Button onClick={handleOpen}>Show backdrop</Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>


*/