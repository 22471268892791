/**
 * This creates a CSS Stylesheet object that can be loaded once and shared
 * by all components. Currently each popup menu is a shadow component that
 * loads the stylesheet remotely (even if cached, needs to be parsed) each time a menu is used,
 * as it looks like the menu is re-created each time. This is causing performance issues depending
 * on network latency.
 */
const XUI_STYLES =
`
.xui-edit-icon-16 {
display: inline-block;
width: 16px;
height: 16px;
}
.xui-edit-icon-24 {
display: inline-block;
width: 24px;
height: 24px;
}
.xui-edit-icon-32 {
display: inline-block;
width: 32px;
height: 32px;
}
.xui-edit-icon-48 {
display: inline-block;
width: 48px;
height: 48px;
}
.xui-edit-icon-64 {
display: inline-block;
width: 64px;
height: 64px;
}
.xui-undo-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px 0px;
}
.xui-undo-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px 0px;
}
.xui-undo-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px 0px;
}
.xui-undo-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px 0px;
}
.xui-undo-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px 0px;
}
.xui-redo-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px 0px;
}
.xui-redo-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px 0px;
}
.xui-redo-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px 0px;
}
.xui-redo-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px 0px;
}
.xui-redo-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px 0px;
}
.xui-repeat-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px 0px;
}
.xui-repeat-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px 0px;
}
.xui-repeat-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px 0px;
}
.xui-repeat-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px 0px;
}
.xui-repeat-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px 0px;
}
.xui-commandHistory-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px 0px;
}
.xui-commandHistory-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px 0px;
}
.xui-commandHistory-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px 0px;
}
.xui-commandHistory-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px 0px;
}
.xui-commandHistory-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px 0px;
}
.xui-paste-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px 0px;
}
.xui-paste-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px 0px;
}
.xui-paste-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px 0px;
}
.xui-paste-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px 0px;
}
.xui-paste-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px 0px;
}
.xui-delete-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px 0px;
}
.xui-delete-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px 0px;
}
.xui-delete-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px 0px;
}
.xui-delete-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px 0px;
}
.xui-delete-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px 0px;
}
.xui-replace-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px 0px;
}
.xui-replace-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px 0px;
}
.xui-replace-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px 0px;
}
.xui-replace-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px 0px;
}
.xui-replace-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px 0px;
}
.xui-insertBefore-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px 0px;
}
.xui-insertBefore-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px 0px;
}
.xui-insertBefore-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px 0px;
}
.xui-insertBefore-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px 0px;
}
.xui-insertBefore-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px 0px;
}
.xui-insert-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px 0px;
}
.xui-insert-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px 0px;
}
.xui-insert-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px 0px;
}
.xui-insert-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px 0px;
}
.xui-insert-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px 0px;
}
.xui-insertAfter-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px 0px;
}
.xui-insertAfter-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px 0px;
}
.xui-insertAfter-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px 0px;
}
.xui-insertAfter-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px 0px;
}
.xui-insertAfter-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px 0px;
}
.xui-convert-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -16px;
}
.xui-convert-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -24px;
}
.xui-convert-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -32px;
}
.xui-convert-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -48px;
}
.xui-convert-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -64px;
}
.xui-wrap-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -16px;
}
.xui-wrap-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -24px;
}
.xui-wrap-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -32px;
}
.xui-wrap-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -48px;
}
.xui-wrap-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -64px;
}
.xui-split-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -16px;
}
.xui-split-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -24px;
}
.xui-split-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -32px;
}
.xui-split-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -48px;
}
.xui-split-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -64px;
}
.xui-join-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -16px;
}
.xui-join-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -24px;
}
.xui-join-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -32px;
}
.xui-join-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -48px;
}
.xui-join-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -64px;
}
.xui-textSearchReplace-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -16px;
}
.xui-textSearchReplace-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -24px;
}
.xui-textSearchReplace-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -32px;
}
.xui-textSearchReplace-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -48px;
}
.xui-textSearchReplace-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -64px;
}
.xui-declareNamespace-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -16px;
}
.xui-declareNamespace-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -24px;
}
.xui-declareNamespace-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -32px;
}
.xui-declareNamespace-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -48px;
}
.xui-declareNamespace-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -64px;
}
.xui-editAttributes-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -16px;
}
.xui-editAttributes-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -24px;
}
.xui-editAttributes-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -32px;
}
.xui-editAttributes-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -48px;
}
.xui-editAttributes-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -64px;
}
.xui-insertOrEditRemark-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -16px;
}
.xui-insertOrEditRemark-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -24px;
}
.xui-insertOrEditRemark-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -32px;
}
.xui-insertOrEditRemark-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -48px;
}
.xui-insertOrEditRemark-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -64px;
}
.xui-deleteRemark-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -16px;
}
.xui-deleteRemark-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -24px;
}
.xui-deleteRemark-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -32px;
}
.xui-deleteRemark-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -48px;
}
.xui-deleteRemark-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -64px;
}
.xui-deleteAllRemarks-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -16px;
}
.xui-deleteAllRemarks-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -24px;
}
.xui-deleteAllRemarks-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -32px;
}
.xui-deleteAllRemarks-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -48px;
}
.xui-deleteAllRemarks-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -64px;
}
.xui-previousRemark-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -32px;
}
.xui-previousRemark-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -48px;
}
.xui-previousRemark-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -64px;
}
.xui-previousRemark-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -96px;
}
.xui-previousRemark-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -128px;
}
.xui-nextRemark-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -32px;
}
.xui-nextRemark-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -48px;
}
.xui-nextRemark-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -64px;
}
.xui-nextRemark-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -96px;
}
.xui-nextRemark-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -128px;
}
.xui-newDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -32px;
}
.xui-newDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -48px;
}
.xui-newDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -64px;
}
.xui-newDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -96px;
}
.xui-newDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -128px;
}
.xui-openDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -32px;
}
.xui-openDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -48px;
}
.xui-openDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -64px;
}
.xui-openDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -96px;
}
.xui-openDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -128px;
}
.xui-saveDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -32px;
}
.xui-saveDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -48px;
}
.xui-saveDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -64px;
}
.xui-saveDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -96px;
}
.xui-saveDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -128px;
}
.xui-autoSaveDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -32px;
}
.xui-autoSaveDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -48px;
}
.xui-autoSaveDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -64px;
}
.xui-autoSaveDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -96px;
}
.xui-autoSaveDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -128px;
}
.xui-saveDocumentAs-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -32px;
}
.xui-saveDocumentAs-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -48px;
}
.xui-saveDocumentAs-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -64px;
}
.xui-saveDocumentAs-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -96px;
}
.xui-saveDocumentAs-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -128px;
}
.xui-closeDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -32px;
}
.xui-closeDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -48px;
}
.xui-closeDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -64px;
}
.xui-closeDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -96px;
}
.xui-closeDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -128px;
}
.xui-styles-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -32px;
}
.xui-styles-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -48px;
}
.xui-styles-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -64px;
}
.xui-styles-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -96px;
}
.xui-styles-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -128px;
}
.xui-addListItem-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -32px;
}
.xui-addListItem-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -48px;
}
.xui-addListItem-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -64px;
}
.xui-addListItem-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -96px;
}
.xui-addListItem-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -128px;
}
.xui-anchor-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -48px;
}
.xui-anchor-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -72px;
}
.xui-anchor-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -96px;
}
.xui-anchor-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -144px;
}
.xui-anchor-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -192px;
}
.xui-bold-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -48px;
}
.xui-bold-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -72px;
}
.xui-bold-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -96px;
}
.xui-bold-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -144px;
}
.xui-bold-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -192px;
}
.xui-cancel-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -48px;
}
.xui-cancel-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -72px;
}
.xui-cancel-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -96px;
}
.xui-cancel-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -144px;
}
.xui-cancel-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -192px;
}
.xui-cell-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -48px;
}
.xui-cell-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -72px;
}
.xui-cell-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -96px;
}
.xui-cell-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -144px;
}
.xui-cell-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -192px;
}
.xui-charCase-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -48px;
}
.xui-charCase-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -72px;
}
.xui-charCase-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -96px;
}
.xui-charCase-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -144px;
}
.xui-charCase-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -192px;
}
.xui-column-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -48px;
}
.xui-column-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -72px;
}
.xui-column-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -96px;
}
.xui-column-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -144px;
}
.xui-column-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -192px;
}
.xui-copy-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -48px;
}
.xui-copy-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -72px;
}
.xui-copy-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -96px;
}
.xui-copy-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -144px;
}
.xui-copy-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -192px;
}
.xui-cut-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -48px;
}
.xui-cut-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -72px;
}
.xui-cut-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -96px;
}
.xui-cut-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -144px;
}
.xui-cut-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -192px;
}
.xui-decrementColumnSpan-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -48px;
}
.xui-decrementColumnSpan-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -72px;
}
.xui-decrementColumnSpan-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -96px;
}
.xui-decrementColumnSpan-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -144px;
}
.xui-decrementColumnSpan-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -192px;
}
.xui-decrementRowSpan-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -48px;
}
.xui-decrementRowSpan-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -72px;
}
.xui-decrementRowSpan-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -96px;
}
.xui-decrementRowSpan-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -144px;
}
.xui-decrementRowSpan-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -192px;
}
.xui-definitionList-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -64px;
}
.xui-definitionList-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -96px;
}
.xui-definitionList-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -128px;
}
.xui-definitionList-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -192px;
}
.xui-definitionList-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -256px;
}
.xui-deleteColumn-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -64px;
}
.xui-deleteColumn-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -96px;
}
.xui-deleteColumn-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -128px;
}
.xui-deleteColumn-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -192px;
}
.xui-deleteColumn-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -256px;
}
.xui-deleteRow-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -64px;
}
.xui-deleteRow-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -96px;
}
.xui-deleteRow-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -128px;
}
.xui-deleteRow-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -192px;
}
.xui-deleteRow-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -256px;
}
.xui-demoteListItem-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -64px;
}
.xui-demoteListItem-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -96px;
}
.xui-demoteListItem-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -128px;
}
.xui-demoteListItem-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -192px;
}
.xui-demoteListItem-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -256px;
}
.xui-down-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -64px;
}
.xui-down-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -96px;
}
.xui-down-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -128px;
}
.xui-down-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -192px;
}
.xui-down-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -256px;
}
.xui-editDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -64px;
}
.xui-editDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -96px;
}
.xui-editDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -128px;
}
.xui-editDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -192px;
}
.xui-editDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -256px;
}
.xui-editDocuments-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -64px;
}
.xui-editDocuments-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -96px;
}
.xui-editDocuments-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -128px;
}
.xui-editDocuments-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -192px;
}
.xui-editDocuments-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -256px;
}
.xui-figure-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -64px;
}
.xui-figure-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -96px;
}
.xui-figure-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -128px;
}
.xui-figure-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -192px;
}
.xui-figure-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -256px;
}
.xui-footnote-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -64px;
}
.xui-footnote-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -96px;
}
.xui-footnote-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -128px;
}
.xui-footnote-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -192px;
}
.xui-footnote-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -256px;
}
.xui-formatTable-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -64px;
}
.xui-formatTable-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -96px;
}
.xui-formatTable-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -128px;
}
.xui-formatTable-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -192px;
}
.xui-formatTable-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -256px;
}
.xui-heading-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -80px;
}
.xui-heading-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -120px;
}
.xui-heading-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -160px;
}
.xui-heading-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -240px;
}
.xui-heading-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -320px;
}
.xui-incrementColumnSpan-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -80px;
}
.xui-incrementColumnSpan-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -120px;
}
.xui-incrementColumnSpan-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -160px;
}
.xui-incrementColumnSpan-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -240px;
}
.xui-incrementColumnSpan-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -320px;
}
.xui-incrementRowSpan-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -80px;
}
.xui-incrementRowSpan-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -120px;
}
.xui-incrementRowSpan-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -160px;
}
.xui-incrementRowSpan-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -240px;
}
.xui-incrementRowSpan-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -320px;
}
.xui-insertColumnAfter-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -80px;
}
.xui-insertColumnAfter-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -120px;
}
.xui-insertColumnAfter-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -160px;
}
.xui-insertColumnAfter-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -240px;
}
.xui-insertColumnAfter-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -320px;
}
.xui-insertColumnBefore-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -80px;
}
.xui-insertColumnBefore-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -120px;
}
.xui-insertColumnBefore-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -160px;
}
.xui-insertColumnBefore-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -240px;
}
.xui-insertColumnBefore-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -320px;
}
.xui-insertRowAfter-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -80px;
}
.xui-insertRowAfter-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -120px;
}
.xui-insertRowAfter-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -160px;
}
.xui-insertRowAfter-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -240px;
}
.xui-insertRowAfter-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -320px;
}
.xui-insertRowBefore-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -80px;
}
.xui-insertRowBefore-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -120px;
}
.xui-insertRowBefore-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -160px;
}
.xui-insertRowBefore-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -240px;
}
.xui-insertRowBefore-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -320px;
}
.xui-italic-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -80px;
}
.xui-italic-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -120px;
}
.xui-italic-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -160px;
}
.xui-italic-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -240px;
}
.xui-italic-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -320px;
}
.xui-itemizedList-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -80px;
}
.xui-itemizedList-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -120px;
}
.xui-itemizedList-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -160px;
}
.xui-itemizedList-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -240px;
}
.xui-itemizedList-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -320px;
}
.xui-left-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -80px;
}
.xui-left-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -120px;
}
.xui-left-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -160px;
}
.xui-left-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -240px;
}
.xui-left-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -320px;
}
.xui-link-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -96px;
}
.xui-link-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -144px;
}
.xui-link-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -192px;
}
.xui-link-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -288px;
}
.xui-link-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -384px;
}
.xui-list-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -96px;
}
.xui-list-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -144px;
}
.xui-list-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -192px;
}
.xui-list-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -288px;
}
.xui-list-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -384px;
}
.xui-media-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -96px;
}
.xui-media-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -144px;
}
.xui-media-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -192px;
}
.xui-media-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -288px;
}
.xui-media-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -384px;
}
.xui-menu-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -96px;
}
.xui-menu-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -144px;
}
.xui-menu-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -192px;
}
.xui-menu-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -288px;
}
.xui-menu-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -384px;
}
.xui-note-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -96px;
}
.xui-note-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -144px;
}
.xui-note-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -192px;
}
.xui-note-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -288px;
}
.xui-note-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -384px;
}
.xui-orderedList_aa-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -96px;
}
.xui-orderedList_aa-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -144px;
}
.xui-orderedList_aa-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -192px;
}
.xui-orderedList_aa-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -288px;
}
.xui-orderedList_aa-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -384px;
}
.xui-orderedList_A-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -96px;
}
.xui-orderedList_A-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -144px;
}
.xui-orderedList_A-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -192px;
}
.xui-orderedList_A-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -288px;
}
.xui-orderedList_A-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -384px;
}
.xui-orderedList_ii-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -96px;
}
.xui-orderedList_ii-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -144px;
}
.xui-orderedList_ii-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -192px;
}
.xui-orderedList_ii-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -288px;
}
.xui-orderedList_ii-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -384px;
}
.xui-orderedList_I-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -96px;
}
.xui-orderedList_I-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -144px;
}
.xui-orderedList_I-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -192px;
}
.xui-orderedList_I-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -288px;
}
.xui-orderedList_I-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -384px;
}
.xui-orderedList-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -96px;
}
.xui-orderedList-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -144px;
}
.xui-orderedList-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -192px;
}
.xui-orderedList-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -288px;
}
.xui-orderedList-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -384px;
}
.xui-paragraph-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -112px;
}
.xui-paragraph-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -168px;
}
.xui-paragraph-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -224px;
}
.xui-paragraph-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -336px;
}
.xui-paragraph-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -448px;
}
.xui-pasteAfter-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -112px;
}
.xui-pasteAfter-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -168px;
}
.xui-pasteAfter-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -224px;
}
.xui-pasteAfter-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -336px;
}
.xui-pasteAfter-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -448px;
}
.xui-pasteBefore-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -112px;
}
.xui-pasteBefore-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -168px;
}
.xui-pasteBefore-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -224px;
}
.xui-pasteBefore-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -336px;
}
.xui-pasteBefore-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -448px;
}
.xui-plainText-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -112px;
}
.xui-plainText-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -168px;
}
.xui-plainText-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -224px;
}
.xui-plainText-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -336px;
}
.xui-plainText-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -448px;
}
.xui-pre-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -112px;
}
.xui-pre-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -168px;
}
.xui-pre-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -224px;
}
.xui-pre-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -336px;
}
.xui-pre-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -448px;
}
.xui-preview-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -112px;
}
.xui-preview-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -168px;
}
.xui-preview-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -224px;
}
.xui-preview-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -336px;
}
.xui-preview-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -448px;
}
.xui-promoteListItem-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -112px;
}
.xui-promoteListItem-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -168px;
}
.xui-promoteListItem-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -224px;
}
.xui-promoteListItem-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -336px;
}
.xui-promoteListItem-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -448px;
}
.xui-right-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -112px;
}
.xui-right-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -168px;
}
.xui-right-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -224px;
}
.xui-right-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -336px;
}
.xui-right-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -448px;
}
.xui-row-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -112px;
}
.xui-row-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -168px;
}
.xui-row-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -224px;
}
.xui-row-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -336px;
}
.xui-row-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -448px;
}
.xui-section-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -112px;
}
.xui-section-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -168px;
}
.xui-section-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -224px;
}
.xui-section-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -336px;
}
.xui-section-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -448px;
}
.xui-show_level-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: 0px -128px;
}
.xui-show_level-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: 0px -192px;
}
.xui-show_level-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: 0px -256px;
}
.xui-show_level-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: 0px -384px;
}
.xui-show_level-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: 0px -512px;
}
.xui-sortRows-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -16px -128px;
}
.xui-sortRows-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -24px -192px;
}
.xui-sortRows-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -32px -256px;
}
.xui-sortRows-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -48px -384px;
}
.xui-sortRows-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -64px -512px;
}
.xui-table-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -32px -128px;
}
.xui-table-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -48px -192px;
}
.xui-table-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -64px -256px;
}
.xui-table-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -96px -384px;
}
.xui-table-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -128px -512px;
}
.xui-typewriter-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -48px -128px;
}
.xui-typewriter-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -72px -192px;
}
.xui-typewriter-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -96px -256px;
}
.xui-typewriter-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -144px -384px;
}
.xui-typewriter-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -192px -512px;
}
.xui-underline-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -64px -128px;
}
.xui-underline-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -96px -192px;
}
.xui-underline-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -128px -256px;
}
.xui-underline-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -192px -384px;
}
.xui-underline-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -256px -512px;
}
.xui-up-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -80px -128px;
}
.xui-up-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -120px -192px;
}
.xui-up-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -160px -256px;
}
.xui-up-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -240px -384px;
}
.xui-up-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -320px -512px;
}
.xui-viewDocument-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -96px -128px;
}
.xui-viewDocument-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -144px -192px;
}
.xui-viewDocument-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -192px -256px;
}
.xui-viewDocument-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -288px -384px;
}
.xui-viewDocument-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -384px -512px;
}
.xui-viewDocuments-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -112px -128px;
}
.xui-viewDocuments-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -168px -192px;
}
.xui-viewDocuments-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -224px -256px;
}
.xui-viewDocuments-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -336px -384px;
}
.xui-viewDocuments-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -448px -512px;
}
.xui-paste_from_word-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -128px -128px;
}
.xui-paste_from_word-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -192px -192px;
}
.xui-paste_from_word-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -256px -256px;
}
.xui-paste_from_word-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -384px -384px;
}
.xui-paste_from_word-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -512px -512px;
}
.xui-fallback-16 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 160px 144px;
background-position: -144px -128px;
}
.xui-fallback-24 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 240px 216px;
background-position: -216px -192px;
}
.xui-fallback-32 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-position: -288px -256px;
}
.xui-fallback-48 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 480px 432px;
background-position: -432px -384px;
}
.xui-fallback-64 {
background-image: url(xuiediticons.png);
background-repeat: no-repeat;
background-size: 640px 576px;
background-position: -576px -512px;
}
@font-face {
font-family: "xui-stock-icons";
src: url(xuistockicons.woff) format("woff");
font-weight: normal;
font-style: normal;
}
body {
--xui-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif;
--xui-mono-family: ui-monospace, "Cascadia Code", "Source Code Pro", Menlo, Consolas, "DejaVu Sans Mono", monospace;
--xui-font-size: 14px;
--xui-large-font-size: 16px;
--xui-small-font-size: 12px;
--xui-fg: #323232;
--xui-bg: #FCFCFC;
--xui-border-color: #C0C2C4;
--xui-border-width: 1px;
--xui-disabled-color: #A6A6A6;
--xui-link-color: navy;
--xui-hint-color: #5980B3;
--xui-read-only-color: var(--xui-hint-color);
--xui-highlight-color: #3DAEE9;
--xui-focus-color: #CDF5FD;
--xui-icon-color: var(--xui-fg);
--xui-small-icon-size: 16px;
--xui-medium-icon-size: 24px;
--xui-large-icon-size: 32px;
--xui-neutral-color: #428BCA;
--xui-negative-color: #D9534F;
--xui-positive-color: #7ED321;
--xui-information-color: #FFE135;
--xui-error-color: red;
--xui-warning-color: orange;
--xui-question-color: blue;
--xui-disabled-fg: var(--xui-disabled-color);
--xui-disabled-bg: #EBEBEB;
--xui-rollover-fg: var(--xui-fg);
--xui-rollover-bg: #EBEBEB;
--xui-heading-fg: var(--xui-fg);
--xui-heading-bg: #F4F4F4;
--xui-selected-fg: white;
--xui-selected-bg: #3DAEE9;
--xui-checked-fg: var(--xui-fg);
--xui-checked-bg: #BBCCDD;
}
div[data-xui-glass] {
position: absolute;
top: 0px;
left: 0px;
}
div[data-xui-dialog]:focus {
outline-style: none;
cursor: default;
}
.xui-control {
font-family: var(--xui-font-family);
font-size: var(--xui-font-size);
color: var(--xui-fg);
background-color: var(--xui-bg);
border-color: var(--xui-border-color);
border-width: var(--xui-border-width);
}
.xui-small-icon {
font-family: "xui-stock-icons";
font-size: var(--xui-small-icon-size);
font-weight: normal;
font-style: normal;
text-decoration: none;
color: var(--xui-icon-color);
}
[disabled].xui-control-disabled,
[disabled].xui-control-disabled *,
.xui-control-disabled .xui-small-icon,
.xui-control-disabled {
color: var(--xui-disabled-color);
}
.xui-control-disabled img,
.xui-control-disabled .xui-edit-icon-16,
.xui-control-disabled .xui-edit-icon-24,
.xui-control-disabled .xui-edit-icon-32,
.xui-control-disabled .xui-edit-icon-48,
.xui-control-disabled .xui-edit-icon-64 {
filter: grayscale(100%) contrast(50%);
}
.xui-menu {
user-select: none;
border-style: solid;
}
.xui-menu-row {
display: flex;
user-select: none;
}
.xui-menu-row > * {
flex: 1 0 auto;
}
.xui-menu-item {
display: flex;
align-items: baseline;
padding: 0.33em 0.66em;
}
.xui-menu-item:not(.xui-control-disabled):hover {
color: var(--xui-rollover-fg);
background-color: var(--xui-rollover-bg);
}
.xui-menu-item-icon {
flex: 0 0 auto;
width: var(--xui-small-icon-size);
}
.xui-menu-item-text {
flex: 1 0 auto;
padding-left: 0.66em;
}
.xui-menu-item-detail {
flex: 0 0 auto;
color: var(--xui-hint-color);
font-size: 0.83em;
padding-left: 1.6em;
}
.xui-control-disabled .xui-menu-item-text,
.xui-control-disabled .xui-menu-item-detail {
color: var(--xui-disabled-color);
}
.xui-menu-item.xui-icon-only > .xui-menu-item-icon {
flex-grow: 1;
text-align: center;
}
.xui-menu-item.xui-icon-only > .xui-menu-item-text,
.xui-menu-item.xui-icon-only > .xui-menu-item-detail {
display: none;
}
.xui-dialog {
border-style: solid;
border-radius: 4px;
box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
}
.xui-dlg {
}
.xui-dlg-container {
display: flex;
flex-direction: column;
}
.xui-dlg-resizable {
overflow: auto;
resize: both;
}
.xui-dlg-title-pane,
.xui-dlg-button-pane {
user-select: none;
flex: none;
display: flex;
flex-wrap: wrap;
border-color: var(--xui-border-color);
border-width: var(--xui-border-width);
color: var(--xui-heading-fg);
background-color: var(--xui-heading-bg);
padding: 5px;
}
.xui-dlg-title-pane > *,
.xui-dlg-button-pane > * {
margin: 5px;
}
.xui-dlg-title-pane {
border-bottom-style: solid;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
cursor: default;
}
.xui-dlg-button-pane {
border-top-style: solid;
border-bottom-left-radius: 4px;
border-bottom-right-radius: 4px;
}
.xui-dlg-button-default-layout {
justify-content: flex-end;
}
.xui-dlg-content-pane {
flex: 1 1 auto;
overflow: auto;
}
.xui-dlg-title {
flex: 1 1 auto;
font-weight: 500;
}
.xui-dlg-close-button {
flex: none;
}
.xui-dlg-button {
flex: none;
font-weight: 500;
border-style: solid;
border-radius: 4px;
padding: 4px 8px;
min-width: 6em;
}
.xui-dlg-default-button {
border-width: calc(2 * var(--xui-border-width));
border-color: var(--xui-highlight-color);
padding: 3px 7px;
}
.xui-dlg-button-separ {
flex: 1 1 auto;
width: 1em;
height: 1em;
min-width: 1em;
}
.xui-dlg-close-button:hover,
.xui-dlg-button:hover {
background-color: var(--xui-rollover-bg);
border-color: var(--xui-border-color);
}
.xui-dlg-button:focus {
outline: solid var(--xui-focus-color) calc(2 * var(--xui-border-width));
}
.xui-alert-pane {
display: flex;
padding: 10px;
cursor: default;
}
.xui-alert-icon {
flex: none;
font-family: "xui-stock-icons";
font-size: 32px;
width: 32px;
color: var(--xui-icon-color);
}
.xui-alert-error-icon {
color: var(--xui-error-color);
}
.xui-alert-warning-icon {
color: var(--xui-warning-color);
}
.xui-alert-info-icon {
color: var(--xui-information-color);
}
.xui-alert-confirm-icon {
color: var(--xui-question-color);
}
.xui-alert-message {
flex: 1 1 auto;
min-width: 20em;
min-height: 2em;
max-width: 60em;
max-height: 30em;
overflow: auto;
margin-left: 10px;
}
.xui-prompt-pane {
display: flex;
padding: 10px;
cursor: default;
}
.xui-prompt-icon {
flex: none;
font-family: "xui-stock-icons";
font-size: 32px;
color: var(--xui-question-color);
width: 32px;
}
.xui-prompt-form {
flex: 1 1 auto;
overflow: auto;
margin-left: 10px;
}
.xui-prompt-question {
margin-bottom: calc(10px - (2 * var(--xui-border-width)));
}
.xui-prompt-input {
display: block;
border-color: var(--xui-border-color);
border-width: var(--xui-border-width);
border-style: solid;
margin: calc(2 * var(--xui-border-width));
}
.xui-prompt-hint {
font-size: var(--xui-small-font-size);
color: var(--xui-hint-color);
margin-top: calc(10px - (2 * var(--xui-border-width)));
}
.xui-prompt-input:focus {
outline: solid var(--xui-focus-color) calc(2 * var(--xui-border-width));
}
xui-list {
user-select: none;
display: block;
border-style: solid;
height: 10em;
overflow: auto;
}
xui-list:focus {
outline: solid var(--xui-focus-color) calc(2 * var(--xui-border-width));
}
.xui-lst-item {
white-space: pre;
padding: 0.25em;
}
.xui-lst-item0 {
background-color: #FFFFFF;
}
.xui-lst-item1 {
background-color: #FFFFE0;
}
.xui-lst-disabled {
color: var(--xui-disabled-color);
}
.xui-lst-selected {
color: var(--xui-selected-fg);
background-color: var(--xui-selected-bg);
}
.xui-lst-anchor {
border-color: var(--xui-border-color);
border-width: var(--xui-border-width);
border-style: solid;
}
xui-list:focus > .xui-lst-anchor {
border-color: var(--xui-selected-bg);
}
xui-autocomplete-field {
display: block;
}
.xui-acfld-field {
border-style: solid;
padding: calc(2 * var(--xui-border-width));
box-sizing: border-box;
width: 100%;
}
.xui-acfld-field:focus {
outline: solid var(--xui-focus-color) calc(2 * var(--xui-border-width));
}
.xui-item-chooser-pane {
display: flex;
flex-direction: column;
height: 100%;
}
.xui-itmchsr-field-pane {
flex: none;
display: flex;
flex-wrap: wrap;
align-items: center;
}
button.xui-itmchsr-cancel,
button.xui-itmchsr-ok {
flex: none;
width: calc(2 * var(--xui-small-icon-size));
border-style: solid;
border-radius: 4px;
padding: 2px 4px;
margin: 5px 0 5px 5px;
}
.xui-itmchsr-cancel {
color: var(--xui-negative-color);
}
.xui-itmchsr-ok {
color: var(--xui-positive-color);
}
xui-autocomplete-field.xui-itmchsr-field {
flex: 1 1 auto;
margin: 5px;
}
xui-list.xui-itmchsr-list {
flex: 1 1 auto;
margin: 0 5px 5px 5px;
}
.xui-itmchsr-cancel:hover,
.xui-itmchsr-ok:hover {
background-color: var(--xui-rollover-bg);
border-color: var(--xui-border-color);
}
.xui-itmchsr-cancel:focus,
.xui-itmchsr-ok:focus {
outline: solid var(--xui-focus-color) calc(2 * var(--xui-border-width));
}
`;

let xuiStylesheet;

export function getXuiStylesheet() {
    if ( !xuiStylesheet ) {
        xuiStylesheet = new CSSStyleSheet();
        xuiStylesheet.replaceSync(XUI_STYLES);
    }
    return xuiStylesheet;
}
