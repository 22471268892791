import React, { Component, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Tooltip from '@mui/material/Tooltip';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import { UserInfo, netGet, netPost, netPostExt, netFetch, keycloak } from "../../network";

import * as XUI from '../xxeclient/xui.js';
import * as XXE from '../xxeclient/xxeclient.js';


import * as DB from '../xxeclient/docbook.js';
import '../xxeclient/xui.css';
import '../xxeclient/xxeclient.css';
import '../xxeclient/docbook.css';


// const LazyEditor = React.lazy(() => import('../xxeclient/xxeclient.js'));


const SAMPLE1 = 
`<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE article PUBLIC "-//OASIS//DTD DocBook XML V4.5//EN"
"http://www.oasis-open.org/docbook/xml/4.5/docbookx.dtd">
<article lang="en-US" status="draft">
  <title>DocBook Table Support</title>

  <sect1 id="examples">
    <title>Examples found in <citetitle>DocBook: The Definitive
    Guide</citetitle></title>

    <table frame="all">
      <title>Sample Table</title>

      <tgroup align="left" cols="5" colsep="1" rowsep="1">
        <colspec colname="c1"/>

        <colspec colname="c2"/>

        <colspec colname="c3"/>

        <colspec colname="c5" colnum="5" colwidth="2*"/>

        <spanspec align="center" nameend="c2" namest="c1" spanname="hspan"/>

        <spanspec align="center" nameend="c3" namest="c2" spanname="bspan"/>

        <thead>
          <row>
            <entry spanname="hspan">Horizontal Span</entry>

            <entry>a3</entry>

            <entry>a4</entry>

            <entry>a5</entry>
          </row>
        </thead>

        <tfoot>
          <row>
            <entry>f1</entry>

            <entry>f2</entry>

            <entry>f3</entry>

            <entry>f4</entry>

            <entry>f5</entry>
          </row>
        </tfoot>

        <tbody>
          <row>
            <entry>b1</entry>

            <entry>b2</entry>

            <entry>b3</entry>

            <entry>b4</entry>

            <entry morerows="1" valign="middle"><para><!--Pernicous Mixed Content-->
            Vertical Span</para></entry>
          </row>

          <row>
            <entry>c1</entry>

            <entry morerows="1" spanname="bspan" valign="bottom">Span
            Both</entry>

            <entry>c4</entry>
          </row>

          <row>
            <entry>d1</entry>

            <entry>d4</entry>

            <entry>d5</entry>
          </row>
        </tbody>
      </tgroup>
    </table>

    <informaltable frame="none">
      <tgroup cols="2">
        <colspec colwidth="0.5in"/>

        <colspec colwidth="0.5in"/>

        <tbody>
          <row>
            <entry>1</entry>

            <entry>1</entry>
          </row>

          <row>
            <entry>2</entry>

            <entry>4</entry>
          </row>

          <row>
            <entry>3</entry>

            <entry>9</entry>
          </row>
        </tbody>
      </tgroup>
    </informaltable>
  </sect1>

</article>
`;



const SAMPLE3 =
`<?xml version="1.0" encoding="utf-8"?>
<supplement>
  <header>
    <model>B787</model>
    <applicableTo>FCOM</applicableTo>
    <number>B787 24/19</number>
    <cancelling>N/A</cancelling>
    <effectiveDate>September 13, 2024</effectiveDate>
  </header>
  <title>Leading Edge Outboard Slat Actuator Upgrade</title>
  <sectionLevel1>
    <title>Purpose</title>
    <para>To advise flight crew that aircraft upgraded with new leading edge
    outboard slat lockout actuators (LEOLAs) no longer require the flaps and
    slats to remain extended after landing under certain conditions.</para>
  </sectionLevel1>
  <sectionLevel1>
    <title>Background</title>
    <para>An FAA AD was issued after several leading edge outboard slat geared
    rotary actuators (GRAs) failed after the flaps were retracted with ice under
    the leading edge slats.</para>
  </sectionLevel1>
  <sectionLevel1>
    <title>Aircraft upgrades</title>
    <para>LE OB slat GRAs will progressively be upgraded with a lockout actuator
    during scheduled maintenance.</para>
  </sectionLevel1>
  <sectionLevel1>
    <title>Operations</title>
    <para>Aircraft fitted with the new LEOLA’s will have the following NTC in the
    eLog:</para>
    <mediaObject>
      <image fileref="/graphics/00/00/00/1D/G0000001D4F/MAIN.png"/>
    </mediaObject>
  </sectionLevel1>
  <signatureBlock>
    <SME>
      <signedBy>Philip Kirk</signedBy>
      <title>Boeing Tech Pilot</title>
    </SME>
    <checked>
      <signedBy>Michael Billett</signedBy>
      <title>Senior Fleet Manager B787/B777</title>
    </checked>
    <operationalReview>
      <signedBy>Andrew Hanley</signedBy>
      <title>Senior Manager Aircraft Operations</title>
    </operationalReview>
    <approved>
      <signedBy>Hugh Pearce</signedBy>
      <title>Head of Operations</title>
    </approved>
  </signatureBlock>
</supplement>
`;

/* http://localhost:3000/api/fs/graphics/00/00/00/1D/G0000001D4F/MAIN.png */

const TEMPLATE1 =
`<?xml version="1.0" encoding="utf-8"?>
<supplement>
  <header>
    <model></model>
    <applicableTo></applicableTo>
    <number></number>
    <cancelling>N/A</cancelling>
    <effectiveDate></effectiveDate>
  </header>
  <title></title>
  <sectionLevel1>
    <title></title>
    <para></para>
  </sectionLevel1>
  
  <signatureBlock>
    <SME>
      <signedBy></signedBy>
      <title></title>
    </SME>
    <checked>
      <signedBy></signedBy>
      <title></title>
    </checked>
    <operationalReview>
      <signedBy></signedBy>
      <title></title>
    </operationalReview>
    <approved>
      <signedBy></signedBy>
      <title></title>
    </approved>
  </signatureBlock>
</supplement>
`;

function MediaFileChooser(props) {
  const {
    open,
    onCancel,
    onSave,
    title='Choose Graphics',
    ...other
  } = props;

  const [sizes, setSizes] = useState([100, '25%', 'auto']);
  // const [url, setUrl] = useState('');
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [categSelection, setCategSelection] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();

  useEffect( () => {
    if ( open ) {
      netGet('/api/catalog/categ')
        .then( response => response.json() )
        .then( categs => {
          if ( Array.isArray(categs) ) {
            setCategories(categs);
            // console.log('Media categoeries: ' + JSON.stringify(categs));
          }
        }).catch( error => console.log('Failed to retrieve categories: ' + error));
      const cs = [...categSelection];
      const cat = cs.map(i => categories[i]).reduce((a, b) => a.length > 0 ? a + ',' + b.name : b.name, '');
      netGet('/api/catalog/image'  + (cs.length > 0 ? '?cat=' + encodeURIComponent(cat) : '') )
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            setImages(data);
          }
        }).catch(error => console.log('Failed to retrieve image info: ' + error));
      setSelectedIndex(-1);
      setSelectedImage(null);
      // setCategSelection([]);
    }

  },[open]);

  const onClose = () => {
    onCancel();
  };

  const onCancelClick = () => {
    if ( typeof onCancel === 'function') {
      onCancel();
    }
  };

  const onOKClick = () => {
    if ( typeof onSave === 'function') {
      // onSave(url);
      const url = selectedImage ? selectedImage.path + '/MAIN' + getImageExtension(selectedImage) : null;
      onSave(url, selectedImage);
    }
  };

  const handleCategClick = (categ, index) => {
    const cs = [...categSelection];
    if ( cs.includes(index) ) {
      const j = cs.findIndex( item => item === index);
      if ( j >= 0 ) {
        cs.splice(j, 1);
      }
    } else {
      cs.push(index);
    }
    setCategSelection(cs);
    setSelectedImage(null);
    setSelectedIndex(-1);
    const cat = cs.map(i => categories[i]).reduce((a, b) => a.length > 0 ? a + ',' + b.name : b.name, '');
    netGet('/api/catalog/image' + (cs.length > 0 ? '?cat=' + encodeURIComponent(cat) : ''))
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setImages(data);
        }
      }).catch(error => console.log('Failed to retrieve image info: ' + error));
  };

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setSelectedIndex(index);
    // console.log('Selected Image: ' + JSON.stringify(image));
  };

  const getImageExtension = (image) => {
    const t = image.type;
    if ( t.includes('png') ) {
      return '.png';
    }
    if ( t.includes('jpeg') ) {
      return '.jpg';
    }
    if ( t.includes('pdf')) {
      return '.pdf';
    }
    if ( t.includes('gif') ) {
      return '.gif';
    }
    if ( t.includes('svg') ) {
      return '.svg';
    }
  }

  const getImageUrl = (image) => {
    // thumbnail is always PNG
    const url = new URL('/api/fs' + image.path + '/THUMB.png', window.location.href);
    return url.toString();
  }

  return (
    <Dialog
      maxWidth={'1200px'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: 1100,
          minHeight: 800,
          maxHeight: 1000
        }
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
      <DialogContent sx={{}}>
        <Box sx={{width: '100%', height: '100%', position: 'relative', display: 'grid', gridTemplateRows: '2em 1fr' }}>
          <Box sx={{paddingLeft: 2}}>
            <Typography sx={{fontWeight: 'bold'}}>Category</Typography>
          </Box>
          <SplitPane
            style={{minHeight: '600px', height: '100%'}}
            split='vertical'
            sizes={sizes}
            onChange={setSizes}
          >
            <Pane minSize={40} maxSize='60%'>
              <Box sx={{ height: '100%', position: 'relative', overflow: 'auto' }}>
                <List sx={{padding: 0}} >
                  {Array.isArray(categories) && categories.map((categ, index) =>
                    <ListItem key={`cat-categ-${index}`} disablePadding disableGutters
                      >
                      <ListItemButton onClick={() => handleCategClick(categ, index)} dense disableGutters
                        selected={categSelection.includes(index)} >
                        <ListItemText primary={categ.name} sx={{margin: 0, whiteSpace: 'nowrap'}}/>
                      </ListItemButton>

                    </ListItem>
                  )}
                </List>
              </Box>
            </Pane>
            <Box sx={{
              height: '100%', position: 'relative', overflow: 'auto',
              borderLeftColor: theme.palette.divider, borderLeftStyle: 'solid', borderLeftWidth: '2px'
            }} >
              <ImageList sx={{ width: 500, height: 490, padding: 3, overflow: 'visible' }} cols={7} gap={10}>
                {Array.isArray(images) && images.filter( (item,index) => index < 128).map((item,index) => (
                  <Tooltip title={
                    <Box>
                      <Typography sx={{fontWeight: 'bold'}}>{item.name}</Typography>
                      <Typography>{item.label}</Typography>
                    </Box>
                  }>
                  <ImageListItem key={item.name} sx={{width: '92px', height: '104px', maxHeight: '130px'}} 
                    >
                      <IconButton onClick={() => handleImageClick(item, index)} 
                        sx={{background: selectedIndex === index ? 'gray' : 'white', borderRadius: 0}} >
                    <img
                      style={{height: '92px', width: '92px', maxHeight: '92px', opacity: selectedIndex === index ? 0.6 : 1}}
                      src={getImageUrl(item)}
                      alt={item.label}
                      loading="lazy"
                    />
                    </IconButton>
                    <ImageListItemBar
                      subtitle={item.label}
                      position="below"
                      sx={{fontSize: '8pt', paddingTop: '2px !important', paddingBottom: '2px'}}
                    />
                  </ImageListItem>
                  </Tooltip>
                ))}
              </ImageList>
            </Box>
          </SplitPane>
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{display: 'flex'}}>
          <Box>
            <Box sx={{ paddingLeft: 2, width: '8em', fontWeight: 'bold' }}>Selected:</Box>
            <Box sx={{ width: '8em' }}>{'\xa0'}</Box>
          </Box>
            <Box>
              <Box>{selectedImage && selectedImage.name}</Box>
              <Box>{selectedImage && selectedImage.label}</Box>
            </Box>
        </Box>
        <Box sx={{ display: 'inline' }}>
          <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success">OK</Button>
          <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: 2, minWidth: '7em' }}>Cancel</Button>
        </Box>
      </DialogActions>
    </Dialog>

);


}

var mediaChooserOpened = false;
var mediaChooserUrl = '';
var mediaChooserImage = null;

function ReactXMLEditor(props) {
  const {
    name,
    path,
    ...other
  } = props;
    const [importedComponent, setImportedComponent] = useState(null);
    const [sampleNumber, setSampleNumber] = useState(0);
    const [mediaChooserDialogOpen, setMediaChooserDialogOpen] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [saveNeeded, setSaveNeeded] = useState(false);
    const [opened, setOpened] = useState(false);
    const isFormEnabled = useSelector((state) => state.auth.formEnabled);
    const editor = useRef(null);

    /* serverurl="ws://localhost:18078/xxe/ws" */

    useEffect( () => {
      setOpened(false);
        /*
        const importComponent = async () => {
            const module = await import('/xxe/xxeclient/xxeclient.js');
            //const AnotherComponent = module.default;
            //setImportedComponent(<AnotherComponent />);
          };
        */
        if ( editor.current ) {
            // console.log('XML autorecover = ' + editor.current.autoRecover);
            editor.current.autoRecover = false;
            editor.current.resourceStorage = new UcpStorage(editor.current);
            editor.current.addEventListener('connected', editorConnected);
            editor.current.addEventListener('saveStateChanged', editorSaveChanged);
            if ( editor.current.connected ) {
              openDoc(path);
            } else {
              // console.log('adding event listener...'  + name);
              
              editor.current.connect().catch( error => console.log('error connecting: ' + error));
              
            }
        } else {
            console.log('XML Editor not yet mounted!!!');
        }

    },[path]);

    /*
    useEffect ( () => {
      console.log('Form Enabled = ' + isFormEnabled);
    },[isFormEnabled]);
    */

    const editorConnected = (event) => {
      console.log('editor connected!');
      if ( !opened ) {
        openDoc(path);
      }
    };

    const editorSaveChanged = (event) => {
      setSaveNeeded(event.saveNeeded);
    }

    const openDoc = (path) => {
      if ( editor.current ) {
        netGet('/api/fs' + path)
        .then(response => response.text())
        .then(text => {
            // console.log('text: ' + text);
            setOpened(true);
            setTimeout(() => {
              if ( text.includes('<description') ) {
                closeDocument().then(() => {
                  editor.current.openDocument(TEMPLATE1, new URL(path, window.location.href).toString());
                });
              } else {
                closeDocument().then(() => {
                  editor.current.openDocument(text, new URL(path, window.location.href).toString()).catch( error => {
                    console.log('Error opending document: ' + error);
                    editor.current.openDocument(TEMPLATE1, new URL(path, window.location.href).toString());
                  });
                });
              }
            }, 500);
            
        }).catch(error => console.log('Error opening document ' + error));
      }
    }

    class UcpResource extends XXE.Resource {
      constructor(uri, data) {
        super(uri, data);
      }
    }
    
    class UcpStorage extends XXE.ResourceStorage {

      constructor(xmlEditor) {
        super(xmlEditor);
      }
    
      async loadResource(uri) {
        /*
        let p = uri;
        let url = `/api/fs/${p}`;
        if (uri.startsWith('catalog:/')) {
          p = uri.substring(10);
          url = `/api/fs/catalog/${p}`;
        } else if ( uri.startsWith('media:/') ) {
          p = uri.substring(8);
          url = `/api/fs/${p}`;
        }
        */
        const url = new URL(uri);
        const purl = url.pathname.startsWith('/api/fs/') ? url.pathname : '/api/fs' + url.pathname;

        // console.log('Loading resource: ' + purl);
        const resp = await netGet(purl);
        const data = await resp.blob();
        return new UcpResource(uri, data);
      }
    
    
      async openResource(options) {
        // console.log('openResource options: ' + JSON.stringify(options));
        setMediaChooserDialogOpen(true);
        // this is a bit ugly, but need to wait for the dialog box to do its thing
        mediaChooserOpened = true;
        await untilCondition(_ => mediaChooserOpened == false);
        // console.log('mediaUrl: ' + mediaUrl);
        // console.log('mediaChooserUrl: ' + mediaChooserUrl);
        if ( mediaChooserUrl == null ) {
          // Canceled by user.
          return null;
        }
        let uri = new URL(mediaChooserUrl, window.location.href); // await NewsResourceChooser.showDialog(this._newsItems,options);
        return this.loadResource(uri.toString());
      }
    }

    /*
      Need a better way to sync with React. We need to wait for the result of a dialog box.
      Also issue with reading the state of some variables, need to use globals to assure we have the recent value.
     */
    const untilCondition = (conditionFunction) => {

      const poll = resolve => {
        if(conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 400);
      }
    
      return new Promise(poll);
    }

    const handleMediaChooserSave = (url, image) => {
      setMediaUrl(url);
      setMediaChooserDialogOpen(false);
      mediaChooserUrl = url;
      mediaChooserImage = image;
      mediaChooserOpened = false;
    }

    const handleMediaChooserCancel = () => {
      setMediaUrl(null);
      setMediaChooserDialogOpen(false);
      mediaChooserUrl = null;
      mediaChooserOpened = false;
    }
    
    const closeDocument = () => {
      if ( !editor.current?.documentIsOpened) {
        return Promise.resolve(true);
      } else {
        return editor.current.closeDocument(/*discardChanges*/ true);
      }
    };

    const handleLoad = (sample) => {
      /* it doesn't matter, it will auto-connect if not connected yet
        if ( editor.current?.connected ) {
            console.log('editor is connected...');
            
        } else {
            console.log('editor is not connected...');
        }
      */
      closeDocument().then(() => {
        if (sample === 1) {
          editor.current.openDocument(SAMPLE1, new URL('/sample1.xml', window.location.href).toString());
        } else if (sample === 2) {
          // editor.current.openDocument(SAMPLE2, new URL('/sample2.xml', window.location.href).toString());
        } else if (sample === 3) {
          editor.current.openDocument(SAMPLE3, new URL('/sample3.xml', window.location.href).toString());
        }
      });
    }

    const handleSave = () => {
      if ( editor.current ) {
        editor.current.getDocument().then(src => {
            netPostExt('/api/doc/save/' + encodeURIComponent(path), src, { 'Content-Type': 'text/xml'})
              .then(response => {
                if ( response.ok ) {
                  console.log('document saved correctly');
                  const saved = editor.current.saveDocument();
                  setSaveNeeded(!saved);
                }
              }).catch(error => console.log('Error saving document:' + error));
        });
        
      }
    };

    /* serverurl="ws://localhost:18078/xxe/ws" */

    return (
      <Box sx={{ height: '100%'}}>
        <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: '3em 1fr' }}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 1, paddingLeft: 2}}>
            <Typography sx={{fontWeight: 'bold'}}>{name}</Typography>
            <Button onClick={() => handleSave()} disabled={!saveNeeded}>Save</Button>
          </Box>
          {isFormEnabled ?
            <Paper component="xxe-client" elevation="0" ref={editor} sx={{ height: '100%', position: 'relative' }}
              serverurl="ws://localhost:18078/xxe/ws" >
            </Paper> :
            <Paper component="xxe-client" elevation="0" ref={editor} sx={{ height: '100%', position: 'relative' }} >
            </Paper>}
        </Box>
        <MediaFileChooser open={mediaChooserDialogOpen} onCancel={handleMediaChooserCancel} onSave={handleMediaChooserSave} />
      </Box>
    );
}

export { ReactXMLEditor };