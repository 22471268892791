import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function FileListItem(props) {
    const {
        name,
        onDelete,
        onClick,
        selected=false,
        ...other
    } = props;

    return (
        <ListItem>
            <Chip label={name} icon={<UploadFileIcon />} variant={selected ? 'filled' : 'outlined'} sx={{ maxWidth: 420 }} onDelete={onDelete} onClick={onClick}/>
        </ListItem>
    );
}