import React, { Component, useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReplayIcon from '@mui/icons-material/Replay';
import OutputIcon from '@mui/icons-material/Output';
import LockIcon from '@mui/icons-material/Lock';

import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { Edit } from "@mui/icons-material";
import { DownloadProfiles } from "./management-download-profiles";



function EditRuleDialog(props) {
    const {
        rules,
        ruleIndex,
        open,
        onSave,
        onCancel,
        title,
        create,
        ...other
    } = props;

    const [rule, setRule] = React.useState({'xpath': '', 'regex': '', 'action': 'TOGETHER', 'group': 0});
    const [index, setIndex] = React.useState(0);
    const [xpathValue, setXpathValue] = React.useState('');
    const [xpathChanged, setXpathChanged] = React.useState(false);
    const [changed,setChanged] = React.useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    React.useEffect( () => {
        if (rules && ruleIndex >= 0) {
            const ir = Array.isArray(rules) && ruleIndex < rules.length && rules.length > 0 ? rules[ruleIndex] : undefined ;
            if (ir) {
                const r = {
                    'xpath': ir.xpath,
                    'regex': ir.regex,
                    'action': ir.action ? ir.action : 'TOGETHER',
                    'group': ir.group ? Number(ir.group) : 0,
                };
                if (r.group === NaN) {
                    r.group = 0;
                }
                setRule(r);
            }
        }
        if ( create ) {
            const i = rules ? rules.length : 0;
            setIndex(i);
            setRule({'xpath': '', 'regex': '', 'action': 'TOGETHER', 'group': 0});
        } else {
            setIndex(ruleIndex);
        }
    }, [rules,ruleIndex,open]);

    const onXpathChanged = (event) => {
        setChanged(true);
        const r = { ...rule};
        r.xpath = event.target.value;
        setRule(r);
    }

    const onRegexChanged = (event) => {
        setChanged(true);
        const r = { ...rule};
        r.regex = event.target.value;
        setRule(r);
    };

    const onGroupChanged = (event) => {
        setChanged(true);
        const r = { ...rule};
        r.group = Number(event.target.value);
        if ( r.group === NaN ) {
            r.group = 0;
        }
        setRule(r);
    };

    const handleActionChange = (event) => {
        setChanged(true);
        const r = { ...rule};
        r.action = event.target.value;
        setRule(r);
    };

    const onOKClick = () => {
        if ( typeof onSave === 'function' ) {
            const r = { ...rule};
            r.group = Number(r.group);
            if ( r.group === NaN ) {
                r.group = 0;
            }
            onSave(r);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if ( typeof onCancel === 'function' ) {
            onCancel();
        }
    };

    const handleExpressionChange = (event) => {
        console.log('expression: ' + event.target.value);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                  minWidth: '500px',
                  width: '900px',
                  maxWidth: '900px',
                  maxHeight: '900px',
                  height: 600
                }
              }}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{paddingTop: '3em', whiteSpace: 'nowrap'}}>
                    <Typography sx={{display: 'inline-block', width: '5em', fontWeight: 'bold'}}>No: </Typography>
                    <Typography sx={{display: 'inline-block'}}>{index}</Typography>
                </Box>
                <Box sx={{paddingTop: '3ex', display: 'flex', alignItems: 'top'}}>
                    <Typography sx={{ fontWeight: 'bold', width: '5em' }}>XPath:</Typography>
                    <TextField value={rule.xpath} onChange={onXpathChanged} size="small" fullWidth
                       multiline rows={2} />
                </Box>
                <Box sx={{paddingTop: '3ex', display: 'flex', alignItems: 'top'}}>
                    <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Regex:</Typography>
                    <TextField value={rule.regex} onChange={onRegexChanged} size="small" fullWidth
                       multiline rows={2} />
                </Box>
                <Box sx={{paddingTop: '3ex', display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Group:</Typography>
                    <TextField value={rule.group} onChange={onGroupChanged} size="small" fullWidth
                       inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Action:</Typography>

                    <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 3em)' }} size="small">
                        <Select
                            value={rule.action}
                            onChange={handleActionChange}
                            sx={{ minWidth: '20em', maxWidth: '100%' }}
                            MenuProps={MenuProps}
                        >
                            {['TOGETHER', 'WITH-PREVIOUS', 'WITH-NEXT'].map((cmd) => (
                                <MenuItem
                                    key={cmd}
                                    value={cmd}
                                >
                                    {cmd}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)'}}>
                <Button variant="outlined" onClick={onOKClick} sx={{minWidth: '7em'}}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{minWidth: '7em'}}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

function a11yProps(index) {
    return {
      id: `docs-config-tab-${index}`,
      'aria-controls': `docs-config-tabpanel-${index}`,
    };
  }

function DocumentConfigEdit(props) {
    const {
        doc,
        onChange,
        documentIndex,
        documentList,
        saveEnabled,
        readOnly,
        ...other
    } = props;

    const [rules, setRules] = React.useState([]);
    const [serial, setSerial] = React.useState(0);
    const [configKey, setConfigKey] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [saveDocEnabled, setSaveDocEnabled] = React.useState(false);
    const [addRuleEnabled, setAddRuleEnabled] = React.useState(true);
    const [editRuleEnabled, setEditRuleEnabled] = React.useState(false);
    const [deleteRuleEnabled, setDeleteRuleEnabled] = React.useState(false);
    const [editRuleDialogOpen, setEditRuleDialogOpen] = React.useState(false);
    const [editRuleCreateMode, setEditRuleCreateMode] = React.useState(true);
    const [dialogTitle, setDialogTitle] = useState('Add Rule');
    const [tabIndex, setTabIndex] = useState(0);

    const theme = useTheme();

    React.useEffect( () => {
        const key = `${doc['configBaseKey']}.workflow.keeps.word.rules`;
        setConfigKey(key);
        setSelectedIndex(-1);
        setDeleteRuleEnabled(false);
        setEditRuleEnabled(false);
        setEditRuleDialogOpen(false);
        setAddRuleEnabled(!readOnly);
        const url = `/api/config/vlist?key=${key}`;
        const url2 = `/api/config/vget?key=${key}.serial`;
        // console.log('cmd: ' + url);
        netGet(url)
            .then(response => response.json())
            .then(irules => {
                if ( Array.isArray(irules) ) {
                    // console.log('Got ' + irules.length + ' rules.\n' + JSON.stringify(irules));
                    /*
                    netGet(url2)
                        .then(response => response.json())
                        .then(res => {
                            console.log('Got raw serial ' + JSON.stringify(res));
                            const rserial = res && res.v ? res.v : 0;
                            let iserial = Number(rserial);
                            if ( iserial === NaN ) {
                                iserial = 0;
                            }
                            console.log('Got serial ' + iserial);
                            if ( iserial >= serial ) {
                                setRules(irules);
                            }
                        }).catch ( error => {
                            console.log('Error fetching semantic rules serial: ' + error);
                        });
                    */
                    setRules(irules);
                }
            }).catch ( error => {
                console.log('Error fetching semantic rules: ' + error);
            });

    }, [doc,documentIndex,documentList, readOnly]);

    const handleTabChange = (event, index) => {
        setTabIndex(index);
    };


    const handleChange = (therules) => {
        if ( typeof onChange === 'function' ) {
            let rserial = serial  + 1;
            setSerial(rserial);
            
            netPost(`/api/config/set?key=${configKey}`, therules)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save rules: status = ' + resp.status);
                } else {
                    // delete def.saveSteps;
                    // setSaveDocumentEnabled(false);
                    // handleItemClick(selectedIndex);
                    // unmarkModified();
                }
            });
            
            onChange(therules, doc, documentIndex);
        }
    };

    const handleProfilesSave = (dprofiles) => {
        if ( typeof onChange === 'function') {
            const oc = [];
            oc.push(dprofiles);
            onChange(undefined, doc, documentIndex, oc);
        }
    };

    const handleRowClick = (index) => {
        setSelectedIndex(index);
        setDeleteRuleEnabled(index >= 0 && !readOnly);
        setEditRuleEnabled(index >= 0 && !readOnly);
    };

    const handleCreateRuleAction = () => {
        if (!readOnly) {
            setDialogTitle('Add Rule');
            setEditRuleCreateMode(true);
            setEditRuleDialogOpen(true);
        }
    };

    const handleEditRuleAction = () => {
        if (!readOnly) {
            setDialogTitle('Edit Rule');
            setEditRuleCreateMode(false);
            setEditRuleDialogOpen(true);
        }
    };

    const handleDeleteRuleAction = () => {
        if (!readOnly) {
            const i = selectedIndex;
            const rs = [...rules];
            rs.splice(i, 1);
            setSelectedIndex(-1);
            setRules(rs);
            // console.log('Deleted index ' + i);
            handleChange(rs);
        }
    };

    const onEditRuleSave = (rule) => {
        if (!readOnly) {
            setEditRuleDialogOpen(false);
            const rs = [ ...rules];
            // console.log('New rule: ' + JSON.stringify(rule));
            if (editRuleCreateMode) {
                rs.push({ ...rule });
            } else {
                rs[selectedIndex] = { ...rule };
            }
            setRules(rs);
            handleChange(rs);
        }
    };

    const onEditRuleCancel = () => {
        setEditRuleDialogOpen(false);
    };

    return (
        <Box sx={{position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid',  gridTemplateRows: 'min-content minmax(10px,1fr) min-content'}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="config tabs">
                    <Tab label="Semantic Pre-proccessor" {...a11yProps(0)} />
                    <Tab label="Download Profiles" {...a11yProps(1)} />
                </Tabs>
            </Box>
            {tabIndex === 0 && 
                <React.Fragment>
                    <Box sx={{ paddingTop: '2em' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ fontWeight: 'bold', borderBottom: 2 }}>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">No.</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>XPath</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Regex</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }} align="center">Group</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rules.map((rule, index) => (
                                        <TableRow selected={index === selectedIndex}
                                            onClick={() => handleRowClick(index)}
                                            key={`r-${doc.id}-${index}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }} align="center">
                                                {index}
                                            </TableCell>
                                            <TableCell>{rule.xpath}</TableCell>
                                            <TableCell>{rule.regex}</TableCell>
                                            <TableCell align="center">{rule.group ? Number(rule.group) : 0}</TableCell>
                                            <TableCell>{rule.action ? rule.action : 'TOGETHER'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                        <Tooltip title="Create Rule">
                            <span>
                            <IconButton color="inherit" sx={{ marginLeft: '1em', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addRuleEnabled} onClick={handleCreateRuleAction} >
                                <AddIcon color="inherit" />
                            </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Edit Rule">
                            <span>
                            <IconButton color="inherit" disabled={!editRuleEnabled} onClick={handleEditRuleAction}>
                                <AutoFixNormalIcon color="inherit" />
                            </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Rule">
                            <span>
                            <IconButton color="inherit" disabled={!deleteRuleEnabled} onClick={handleDeleteRuleAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                                <DeleteIcon color="inherit" />
                            </IconButton>
                            </span>
                        </Tooltip>
                        <EditRuleDialog rules={rules} ruleIndex={selectedIndex} onCancel={onEditRuleCancel} onSave={onEditRuleSave} open={editRuleDialogOpen}
                            create={editRuleCreateMode} title={dialogTitle} />
                    </Box>
                </React.Fragment>
            }
            { tabIndex === 1 &&
                <DownloadProfiles doc={doc} onChange={handleProfilesSave} readOnly={readOnly} />
            }
        </Box>
    );

}

function DocumentConfiguration(props) {

    const [documentList, setDocumentList] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [saveDocumentEnabled, setSaveDocumentEnabled] = React.useState(false);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const [checkinEnabled, setCheckinEnabled] = React.useState(false);
    const [modified, setModified] = React.useState([]);
    const [rulesMap, setRulesMap] = React.useState({});
    const [profilesMap, setProfilesMap] = React.useState({});

    const theme = useTheme();

    React.useEffect( () => {
        netGet('/api/doc/roots')
            .then(response => response.json())
            .then(docs => {
                if ( Array.isArray(docs) ) {
                    // console.log('Got ' + docs.length + ' documents.\n' + JSON.stringify(docs));
                    setDocumentList(docs);
                }
            }).catch ( error => {
                console.log('Error fetching profiles: ' + error);
            });

    }, []);

    const lockIconLabel = (index) => {
        const doc = documentList[index];
        if ( doc.configLock && doc.configLock !== '' ) {
            return 'Locked by ' + doc.configLock;
        }
        return '';
    };

    const lockIconColor = (index) => {
        const doc = documentList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (doc.configLock && doc.configLock !== '') {
            if (doc.configLock === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };

    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    };

    const isLockedByMe = (index) => {
        let doc = documentList[index];
        return typeof doc.configLock === 'string' && doc.configLock.length > 0 && doc.configLock === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let doc = documentList[index];
        return typeof doc.configLock === 'string' && doc.configLock.length > 0;
    };

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    const markModified = () => {
        const m = [ ...modified];
        if ( !m.includes(selectedIndex) ) {
            m.push(selectedIndex);
        }
        setModified(m);
        
        setSaveDocumentEnabled(true);
        // setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter( item => item !== selectedIndex);
        setModified(m);
        setCheckinEnabled(isLockedByMe(selectedIndex));
        setSaveDocumentEnabled(false);
        // setUndoSaveEnabled(false);
    };

    const doUnmarkModified = (index) => {
        const m = modified.filter( item => item !== index);
        setModified(m);
        // setCheckinEnabled(isLockedByMe(index));
    };


    const handleContextMenu = (event, index) => {

    };

    const handleItemClick = (index) => {
        setSelectedIndex(index);
        if ( isLocked(index) ) {
            setCheckoutEnabled(false);
            setCheckinEnabled(isLockedByMe(index));
        } else {
            setCheckoutEnabled(true);
            setCheckinEnabled(false);
        }
    };

    const documentChangeHandler = (rules, doc, docIndex, otherConfig) => {
        if (rules) {
            const rm = { ...rulesMap };
            rm[doc.id] = rules;
            setRulesMap(rm);
        }
        if ( Array.isArray(otherConfig) && otherConfig.length > 0 ) {
            const pf = otherConfig[0];
            const pm = { ...profilesMap};
            pm[doc.id] = pf;
            setProfilesMap(pm);
        }
        markModified();
    };

    const handleCheckoutAction = () => {
        const doc = documentList[selectedIndex];
        doc.configLock = UserInfo.info.name;
        const dlist = [ ...documentList];
        setDocumentList(dlist);
        handleItemClick(selectedIndex);
    };

    const handleCheckinAction = () => {
        const doc = documentList[selectedIndex];
        doc.configLock = '';
        const dlist = [ ...documentList];
        setDocumentList(dlist);
        handleItemClick(selectedIndex);
    };

    const handleRevokeCheckoutAction = () => {
        const doc = documentList[selectedIndex];
        doc.configLock = '';
        const dlist = [ ...documentList];
        setDocumentList(dlist);
        handleItemClick(selectedIndex);
    };

    const handleSaveDocumentAction = () => {
        unmarkModified();
        handleItemClick(selectedIndex);
        const doc = documentList[selectedIndex];
        if (rulesMap && rulesMap[doc.id]) {
            // console.log('rules[' + doc.id + ']=' + JSON.stringify(rulesMap[doc.id]));
            const key = `${doc['configBaseKey']}.workflow.keeps.word.rules`;
            netPost(`/api/config/set?key=${key}`, rulesMap[doc.id])
                .then(resp => {
                    if (!resp.ok) {
                        console.log('Could not save rules: status = ' + resp.status);
                    } else {
                        // delete def.saveSteps;
                        setSaveDocumentEnabled(false);
                        handleItemClick(selectedIndex);
                        unmarkModified();
                    }
                });
        }
        if ( profilesMap && profilesMap[doc.id] ) {
            // console.log('down profiles[' + doc.id + ']=' + JSON.stringify(profilesMap[doc.id]));
            const key = `${doc['configBaseKey']}.download.profiles`;
            netPost(`/api/config/set?key=${key}`, profilesMap[doc.id])
                .then(resp => {
                    if (!resp.ok) {
                        console.log('Could not save rules: status = ' + resp.status);
                    } else {
                        // delete def.saveSteps;
                        setSaveDocumentEnabled(false);
                        handleItemClick(selectedIndex);
                        unmarkModified();
                    }
                });
        }
    };

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '30% 3px 1fr', gridTemplateRows: '100%' }}>
            <Box sx={{position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid',  gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content'}}>
                <Typography align="center" sx={{paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Documents</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                />
                <Box 
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }} 
                    onContextMenu={(event) => handleContextMenu(event, -1)}
                >

                    <List sx={{ minHeight: '2em' }}>
                    {
                            documentList.map((doc, index) => (
                                <ListItem
                                    key={'mwdc-' + index} 
                                    sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                    onContextMenu={(event) => handleContextMenu(event, index)}
                                >
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: lockIconColor(index), fontSize: '12px'}}>
                                        <Tooltip title={lockIconLabel(index)}>
                                            <span>
                                                <LockIcon fontSize="inherit" />
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: modifiedColor(index), fontSize: '12px', paddingTop: '0.5ex'}}>
                                        <span>
                                        <Tooltip title={modifiedLabel(index)}>
                                            <Typography sx={{paddingLeft: '4px'}}>*</Typography>
                                        </Tooltip>
                                        </span>
                                    </Box>
                                    <ListItemButton onClick={(event) => handleItemClick(index)} sx={{paddingLeft: '4px'}} selected={selectedIndex === index}>
                                        <ListItemText>{doc.title}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                    }
                    </List>
                </Box>
                <Box sx={{padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Checkout Document">
                        <span>
                        <IconButton color="inherit" disabled={!checkoutEnabled} onClick={handleCheckoutAction} sx={{paddingLeft: '2px', paddingRight: '6px'}}>
                            <OutputIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Revoke Checkout Document">
                        <span>
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleRevokeCheckoutAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <ReplayIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Save Document Configuration">
                        <span>
                        <IconButton color="inherit" disabled={!saveDocumentEnabled} sx={{paddingLeft: '6px', paddingRight: '6px'}} onClick={handleSaveDocumentAction}>
                            <SaveAltIcon color="inherit" />
                        </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Checkin Document">
                        <span>
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleCheckinAction} sx={{paddingLeft: '6px', paddingRight: '6px'}}>
                            <ExitToAppIcon color="inherit" sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <Divider orientation="vertical"/>

            <Box sx={{overflowY: 'auto', position: 'relative', height: '100%'}}>
                
                <Box sx={{height: '100%'}}>
                    {
                        documentList && documentList.length > 0  && selectedIndex >= 0 ?
                            <DocumentConfigEdit doc={documentList[selectedIndex]} onChange={documentChangeHandler} documentIndex={selectedIndex}
                                saveEnabled={saveDocumentEnabled} documentList={documentList} readOnly={!checkinEnabled} />
                            : null
                    }
                </Box>
                
            </Box>
        </Box>

    );
}

export { DocumentConfiguration, };