import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

const initialSessionId = uuidv4();

// Information about networking state: authentication, websocket availability etc

// keycloak and websocket state and token
export const auth = createSlice({
    name: 'auth',
    initialState: {
        keycloakInitialized: false,
        authenticated: false,
        oidcEnabled: false,
        formEnabled: false,
        authToken: 'DUMMY',
        errorMessage: ''
    },
    reducers: {
        setKeycloakInitialized: (state, action) => {
            state.keycloakInitialized = action.payload;
        },
        setAuthenticated: (state, action) => {
            state.authenticated = action.payload;
        },
        setOidcEnabled: (state, action) => {
            state.oidcEnabled = action.payload;
        },
        setFormEnabled: (state, action) => {
          state.formEnabled = action.payload;
        },
        setAuthToken: (state, action) => {
            state.token = action.payload;
        },
        setAuthErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        }
    },
});



export const websocket = createSlice({
    name: 'websocket',
    initialState: {
        initialized: false,
        opened: false,
        sessionId: initialSessionId,
        errorMessage: '',
    },
    reducers: {
        setWebsocketInitialized: (state, action) => {
            state.initialized = action.payload;
        },
        setWebsocketOpened: (state, action) => {
            state.authenticated = action.payload;
        },
        setSessionId: (state,action) => {
            state.sessionId = action.payload;
        },
        setWebsocketErrorMessage: (state,action) => {
            state.errorMessage = action.payload;
        }
    },
});

export const user = createSlice({
    name: 'user',
    initialState: { 
        name: 'anonymous', 
        email: 'anonymous@nodomain.com', 
        givenName: 'Anonymous', 
        familyName: '', 
        roles: [], 
        sessionId: initialSessionId, 
    },
    reducers: {
        setUser: (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.givenName = action.payload.givenName;
            state.familyName = action.payload.familyName;
            state.roles = action.payload.roles;
        },
    },
    /*
    extraReducers: {
        [websocket.actions.setSessionId]: (state, action) => {
          state.sessionId = action.payload;
        },
      },
    */
    extraReducers: builder => {
      builder.addCase(websocket.actions.setSessionId, (state,action) => {
        state.sessionId = action.payload;
      })
    },
});

export const { setKeycloakInitialized, setAuthenticated, setOidcEnabled,  setFormEnabled, setAuthToken, setAuthErrorMessage} = auth.actions;
export const { setUser } = user.actions;
export const { setSessionId, setWebsocketInitialized, setWebsocketOpened, setWebsocketErrorMessage } = websocket.actions;

// for testing
export const setAuthenticatedAsync = (value) => (dispatch) => {
    setTimeout(() => {
        dispatch(setAuthenticated(value));
    }, 1000)
};

/*
export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

export const { increment, decrement, incrementByAmount } = counterSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount))
  }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.counter.value

export default counterSlice.reducer


// MORE EXAMPLES:
import { createSlice, createAction } from '@reduxjs/toolkit'
import { createStore, combineReducers } from 'redux'

const incrementBy = createAction('incrementBy')
const decrementBy = createAction('decrementBy')

const counter = createSlice({
  name: 'counter',
  initialState: 0,
  reducers: {
    increment: (state) => state + 1,
    decrement: (state) => state - 1,
    multiply: {
      reducer: (state, action) => state * action.payload,
      prepare: (value) => ({ payload: value || 2 }), // fallback if the payload is a falsy value
    },
  },
  // "builder callback API", recommended for TypeScript users
  extraReducers: (builder) => {
    builder.addCase(incrementBy, (state, action) => {
      return state + action.payload
    })
    builder.addCase(decrementBy, (state, action) => {
      return state - action.payload
    })
  },
})

const user = createSlice({
  name: 'user',
  initialState: { name: '', age: 20 },
  reducers: {
    setUserName: (state, action) => {
      state.name = action.payload // mutate the state all you want with immer
    },
  },
  // "map object API"
  extraReducers: {
    [counter.actions.increment]: (
      state,
      action // action will be inferred as "any", as the map notation does not contain type information 
      ) => {
        state.age += 1
      },
    },
  })
  
  const reducer = combineReducers({
    counter: counter.reducer,
    user: user.reducer,
  })
  
  const store = createStore(reducer)
  
  store.dispatch(counter.actions.increment())
  // -> { counter: 1, user: {name : '', age: 21} }
  store.dispatch(counter.actions.increment())
  // -> { counter: 2, user: {name: '', age: 22} }
  store.dispatch(counter.actions.multiply(3))
  // -> { counter: 6, user: {name: '', age: 22} }
  store.dispatch(counter.actions.multiply())
  // -> { counter: 12, user: {name: '', age: 22} }
  console.log(`${counter.actions.decrement}`)
  // -> "counter/decrement"
  store.dispatch(user.actions.setUserName('eric'))
  // -> { counter: 12, user: { name: 'eric', age: 22} }
*/