import { configureStore } from '@reduxjs/toolkit';

import {auth, user, websocket } from '../features/network/networkSlice';
import { fileUpload } from '../features/file-upload/fileUploadSlice';
import { processLogs } from '../features/process/processSlice';
import { menu } from '../features/menu/menuSlice';

export default configureStore({
  reducer: {
    auth: auth.reducer,
    user: user.reducer,
    menu: menu.reducer,
    websocket: websocket.reducer,
    fileUpload: fileUpload.reducer,
    processLogs: processLogs.reducer,
  },
});

